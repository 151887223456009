import { useQuery } from "@apollo/react-hooks";
import QUERY_TEMPLATE_BY_SLUG from "./queries/QUERY_TEMPLATE_BY_SLUG";
import {
  QueryTemplateBySlug,
  QueryTemplateBySlugVariables
} from "./queries/__generated__/QueryTemplateBySlug";

export default ({
  projectSlug,
  templateSlug
}: {
  projectSlug: string;
  templateSlug: string;
}) => {
  const { data, ...rest } = useQuery<
    QueryTemplateBySlug,
    QueryTemplateBySlugVariables
  >(QUERY_TEMPLATE_BY_SLUG, {
    variables: {
      projectSlug,
      templateSlug
    }
  });

  return { template: data && data.templates && data.templates[0], ...rest };
};
