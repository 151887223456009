import HelpLabel from "components/common/texts/HelpLabel";
import { TIcon } from "components/common/TIcon";
import React from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult
} from "react-beautiful-dnd";
import BranchFieldInterface from "templatio-evaluator/lib/main/evaluator/models/BranchFieldInterface";
import BranchField from "./BranchField";

export interface BranchFieldsProps {
  branchId: string;
  fields: BranchFieldInterface[];
  onFieldMoved: (move: { branchId: string; from: number; to: number }) => void;
}

const BranchFields = ({
  fields,
  branchId,
  onFieldMoved
}: BranchFieldsProps) => {
  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    onFieldMoved({
      branchId,
      from: result.source.index,
      to: result.destination.index
    });
  };
  return (
    <>
      <HelpLabel t="branchFields.label" helpT="branchFields.help" />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {droppableProvided => (
            <div
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}
            >
              {fields.map(({ localId }, index) => (
                <Draggable key={localId} draggableId={localId} index={index}>
                  {provided => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      <BranchField
                        dragHandle={
                          <TIcon
                            padding="small"
                            type="menu"
                            {...provided.dragHandleProps}
                          />
                        }
                        key={localId}
                        fieldId={localId}
                      />
                      {provided.placeholder}
                    </div>
                  )}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default BranchFields;
