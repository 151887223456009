import { Column } from "components/common/Flex";
import strings from "localisation/strings";
import React from "react";
import { BranchFieldsState } from "state/template/BranchFieldsState";
import ToolsDoneButton from "../common/ToolsDoneButton";
import { ToolsTitle } from "../common/ToolsTitle";
import TreeViewEditor from "../synonym/TreeViewEditor";
import AddBranchField from "./AddBranchField";
import BranchFields from "./BranchFields";
import { BranchNameInput } from "./BranchNameInput";
import DefaultBranchField from "./DefaultBranchField";
import { MarginedDivider } from "components/common/MarginedDivider";

export interface BranchToolsProps {
  templateId: string;
  entityId: string;
}

const BranchTools = ({ templateId, entityId }: BranchToolsProps) => {
  const { getBranchFields, moveBranchField } = BranchFieldsState.useContainer();

  return (
    <Column>
      <ToolsDoneButton />
      <TreeViewEditor entityId={entityId} />
      <ToolsTitle>{strings("projectTemplate.branchTools.title")}</ToolsTitle>
      <BranchNameInput branchId={entityId} />
      <MarginedDivider margin="large" />
      <DefaultBranchField entityId={entityId} />
      <MarginedDivider margin="large" />
      <BranchFields
        branchId={entityId}
        fields={getBranchFields({ branchId: entityId, includeDefault: false })}
        onFieldMoved={moveBranchField}
      />
      <AddBranchField entityId={entityId} />
    </Column>
  );
};

export default BranchTools;
