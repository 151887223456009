import { Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import { Row } from "components/common/Flex";
import { Spacer } from "components/common/Spacer";
import strings from "localisation/strings";
import moment from "moment";
import { Paths } from "navigation/Paths";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styled from "style/themed";
import DeleteTemplateButton from "./DeleteTemplateButton";
import {
  ProjectTemplates_project,
  ProjectTemplates_project_templates
} from "./queries/__generated__/ProjectTemplates";
import UpdateTemplateButton from "./UpdateTemplateButton";

const RelativeTime = ({ time }: { time: string }) => {
  const timeMoment = moment(time);
  const [timeText, setTimeText] = useState(timeMoment.fromNow());
  useEffect(() => {
    const interval = setInterval(
      () => setTimeText(timeMoment.fromNow()),
      10000
    );
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);
  return <span>{timeText}</span>;
};

const relativeDateRenderer = (time: string) => <RelativeTime time={time} />;

const columns = (
  projectId: string
): Array<ColumnProps<ProjectTemplates_project_templates>> => [
  {
    dataIndex: "name",
    title: strings("projectTemplates.templatesTable.columns.name")
  },
  {
    dataIndex: "createdAt",
    title: strings("projectTemplates.templatesTable.columns.createdAt"),
    render: relativeDateRenderer
  },
  {
    dataIndex: "updatedAt",
    title: strings("projectTemplates.templatesTable.columns.updatedAt"),
    render: relativeDateRenderer
  },
  {
    dataIndex: "actions",
    render: (_, template) => (
      <Row crossAxis="stretch">
        <DeleteTemplateButton templateId={template.id} projectId={projectId} />
        <Spacer vertical />
        <UpdateTemplateButton
          templateId={template.id}
          projectId={projectId}
          templateName={template.name}
          templateSlug={template.slug}
        />
      </Row>
    )
  }
];

const TableRow = styled.tr`
  cursor: pointer;
`;

export interface TemplatesTableProps extends RouteComponentProps {
  project: ProjectTemplates_project;
}

const TemplatesTable = ({ project, history }: TemplatesTableProps) => {
  return (
    <Table
      dataSource={project.templates}
      columns={columns(project.id)}
      rowKey="id"
      onRow={({ slug }) => {
        return {
          onClick: () =>
            history.push(
              Paths.projectTemplate.withParams({
                projectSlug: project.slug,
                templateSlug: slug
              })
            )
        };
      }}
      components={{
        body: {
          row: props => <TableRow {...props} />
        }
      }}
    />
  );
};

export default withRouter(TemplatesTable);
