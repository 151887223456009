import { EditorState } from "draft-js";
import { DraftStates } from "state/template/DraftStates";
import { useGetEntity } from "state/template/useGetEntity";
import { useGetField } from "state/template/useGetField";
import commitText from "state/templateStateHelpers/commitText";
import EntityInterface from "templatio-evaluator/lib/main/evaluator/models/EntityInterface";

export const useCommitEntityText = () => {
  const { getDraftState, setDraftState } = DraftStates.useContainer();
  const { getEntity } = useGetEntity();
  const { getField } = useGetField();

  const commitEntityText = ({
    entity,
    text,
    editorState
  }: {
    entity: EntityInterface;
    text?: string;
    editorState?: EditorState;
  }) => {
    const draftState = getDraftState(entity.parentId);
    if (draftState) {
      const stateWithText = text
        ? commitText(text, draftState, entity)
        : editorState;
      if (stateWithText) {
        setDraftState(entity.parentId, stateWithText);
        const field = getField(entity.parentId);
        if (field) {
          const parentEntity = getEntity(field.parentId);
          if (parentEntity) {
            const parentText = stateWithText.getCurrentContent().getPlainText();
            commitEntityText({
              entity: parentEntity,
              text: parentEntity.name || parentText
            });
          }
        }
      }
    }
  };

  return { commitEntityText };
};
