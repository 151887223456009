import { QueryProjectDataVariablesVariables } from "components/projectTemplateScreen/queries/__generated__/QueryProjectDataVariables";
import Refetch from "types/Refetch";
import PROJECT_DATA from "./QUERY_PROJECT_DATA";

export default (
  variables: QueryProjectDataVariablesVariables
): Refetch<QueryProjectDataVariablesVariables> => ({
  query: PROJECT_DATA,
  variables
});
