import gql from "graphql-tag";

export default gql`
  subscription QueryLatestPublications($templateId: uuid!) {
    publications(
      where: { template_id: { _eq: $templateId } }
      order_by: { created_at: desc }
      limit: 1
    ) {
      id
      status
    }
  }
`;
