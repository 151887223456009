import PROJECT_TEMPLATES from "./PROJECT_TEMPLATES";
import Refetch from "types/Refetch";
import { ProjectTemplatesVariables } from "./__generated__/ProjectTemplates";

export default (
  variables: ProjectTemplatesVariables
): Refetch<ProjectTemplatesVariables> => ({
  query: PROJECT_TEMPLATES,
  variables
});
