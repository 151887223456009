import gql from "graphql-tag";

export default gql`
  query QueryCheckProjectSlug($slug: String!) {
    slugs: projects_aggregate(where: { slug: { _eq: $slug } }) {
      total: aggregate {
        count
      }
    }
  }
`;
