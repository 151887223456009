import { useMutation } from "@apollo/react-hooks";
import { Button, message } from "antd";
import { Row } from "components/common/Flex";
import refetchProjectData from "components/projectDataScreen/queries/refetchProjectData";
import strings from "localisation/strings";
import React, { useState } from "react";
import { data_variable_types_enum } from "__generated__/AppGlobalTypes";
import QUERY_ADD_DATA_VARIABLE from "./queries/QUERY_ADD_DATA_VARIABLE";
import { refetchDataVariables } from "./queries/refetch";
import {
  QueryAddDataVariable,
  QueryAddDataVariableVariables
} from "./queries/__generated__/QueryAddDataVariable";

interface AddDataVariableButtonProps {
  projectId: string;
}

export const AddDataVariableButton = ({
  projectId
}: AddDataVariableButtonProps) => {
  const [loading, setLoading] = useState(false);
  const [addDataVariable] = useMutation<
    QueryAddDataVariable,
    QueryAddDataVariableVariables
  >(QUERY_ADD_DATA_VARIABLE);

  const onAdd = async () => {
    setLoading(true);
    try {
      await addDataVariable({
        variables: {
          projectId,
          type: data_variable_types_enum.TEXT,
          name: ""
        },
        refetchQueries: [
          refetchDataVariables({ projectId }),
          refetchProjectData({ projectId })
        ]
      });
    } catch (err) {
      message.error(strings("dataTypes.addFailed"));
    }
    setLoading(false);
  };
  return (
    <Row mainAxis="flex-end" marginVertical="medium">
      <Button type="primary" loading={loading} onClick={onAdd}>
        {strings("dataTypes.addVariableButton")}
      </Button>
    </Row>
  );
};
