import { Column } from "components/common/Flex";
import useProjectData from "components/projectTemplateScreen/hooks/useProjectData";
import React, { memo } from "react";
import { BranchFieldsState } from "state/template/BranchFieldsState";
import { FlatEquationRenderer } from "./EquationRenderer";
import { SlotFieldsTable } from "./slots/SlotFieldsTable";

export interface MultiBranchToolsProps {
  branchFieldId: string;
}

export const MultiBranchTools = memo(
  ({ branchFieldId }: MultiBranchToolsProps) => {
    const { getBranchField } = BranchFieldsState.useContainer();
    const dataVariables = useProjectData() || [];

    const branchField = getBranchField({ fieldId: branchFieldId });
    if (!branchField) {
      return null;
    }

    const { slotFields = [], equation } = branchField;

    return (
      <Column margin="small">
        <FlatEquationRenderer
          equationAST={equation}
          dataVariables={dataVariables}
        />
        <SlotFieldsTable
          slotFields={slotFields}
          equation={equation}
          branchFieldId={branchFieldId}
        />
      </Column>
    );
  }
);
