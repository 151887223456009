import { Button } from "antd";
import React, { memo, useMemo } from "react";
import { useRemoveSlotField } from "state/template/expensive/useRemoveSlotField";
import SlotFieldInterface from "templatio-evaluator/lib/main/evaluator/models/SlotField";

export interface SlotFieldActionsProps {
  field: SlotFieldInterface;
}

export const SlotFieldActions = memo(({ field }: SlotFieldActionsProps) => {
  const removeSlotField = useRemoveSlotField();

  return useMemo(
    () => <Button icon="delete" onClick={() => removeSlotField(field)} />,
    [removeSlotField, field]
  );
});
