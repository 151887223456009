import { Layout } from "antd";
import React from "react";
import styled, { StyledComponentProps } from "styled-components";

const AppContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  min-width: 0;
  padding: ${({ theme }) => theme.margin.medium};
`;

export interface AppScreenProps
  extends StyledComponentProps<"div", any, {}, never> {
  children: JSX.Element[] | JSX.Element;
}

const AppScreen = (props: AppScreenProps) => (
  <Layout>
    <AppContainer {...props} />
  </Layout>
);

export default AppScreen;
