import { Button, Dropdown, Icon, Menu } from "antd";
import { DropDownProps } from "antd/lib/dropdown";
import strings from "localisation/strings";
import { keys } from "ramda";
import React, { ReactNode } from "react";
import { Capitalization } from "templatio-evaluator/lib/main/evaluator/models/Capitalization";

interface CapitalizationDropdownProps extends Partial<DropDownProps> {
  children?: ReactNode;
  onSelected?: (capitalization: Capitalization) => void;
  selectedCapitalization?: Capitalization;
}

const capitalizationStrings = {
  [Capitalization.ORIGINAL]: strings("capitalization.ORIGINAL"),
  [Capitalization.ALL_WORDS]: strings("capitalization.ALL_WORDS"),
  [Capitalization.FIRST_LETTER]: strings("capitalization.FIRST_LETTER"),
  [Capitalization.FIRST_LETTER_LOWERCASE]: strings(
    "capitalization.FIRST_LETTER_LOWERCASE"
  ),
  [Capitalization.ALL_LETTERS]: strings("capitalization.ALL_LETTERS"),
  [Capitalization.NO_LETTERS]: strings("capitalization.NO_LETTERS")
};

const CapitalizationDropdown = ({
  onSelected = () => {},
  selectedCapitalization = Capitalization.ORIGINAL,
  ...dropdownProps
}: CapitalizationDropdownProps) => {
  const menu = (
    <Menu>
      {keys(Capitalization).map(capitalization => (
        <Menu.Item
          disabled={selectedCapitalization === capitalization}
          key={capitalization}
          onClick={() => onSelected(Capitalization[capitalization])}
        >
          {capitalizationStrings[capitalization]}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]} {...dropdownProps}>
      <Button>
        {capitalizationStrings[selectedCapitalization]}
        <Icon type="down" />
      </Button>
    </Dropdown>
  );
};

export default CapitalizationDropdown;
