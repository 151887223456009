import { Button, Form, Icon, Input } from "antd";
import { NativeButtonProps } from "antd/lib/button/button";
import { WrappedFormUtils } from "antd/lib/form/Form";
import { AuthErrorTypes } from "authService/generated/graphql";
import { EmailPassword } from "authService/hooks/useEmailAuth";
import strings from "localisation/strings";
import React, { FormEvent, useEffect } from "react";
import styled from "styled-components";

const StyledForm = styled(Form)`
  max-width: 300px;
`;

const B = (props: NativeButtonProps) => <Button {...props} />;
const LoginButton = styled(B)`
  width: 100%;
`;

export interface RegisterFormProps {
  form: WrappedFormUtils;
  onRegister: (params: EmailPassword) => any;
  errors: AuthErrorTypes[];
}

const RegisterFormComponent = ({
  form,
  onRegister,
  errors
}: RegisterFormProps) => {
  useEffect(() => {
    const fields = errors.reduce((acc, type) => {
      if (type === AuthErrorTypes.DuplicateEmail) {
        return {
          ...acc,
          email: {
            value: form.getFieldValue("email"),
            errors: [new Error(strings("register.duplicateEmail"))]
          }
        };
      }
      return acc;
    }, {});
    form.setFields(fields);
    // eslint-disable-next-line
  }, [errors]);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    form.validateFields((err, { email, password }) => {
      if (!err) {
        onRegister({ email, password });
      }
    });
  };

  const { getFieldDecorator } = form;
  return (
    <StyledForm onSubmit={handleSubmit}>
      <Form.Item>
        {getFieldDecorator("email", {
          rules: [{ required: true, message: strings("register.emailError") }]
        })(
          <Input
            prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder={strings("register.emailPlaceholder")}
          />
        )}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator("password", {
          rules: [
            { required: true, message: strings("register.passwordError") }
          ]
        })(
          <Input
            prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
            type="password"
            placeholder={strings("register.passwordPlaceholder")}
          />
        )}
      </Form.Item>
      <Form.Item>
        <LoginButton type="primary" htmlType="submit">
          {strings("register.register")}
        </LoginButton>
      </Form.Item>
    </StyledForm>
  );
};

const RegisterForm = Form.create<RegisterFormProps>()(RegisterFormComponent);

export default RegisterForm;
