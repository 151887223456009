import { Button, Result } from "antd";
import { AuthErrorTypes } from "authService/generated/graphql";
import useEmailAuth from "authService/hooks/useEmailAuth";
import LoadingScreen from "components/common/layout/LoadingScreen";
import { Paths, WithToken } from "navigation/Paths";
import React, { useEffect, useState } from "react";
import { Redirect, RouteComponentProps } from "react-router";

export default ({ match }: RouteComponentProps<WithToken>) => {
  const { token } = match.params;
  const [finished, setFinished] = useState(false);
  const { emailVerification, loading, errorType } = useEmailAuth();

  useEffect(() => {
    emailVerification(token).then(() => setFinished(true));
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  if (errorType === AuthErrorTypes.InvalidVerificationToken) {
    return (
      <Result
        status="403"
        title="This token can't be used"
        extra={<Button onClick={() => window.location.reload()}>RELOAD</Button>}
      />
    );
  }

  if (finished) {
    return <Redirect to={Paths.projects.withParams()} />;
  }

  return null;
};
