import { useMutation } from "@apollo/react-hooks";
import { Modal } from "antd";
import { WrappedFormUtils } from "antd/lib/form/Form";
import { ApolloError } from "apollo-client";
import strings from "localisation/strings";
import React, { useState } from "react";
import CreateProjectForm, { ProjectFormFields } from "./CreateProjectForm";
import CREATE_PROJECT from "./queries/CREATE_PROJECT";
import {
  CreateProject,
  CreateProjectVariables
} from "./queries/__generated__/CreateProject";

interface AddProjectModalProps {
  onCancel: () => any;
  onDone: () => void;
  visible: boolean;
}

const AddProjectModal = ({
  visible,
  onCancel,
  onDone
}: AddProjectModalProps) => {
  const [error, setError] = useState<ApolloError | undefined>();
  const [createProject, { loading }] = useMutation<
    CreateProject,
    CreateProjectVariables
  >(CREATE_PROJECT);
  let form: WrappedFormUtils<ProjectFormFields> | undefined;

  const onCreateProject = () => {
    if (form) {
      form.validateFields(async (err, variables) => {
        if (!err && form) {
          try {
            await createProject({
              variables
            });
            onDone();
          } catch (err) {
            setError(err);
          }
        }
      });
    }
  };

  const onModalClose = () => {
    if (form) {
      form.resetFields();
      setError(undefined);
    }
  };

  return (
    <Modal
      title={strings("projects.addNew")}
      okText={error ? strings("projects.tryAgain") : strings("projects.create")}
      cancelText={strings("projects.cancel")}
      confirmLoading={loading}
      visible={visible}
      onOk={onCreateProject}
      onCancel={onCancel}
      afterClose={onModalClose}
    >
      <CreateProjectForm ref={ref => (form = ref as any)} error={error} />
    </Modal>
  );
};

export default AddProjectModal;
