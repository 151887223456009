import { assoc, Omit } from "ramda";
import { useState } from "react";
import { Snapshot, StateInterface } from "templatio-evaluator";
import EntityTypes from "templatio-evaluator/lib/main/evaluator/EntityTypes";
import DataEntityInterface from "templatio-evaluator/lib/main/evaluator/models/DataEntityInterface";
import { createContainer } from "unstated-next";
import uuid from "uuid/v4";

const makeData = (
  partialData: Omit<DataEntityInterface, "entityId" | "type">
) => {
  const data: DataEntityInterface = {
    entityId: uuid(),
    type: EntityTypes.DATA,
    ...partialData
  };
  return data;
};

const useDatasState = (initialState?: StateInterface<DataEntityInterface>) => {
  const [datas, setDatas] = useState(initialState || {});

  const getDataEntity = ({ dataId }: { dataId: string }) => datas[dataId];

  const addData = (
    partialData: Omit<DataEntityInterface, "entityId" | "type">
  ) => {
    const data = makeData(partialData);
    setDatas(assoc(data.entityId, data));
    return data;
  };

  const updateData = (newData: DataEntityInterface) => {
    setDatas(assoc(newData.entityId, newData));
    return newData;
  };

  const addFromSnapshot = (snapshot: Snapshot) => {
    setDatas({ ...datas, ...snapshot.dataEntities });
  };

  return { datas, getDataEntity, addData, updateData, addFromSnapshot };
};

export const DatasState = createContainer(useDatasState);
