import gql from "graphql-tag";

export default gql`
  query UseExampleData($projectId: uuid!) {
    project: projects_by_pk(id: $projectId) {
      id
      exampleData: data_examples {
        id
        rows
      }
    }
  }
`;
