import { useMutation } from "@apollo/react-hooks";
import { Button, message } from "antd";
import strings from "localisation/strings";
import { remove } from "ramda";
import React, { useState } from "react";
import ExampleDataRow from "./ExampleDataRow";
import QUERY_UPDATE_EXAMPLTE_DATA from "./queries/QUERY_UPDATE_EXAMPLTE_DATA";
import refetchProjectData from "./queries/refetchProjectData";
import {
  QueryUpdateExampleData,
  QueryUpdateExampleDataVariables
} from "./queries/__generated__/QueryUpdateExampleData";

export interface RemoveExampleDataButtonProps {
  projectId: string;
  rows: ExampleDataRow[];
  rowIndex: number;
}

export const RemoveExampleDataButton = ({
  projectId,
  rows,
  rowIndex
}: RemoveExampleDataButtonProps) => {
  const [loading, setLoading] = useState(false);
  const [updateExampleData] = useMutation<
    QueryUpdateExampleData,
    QueryUpdateExampleDataVariables
  >(QUERY_UPDATE_EXAMPLTE_DATA);

  const onRemove = async () => {
    setLoading(true);
    try {
      await updateExampleData({
        variables: {
          projectId,
          rows: remove(rowIndex, rowIndex + 1, rows)
        },
        refetchQueries: [refetchProjectData({ projectId })]
      });
    } catch (err) {
      message.error(strings("projectData.removeFailed"));
    }
    setLoading(false);
  };
  return <Button icon="delete" loading={loading} onClick={onRemove} />;
};
