import * as styledComponents from "styled-components";
import { ThemedStyledComponentsModule } from "styled-components";

import { ThemeInterface } from "style/Theme";

const {
  default: styled,
  css,
  ThemeProvider
} = styledComponents as ThemedStyledComponentsModule<ThemeInterface>;

export type ThemedAndStyled<P> = P &
  styledComponents.ThemeProps<ThemeInterface>;

export { styled as default, css, ThemeProvider };
