import gatewayLogin from "appGateway/gatewayLogin";
import authServiceClient from "authService/authServiceClient";
import QUERY_EMAIL_LOGIN from "authService/queries/QUERY_EMAIL_LOGIN";
import {
  QueryEmailLogin,
  QueryEmailLoginVariables,
} from "authService/queries/__generated__/QueryEmailLogin";

export default async ({
  email,
  password,
  rememberUser,
}: {
  email: string;
  password: string;
  rememberUser?: boolean;
}) => {
  let authToken;
  const { data } = await authServiceClient.mutate<
    QueryEmailLogin,
    QueryEmailLoginVariables
  >({
    mutation: QUERY_EMAIL_LOGIN,
    variables: { email, password, rememberUser },
  });

  authToken = data && data.login.token;
  return await gatewayLogin(authToken);
};
