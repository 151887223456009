import { Avatar as AntdAvatar } from "antd";
import { AvatarProps as AntdAvatarProps } from "antd/lib/avatar";
import { head, Omit } from "ramda";
import React from "react";
import styled from "style/themed";

const Avatar = styled(AntdAvatar)`
  &&& {
    background-color: ${({ theme }) => theme.color.foreground.secondary};
  }
`;

const Initial = styled.span`
  font-size: ${({ theme }) => theme.font.size.medium};
`;

export interface TAvatarProps extends Omit<AntdAvatarProps, "src"> {
  src?: string | null;
  email?: string | null;
}

const TAvatar = ({ src, email, icon, ...props }: TAvatarProps) => {
  return (
    <Avatar
      src={src || undefined}
      icon={email ? undefined : icon || "user"}
      {...props}
    >
      <Initial>{email ? head(email).toUpperCase() : null}</Initial>
    </Avatar>
  );
};

export default TAvatar;
