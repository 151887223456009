import TInput from "components/common/inputs/TInput";
import HelpLabel from "components/common/texts/HelpLabel";
import React, { ChangeEvent } from "react";
import { SynonymsState } from "state/template/SynonymsState";
import { useCommitEntityText } from "../common/useCommitEntityText";

export interface SynonymNameInputProps {
  synonymId: string;
}

export const SynonymNameInput = ({ synonymId }: SynonymNameInputProps) => {
  const { getSynonymEntity, updateSynonym } = SynonymsState.useContainer();
  const { commitEntityText } = useCommitEntityText();

  const synonym = getSynonymEntity({ synonymId });

  if (!synonym) {
    return null;
  }

  const changeSynonymName = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    const newSynonym = { ...synonym, name };
    commitEntityText({ entity: newSynonym, text: name });
    updateSynonym(newSynonym);
  };

  return (
    <>
      <HelpLabel t="synonymNameInput.label" helpT="synonymNameInput.help" />
      <TInput
        value={synonym.name}
        onChange={changeSynonymName}
        placeholderT="synonymNameInput.label"
      />
    </>
  );
};
