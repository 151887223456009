import { Layout } from "antd";
import LoadingScreen from "components/common/layout/LoadingScreen";
import { Paths } from "navigation/Paths";
import React, { ReactNode } from "react";
import { Redirect } from "react-router";
import Sidebar from "sidebar/Sidebar";
import AuthState from "state/AuthState";
import styled from "style/themed";
import AuthErrorScreen from "./AuthErrorScreen";

const MainLayout = styled(Layout)`
  height: 100%;
`;

export interface AppFrameProps {
  children?: ReactNode;
}

const AppFrame = ({ children }: AppFrameProps) => {
  const { authState } = AuthState.useContainer();

  if (authState.status === "error") {
    return <AuthErrorScreen />;
  }

  if (authState.status === "loading") {
    return <LoadingScreen />;
  }

  if (authState.status === "loggedOut") {
    return <Redirect to={Paths.login.path} />;
  }

  return (
    <MainLayout>
      <Layout>
        <Sidebar />
        {children}
      </Layout>
    </MainLayout>
  );
};

export default AppFrame;
