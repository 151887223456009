import { AuthErrorTypes } from "authService/generated/graphql";
import useEmailAuth, { EmailPassword } from "authService/hooks/useEmailAuth";
import AppContainer from "components/common/layout/AppContainer";
import LoginForm from "components/loginScreen/LoginForm";
import VerifyEmail from "components/loginScreen/VerifyEmail";
import { Paths } from "navigation/Paths";
import React, { useState } from "react";
import { Redirect } from "react-router";
import AuthState from "state/AuthState";

const LoginScreen = () => {
  const { login, errorType, loading } = useEmailAuth();
  const { authState } = AuthState.useContainer();
  const [email, setEmail] = useState("");

  if (authState.status === "loggedIn") {
    return <Redirect to={Paths.projects.path} />;
  }

  if (errorType === AuthErrorTypes.EmailVerificationRequired) {
    return (
      <AppContainer>
        <VerifyEmail email={email} />
      </AppContainer>
    );
  }

  const signIn = (params: EmailPassword) => {
    setEmail(params.email);
    login(params);
  };

  return (
    <AppContainer>
      <LoginForm
        onLogin={signIn}
        errors={errorType ? [errorType] : []}
        loading={loading}
      />
    </AppContainer>
  );
};

export default LoginScreen;
