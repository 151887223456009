import { EditorState } from "draft-js";
import { assoc, dissoc, merge, mergeLeft } from "ramda";
import { useCallback, useState } from "react";
import { createInitialState } from "state/templateStateHelpers/createInitialState";
import { StateInterface } from "templatio-evaluator";
import { createContainer } from "unstated-next";
import { LocalSnapshot } from "./useSnapshot";

const useDraftStates = (initialState?: StateInterface<EditorState>) => {
  const [draftStates, setDraftStates] = useState(initialState || {});
  const getDraftState = (localId: string) => draftStates[localId];

  const setDraftState = useCallback(
    (localId: string, newState: EditorState) =>
      setDraftStates(assoc(localId, newState)),
    [setDraftStates]
  );

  const removeDraftState = useCallback(
    (localId: string) => {
      setDraftStates(dissoc(localId));
    },
    [setDraftStates]
  );

  const createEmptyStates = useCallback(
    (parentIds: string[]) => {
      const newStates = parentIds.reduce(
        (acc, id) => assoc(id, createInitialState(), acc),
        {} as StateInterface<EditorState>
      );
      setDraftStates(merge(newStates));
    },
    [setDraftStates]
  );

  const removeDraftStates = useCallback(
    (parentIds: string[]) => {
      const newStates = parentIds.reduce(
        (acc, id) => dissoc(id, acc),
        {} as StateInterface<EditorState>
      );
      setDraftStates(merge(newStates));
    },
    [setDraftStates]
  );

  const addFromSnapshot = useCallback(
    (snapshot: Partial<LocalSnapshot>) => {
      setDraftStates(mergeLeft(snapshot.draftStates));
    },
    [setDraftStates]
  );

  return {
    setDraftStates,
    getDraftState,
    setDraftState,
    removeDraftState,
    draftStates,
    addFromSnapshot,
    createEmptyStates,
    removeDraftStates
  };
};

export const DraftStates = createContainer(useDraftStates);
