import { EditorState, Modifier } from "draft-js";
import EntityInterface from "templatio-evaluator/lib/main/evaluator/models/EntityInterface";

export interface EntitySelection {
  data: EntityInterface;
  rangeInBlock: { start: number; end: number };
}

export const selectionToNewContent = (
  draftState: EditorState,
  entity: EntityInterface,
  replacementText?: string
) => {
  let contentState = draftState.getCurrentContent();
  const blocks = contentState.getBlockMap();
  const selection = draftState.getSelection();
  const startKey = selection.getStartKey();
  const endKey = selection.getEndKey();
  const startOffset = selection.getStartOffset();
  const endOffset = selection.getEndOffset();

  const selectedBlocks = blocks
    .toSeq()
    .skipUntil((_, k) => k === startKey)
    .takeUntil((_, k) => k === endKey)
    .concat([[endKey, blocks.get(endKey)]])
    .toArray();

  const selectionText: string[] = [];

  const oldEntities = selectedBlocks.map(block => {
    const blockSelectionStart = startKey === block.getKey() ? startOffset : 0;
    const blockSelectionEnd =
      endKey === block.getKey() ? endOffset : block.getLength();
    const entities: EntitySelection[] = [];
    let lastEntity: EntityInterface = {} as EntityInterface;
    selectionText.push(
      block.getText().slice(blockSelectionStart, blockSelectionEnd)
    );
    block.findEntityRanges(
      character => {
        const entityKey = character.getEntity();
        if (entityKey !== null) {
          lastEntity = contentState.getEntity(entityKey).getData();
          return true;
        }
        return false;
      },
      (start, end) => {
        if (start >= blockSelectionStart && end <= blockSelectionEnd) {
          entities.push({
            data: lastEntity,
            rangeInBlock: {
              start: start - blockSelectionStart,
              end: end - blockSelectionStart
            }
          });
        }
      }
    );
    return entities;
  });

  contentState = contentState.createEntity(entity.type, "IMMUTABLE", entity);
  contentState = Modifier.replaceText(
    contentState,
    selection,
    replacementText || selectionText.join("\n"),
    undefined,
    contentState.getLastCreatedEntityKey()
  );

  const editorState = EditorState.push(
    draftState,
    contentState,
    "apply-entity"
  );

  return { selectionText: selectionText.join("\n"), oldEntities, editorState };
};
