import { ContentBlock, ContentState } from "draft-js";
import EntityTypes from "templatio-evaluator/lib/main/evaluator/EntityTypes";

const findEntitiesByType = (type: EntityTypes) => {
  return (
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState
  ) => {
    contentBlock.findEntityRanges(character => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === type
      );
    }, callback);
  };
};

export default findEntitiesByType;
