import { Button } from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import strings from "localisation/strings";
import React from "react";
import DataDropdown from "./DataDropdown";
import { useToolbarActions } from "./useToolbarActions";

export interface ToolbarButtonsProps {
  localId: string;
}

const ToolbarButtons = ({ localId }: ToolbarButtonsProps) => {
  const { createSynonym, createBranch, createData } = useToolbarActions(
    localId
  );
  return (
    <ButtonGroup>
      <DataDropdown
        onVariableSelected={variable => createData(localId, variable)}
      >
        <Button>{strings("projectTemplate.inlineToolbar.data")}</Button>
      </DataDropdown>
      <Button onClick={createSynonym}>
        {strings("projectTemplate.inlineToolbar.synonym")}
      </Button>
      <Button onClick={createBranch}>
        {strings("projectTemplate.inlineToolbar.branch")}
      </Button>
    </ButtonGroup>
  );
};

export default ToolbarButtons;
