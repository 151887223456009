import { Editor } from "draft-js";
import React, { Fragment } from "react";
import { DraftStates } from "state/template/DraftStates";
import { EntityEditorState } from "state/template/EntityEditorState";
import { MarginedDivider } from "components/common/MarginedDivider";
import styled from "style/themed";
import { Row } from "components/common/Flex";
import { range } from "ramda";

const LevelIndicator = styled.div`
  border-left: 1px solid ${({ theme }) => theme.color.foreground.tertiary};
  margin-right: ${({ theme }) => theme.margin.large};
`;

export interface TreeViewEditorProps {
  entityId: string;
}

const TreeViewEditor = ({ entityId }: TreeViewEditorProps) => {
  const { getEntityTree } = EntityEditorState.useContainer();
  const { getDraftState } = DraftStates.useContainer();

  return (
    <>
      {getEntityTree(entityId).map((field, index) => {
        const draftState = getDraftState(field.localId);
        return draftState ? (
          <Fragment key={field.localId}>
            <Row>
              {range(0, index + 1).map(i => (
                <LevelIndicator key={i} />
              ))}
              <Editor
                key={field.localId}
                editorState={draftState}
                onChange={() => ({})}
                readOnly
              />
            </Row>
            <MarginedDivider margin="medium" />
          </Fragment>
        ) : null;
      })}
    </>
  );
};

export default TreeViewEditor;
