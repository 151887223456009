import React, { ReactNode } from "react";
import { BranchesState } from "state/template/BranchesState";
import { BranchFieldsState } from "state/template/BranchFieldsState";
import { DatasState } from "state/template/DatasState";
import { DraftStates } from "state/template/DraftStates";
import { EntityEditorState } from "state/template/EntityEditorState";
import { ExampleDataState } from "state/template/ExampleDataState";
import { SynonymFieldsState } from "state/template/SynonymFieldsState";
import { SynonymsState } from "state/template/SynonymsState";
import { fromServerSnapshot, LocalSnapshot } from "state/template/useSnapshot";
import { createInitialState } from "state/templateStateHelpers/createInitialState";
import { cleanUpSnapshot, ServerSnapshot } from "templatio-evaluator";

interface TemplateStateProviderProps {
  projectId: string;
  templateId: string;
  children: ReactNode;
  snapshot?: ServerSnapshot;
}

export const TemplateStateProvider = ({
  children,
  projectId,
  templateId,
  snapshot
}: TemplateStateProviderProps) => {
  const {
    draftStates,
    dataEntities,
    synonymEntities,
    synonymFields,
    branchEntities,
    branchFields
  } = snapshot
    ? fromServerSnapshot(cleanUpSnapshot({ snapshot }))
    : ({} as LocalSnapshot);
  return (
    <ExampleDataState.Provider initialState={{ projectId }}>
      <DraftStates.Provider
        initialState={draftStates || { [templateId]: createInitialState() }}
      >
        <DatasState.Provider initialState={dataEntities}>
          <SynonymFieldsState.Provider initialState={synonymFields}>
            <BranchFieldsState.Provider initialState={branchFields}>
              <SynonymsState.Provider initialState={synonymEntities}>
                <BranchesState.Provider initialState={branchEntities}>
                  <EntityEditorState.Provider
                    initialState={{ templateId, projectId }}
                  >
                    {children}
                  </EntityEditorState.Provider>
                </BranchesState.Provider>
              </SynonymsState.Provider>
            </BranchFieldsState.Provider>
          </SynonymFieldsState.Provider>
        </DatasState.Provider>
      </DraftStates.Provider>
    </ExampleDataState.Provider>
  );
};
