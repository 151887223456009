import gql from "graphql-tag";

export default gql`
  mutation MutationChangePassword(
    $email: String!
    $oldPassword: String!
    $newPassword: String!
  ) {
    changePassword(
      email: $email
      oldPassword: $oldPassword
      newPassword: $newPassword
    )
  }
`;
