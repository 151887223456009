import { InMemoryCache } from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import { split } from "apollo-link";
import { createHttpLink } from "apollo-link-http";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import useSubscription from "components/common/hooks/useSubscription";
import { Paths } from "navigation/Paths";
import { path } from "ramda";
import React from "react";
import { Redirect } from "react-router";
import { SubscriptionClient } from "subscriptions-transport-ws";
import QUERY_APP_INFO from "./serviceQueries/QUERY_APP_INFO";
import {
  QueryAppInfo,
  QueryAppInfoVariables
} from "./serviceQueries/__generated__/QueryAppInfo";

const getAuthServiceClient = () => {
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_AM_HASURA_URL
  });

  const wsClient = new SubscriptionClient(
    `${process.env.REACT_APP_AM_HASURA_WS_URL}`,
    {
      reconnect: true
    }
  );
  const wsLink = new WebSocketLink(wsClient);

  const transportLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );

  return new ApolloClient({
    link: transportLink,
    cache: new InMemoryCache()
  });
};

const client = getAuthServiceClient();

export default () => {
  const { data } = useSubscription<QueryAppInfo, QueryAppInfoVariables>(
    QUERY_APP_INFO,
    {
      client,
      variables: { appId: process.env.REACT_APP_AUTH_SERVICE_APP_ID }
    }
  );

  const isAppInMaintenanceMode = path(["app", "info", "maintenanceMode"], data);

  if (
    window.location.pathname === Paths.maintenance.path &&
    isAppInMaintenanceMode === false
  ) {
    return <Redirect to={Paths.projects.withParams()} />;
  }

  if (isAppInMaintenanceMode) {
    return <Redirect to={Paths.maintenance.withParams()} />;
  }
  return null;
};
