import { Button } from "antd";
import React from "react";
import { useAddRemoveBranchField } from "state/template/expensive/useAddRemoveBranchField";

export interface RemoveBranchFieldButtonProps {
  fieldId: string;
}

export const RemoveBranchFieldButton = ({
  fieldId
}: RemoveBranchFieldButtonProps) => {
  const { removeBranchField } = useAddRemoveBranchField();

  return (
    <Button icon="delete" onClick={() => removeBranchField({ fieldId })} />
  );
};
