import { Tooltip } from "antd";
import strings from "localisation/strings";
import TranslationPaths from "localisation/TranslationPaths";
import React from "react";
import styled from "style/themed";
import { SimpleText, SimpleTextProps } from "../SimpleText";
import { TIcon } from "../TIcon";

const StyledText = styled(SimpleText)`
  margin-bottom: ${({ theme, marginVertical }) =>
    theme.margin[marginVertical || "small"]};
`;

interface HelpLabelProps extends SimpleTextProps {
  helpT?: TranslationPaths;
}

const HelpLabel = ({ helpT, t, ...props }: HelpLabelProps) => (
  <StyledText as="p" color="secondary" {...props}>
    {!!t && strings(t)}
    {!!helpT && " "}
    {!!helpT && (
      <Tooltip title={strings(helpT)}>
        <TIcon type="question-circle-o" />
      </Tooltip>
    )}
  </StyledText>
);

export default HelpLabel;
