import gatewayLogin from "appGateway/gatewayLogin";
import authServiceClient from "authService/authServiceClient";
import QUERY_VERIFY_EMAIL from "./queries/QUERY_VERIFY_EMAIL";
import {
  QueryVeriryEmail,
  QueryVeriryEmailVariables
} from "./queries/__generated__/QueryVeriryEmail";

export default async (token: string) => {
  let authToken;
  const { data } = await authServiceClient.mutate<
    QueryVeriryEmail,
    QueryVeriryEmailVariables
  >({
    mutation: QUERY_VERIFY_EMAIL,
    variables: { token }
  });

  authToken = data && data.verified.token;
  return await gatewayLogin(authToken);
};
