import gql from "graphql-tag";

export default gql`
  query QueryCheckTemplateSlug($projectId: uuid!, $slug: String!) {
    slugs: templates_aggregate(
      where: { _and: { project_id: { _eq: $projectId }, slug: { _eq: $slug } } }
    ) {
      total: aggregate {
        count
      }
    }
  }
`;
