import gql from "graphql-tag";

export default gql`
  mutation QueryAddDataVariable(
    $projectId: uuid!
    $name: String!
    $type: data_variable_types_enum!
  ) {
    insert_data_variables(
      objects: { name: $name, project_id: $projectId, type: $type }
    ) {
      affected_rows
    }
  }
`;
