import globalTheme from "style/Theme";
import styled from "style/themed";

interface SpacerProps {
  margin?: keyof typeof globalTheme.margin;
  vertical?: boolean;
}

export const Spacer = styled.div<SpacerProps>`
  ${({ theme, vertical, margin }) =>
    vertical
      ? `margin-left: ${theme.margin[margin || "medium"]}`
      : `margin-top: ${theme.margin[margin || "medium"]}`};
`;
