import { EditorInputContainer } from "components/common/inputs/EditorInputContainer";
import TemplatioEditor from "components/projectTemplateScreen/TemplatioEditor";
import React, { ReactNode, useRef } from "react";
import { BranchFieldsState } from "state/template/BranchFieldsState";
import styled from "style/themed";
import { hasSlots } from "templatio-evaluator";
import BranchFieldHeader from "./BranchFieldHeader";

const FieldArea = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.color.background.primary};
  margin-bottom: ${({ theme }) => theme.margin.medium};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.color.background.tertiary};
  background-color: ${({ theme }) => theme.color.background.tertiary};
`;

const EditorContainer = styled(EditorInputContainer)`
  border-radius: 0 0 4px 4px;
  border: 1px solid ${({ theme }) => theme.color.background.primary};
`;

export interface BranchFieldProps {
  fieldId: string;
  dragHandle?: ReactNode;
}

const BranchField = ({ fieldId, dragHandle }: BranchFieldProps) => {
  const { getBranchEquation } = BranchFieldsState.useContainer();
  const fieldAreaRef = useRef<HTMLDivElement>(null);

  const onAreaClick = () => {
    const { current } = fieldAreaRef;
    if (current) {
      current.focus();
    }
  };

  return (
    <FieldArea onClick={onAreaClick} ref={fieldAreaRef}>
      <BranchFieldHeader dragHandle={dragHandle} fieldId={fieldId} />
      {!hasSlots(getBranchEquation(fieldId)) && (
        <EditorContainer>
          <TemplatioEditor draftStateId={fieldId} />
        </EditorContainer>
      )}
    </FieldArea>
  );
};

export default BranchField;
