import gql from "graphql-tag";

export default gql`
  query QueryProjectData($projectId: uuid!) {
    project: projects_by_pk(id: $projectId) {
      name
      id
      slug
      dataVariables: data_variables {
        id
        name
        projectId: project_id
        type
      }
      exampleData: data_examples {
        id
        rows
      }
    }
  }
`;
