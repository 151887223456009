import { InMemoryCache } from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import { createHttpLink } from "apollo-link-http";

export default new ApolloClient({
  link: createHttpLink({
    uri: process.env.REACT_APP_AUTH_SERVICE_URL,
    credentials: "include",
    headers: {
      "x-pummy-am-app-id": process.env.REACT_APP_AUTH_SERVICE_APP_ID
    }
  }),
  cache: new InMemoryCache()
});
