import { useMutation } from "@apollo/react-hooks";
import { Button, message, Popconfirm } from "antd";
import strings from "localisation/strings";
import React, { useState } from "react";
import DELETE_TEMPLATE from "./queries/DELETE_TEMPLATE";
import PROJECT_TEMPLATES from "./queries/PROJECT_TEMPLATES";
import {
  DeleteTemplate,
  DeleteTemplateVariables
} from "./queries/__generated__/DeleteTemplate";

export interface DeleteTemplateButtonProps {
  templateId: string;
  projectId: string;
}

const DeleteTemplateButton = ({
  templateId,
  projectId
}: DeleteTemplateButtonProps) => {
  const [loading, setLoading] = useState(false);
  const [deleteTemplate] = useMutation<DeleteTemplate, DeleteTemplateVariables>(
    DELETE_TEMPLATE
  );
  const onConfirm = async () => {
    setLoading(true);
    try {
      await deleteTemplate({
        variables: { templateId },
        refetchQueries: [{ query: PROJECT_TEMPLATES, variables: { projectId } }]
      });
    } catch (err) {
      message.error(strings("projectTemplates.deleteFailed"));
    }
    setLoading(false);
  };
  return (
    <div onClick={(ev: any) => ev.stopPropagation()}>
      <Popconfirm
        title={strings("projectTemplates.deleteConfirmTitle")}
        okText={strings("projectTemplates.deleteOk")}
        cancelText={strings("projectTemplates.deleteCancel")}
        placement="left"
        onConfirm={onConfirm}
      >
        <Button icon="delete" loading={loading} />
      </Popconfirm>
    </div>
  );
};

export default DeleteTemplateButton;
