import gql from "graphql-tag";

export default gql`
  query ProjectTemplates($projectId: uuid!) {
    project: projects_by_pk(id: $projectId) {
      name
      id
      slug
      templates(order_by: { updated_at: desc }) {
        id
        name
        slug
        createdAt: created_at
        updatedAt: updated_at
      }
    }
  }
`;
