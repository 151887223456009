import { RawDraftContentState } from "draft-js";
import { assoc, dissoc, drop } from "ramda";
import { useCallback } from "react";
import { createInitialState } from "state/templateStateHelpers/createInitialState";
import { mergeAllSnapshots } from "templatio-evaluator";
import SynonymFieldInterface from "templatio-evaluator/lib/main/evaluator/models/SynoymFieldInterface";
import uuid from "uuid/v4";
import { DraftStates } from "../DraftStates";
import { SynonymFieldsState } from "../SynonymFieldsState";
import { useAddField } from "../useAddField";
import { LocalSnapshot } from "../useSnapshot";

export const useAddRemoveSynonymFields = () => {
  const {
    removeDraftState,
    addFromSnapshot: addDraftStates
  } = DraftStates.useContainer();
  const { addField } = useAddField();
  const {
    setSynonymFields,
    addFromSnapshot: addSynonymFields
  } = SynonymFieldsState.useContainer();

  const addSynonymField = useCallback(
    ({
      synonymId,
      initialText
    }: {
      synonymId: string;
      initialText?: string | RawDraftContentState;
    }) => {
      const fieldId = addField(initialText);
      const newField: SynonymFieldInterface = {
        localId: fieldId,
        parentId: synonymId
      };
      setSynonymFields(assoc(fieldId, newField));
      return newField;
    },
    [addField, setSynonymFields]
  );

  const removeSynonymField = useCallback(
    (fieldId: string) => {
      removeDraftState(fieldId);
      setSynonymFields(currentFields => dissoc(fieldId, currentFields));
    },
    [removeDraftState, setSynonymFields]
  );

  const updateFields = useCallback(
    (
      synonymId: string,
      fieldsToUpdate: SynonymFieldInterface[],
      newTexts: string[]
    ) => {
      const newFields = drop(fieldsToUpdate.length, newTexts).map<
        SynonymFieldInterface
      >(() => ({
        parentId: synonymId,
        localId: uuid()
      }));
      const updatedFields = [...fieldsToUpdate, ...newFields];
      const newSnapshot = mergeAllSnapshots(
        updatedFields.map<Partial<LocalSnapshot>>((field, index) => {
          return {
            draftStates: {
              [field.localId]: createInitialState(newTexts[index])
            },
            synonymFields: { [field.localId]: field }
          };
        })
      );
      addDraftStates(newSnapshot);
      addSynonymFields(newSnapshot);
    },
    [addDraftStates, addSynonymFields]
  );

  return { addSynonymField, removeSynonymField, updateFields };
};
