import useProjectData from "components/projectTemplateScreen/hooks/useProjectData";

export const useDataVariable = () => {
  const projectData = useProjectData();

  const getVariableName = (variableId: string) => {
    const dataVariable = (projectData || []).find(
      variable => variable.id === variableId
    );
    return dataVariable && dataVariable.name;
  };

  return { getVariableName };
};
