import { useMutation } from "@apollo/react-hooks";
import { Button, message, Popconfirm } from "antd";
import strings from "localisation/strings";
import React, { useState } from "react";
import DELETE_PROJECT from "./queries/DELETE_PROJECT";
import {
  DeleteProject,
  DeleteProjectVariables
} from "./queries/__generated__/DeleteProject";

export interface DeleteProjectButtonProps {
  projectId: string;
}

const DeleteProjectButton = ({ projectId }: DeleteProjectButtonProps) => {
  const [loading, setLoading] = useState(false);
  const [deleteProject] = useMutation<DeleteProject, DeleteProjectVariables>(
    DELETE_PROJECT
  );
  const onConfirm = async () => {
    setLoading(true);
    try {
      await deleteProject({
        variables: { projectId }
      });
    } catch (err) {
      console.log(err);
      message.error(strings("projects.deleteFailed"));
    }
    setLoading(false);
  };
  return (
    <Popconfirm
      title={strings("projects.deleteConfirmTitle")}
      okText={strings("projects.deleteOk")}
      cancelText={strings("projects.deleteCancel")}
      placement="left"
      onConfirm={onConfirm}
    >
      <Button icon="delete" loading={loading} />
    </Popconfirm>
  );
};

export default DeleteProjectButton;
