import { Button } from "antd";
import { SpreadRow } from "components/common/Flex";
import React from "react";
import { useSlottedBranchField } from "state/template/expensive/useSlottedBranchField";
import SlotFieldInterface from "templatio-evaluator/lib/main/evaluator/models/SlotField";
import uuid from "uuid/v4";

export interface SlotFieldsTableHeaderProps {
  branchFieldId: string;
}

export const SlotFieldsTableHeader = ({
  branchFieldId
}: SlotFieldsTableHeaderProps) => {
  const { addSlottedField } = useSlottedBranchField();

  const onAddEmptyField = () => {
    const newSlotField: SlotFieldInterface = {
      index: -1,
      parentId: branchFieldId,
      localId: uuid(),
      slotValues: {}
    };
    addSlottedField(newSlotField);
  };

  return (
    <SpreadRow
      marginVertical="medium"
      mainAxis="space-between"
      crossAxis="flex-start"
    >
      <Button icon="plus" onClick={onAddEmptyField} />
    </SpreadRow>
  );
};
