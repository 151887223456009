import authServiceClient from "./authServiceClient";
import QUERY_REFRESH_AUTH_TOKEN from "./queries/QUERY_REFRESH_AUTH_TOKEN";
import { QueryRefreshAuthToken } from "./queries/__generated__/QueryRefreshAuthToken";

export default async () => {
  const { data: refreshData } = await authServiceClient.mutate<
    QueryRefreshAuthToken
  >({
    mutation: QUERY_REFRESH_AUTH_TOKEN
  });
  return { token: refreshData && refreshData.refreshAuthToken.token };
};
