import { useLazyQuery } from "@apollo/react-hooks";
import { AutoComplete, Icon, Input } from "antd";
import { SelectValue } from "antd/lib/select";
import useDebouncedValue from "components/common/hooks/useDebouncedValue";
import strings from "localisation/strings";
import React, { useCallback, useEffect, useState } from "react";
import styled from "style/themed";
import QUERY_SEARCH_MEMBERS_TO_ADD from "./queries/QUERY_SEARCH_MEMBERS_TO_ADD";
import {
  QuerySearchMembersToAdd,
  QuerySearchMembersToAddVariables
} from "./queries/__generated__/QuerySearchMembersToAdd";

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const isValidEmail = (maybeEmail: string) => {
  return EMAIL_REGEX.test(maybeEmail.toLowerCase());
};

const LoadingIcon = styled(Icon)`
  color: ${({ theme, spin }) =>
    spin ? theme.color.background.tertiary : "transparent"};
`;

interface UserAutocompleteProps {
  onUserSelected: (userId: string) => void;
  onEmailSelected: (email: string) => void;
  excludedProjectId: string;
}

export default ({
  onUserSelected,
  onEmailSelected,
  excludedProjectId
}: UserAutocompleteProps) => {
  const {
    value: inputValue,
    setValue: setInputValue,
    debouncedValue
  } = useDebouncedValue({ initialValue: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [searchMembers, { data }] = useLazyQuery<
    QuerySearchMembersToAdd,
    QuerySearchMembersToAddVariables
  >(QUERY_SEARCH_MEMBERS_TO_ADD);

  const onSearch = useCallback(async () => {
    if (debouncedValue) {
      try {
        await searchMembers({
          variables: {
            partialEmail: `%${debouncedValue}%`,
            excludedProjectId
          }
        });
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, [debouncedValue, setIsLoading, searchMembers, excludedProjectId]);

  useEffect(() => {
    onSearch();
  }, [debouncedValue, onSearch]);

  useEffect(() => {
    if (!isLoading && inputValue !== debouncedValue) {
      setIsLoading(true);
    }
  }, [inputValue, isLoading, setIsLoading, debouncedValue]);

  const users = (data && data.users) || [];

  const onInputChange = (newValue: string) => {
    setInputValue(newValue);
  };

  const onSelect = (userIdOrEmail: SelectValue) => {
    setInputValue("");
    if (userIdOrEmail === "email") {
      onEmailSelected(inputValue);
    } else {
      onUserSelected(userIdOrEmail as "string");
    }
  };

  const usersDataSource = users.map(({ email, id }) => ({
    value: id,
    text: `${email}`
  }));

  const emailDataSource = isValidEmail(inputValue)
    ? [
        {
          value: "email",
          text: `Email: ${inputValue}`
        }
      ]
    : [];

  const dataSource = [...usersDataSource, ...emailDataSource];

  return (
    <AutoComplete
      open={isFocused && dataSource.length > 0 && !isLoading}
      dataSource={dataSource}
      onSearch={onInputChange}
      value={inputValue}
      onSelect={onSelect}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    >
      <Input
        suffix={<LoadingIcon type="sync" spin={isLoading} />}
        placeholder={strings("userAutocomplete.addMember")}
      />
    </AutoComplete>
  );
};
