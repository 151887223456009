import { assoc, dissoc } from "ramda";
import BranchFieldInterface, {
  BranchFieldType
} from "templatio-evaluator/lib/main/evaluator/models/BranchFieldInterface";
import { Equation } from "templatio-evaluator/lib/main/evaluator/models/EquationInterfaces";
import { BranchFieldsState } from "../BranchFieldsState";
import { DraftStates } from "../DraftStates";
import { useAddField } from "../useAddField";
import { useGetField } from "../useGetField";

const EMPTY_EQUATION: Equation = { type: "equation" };

export const useAddRemoveBranchField = () => {
  const {
    getBranchFields,
    setBranchFields,
    reindexFields
  } = BranchFieldsState.useContainer();
  const { removeDraftState } = DraftStates.useContainer();
  const { addField } = useAddField();
  const { getField } = useGetField();

  const addBranchField = ({
    branchId,
    initialText,
    fieldType
  }: {
    branchId: string;
    initialText?: string;
    fieldType?: BranchFieldType;
  }) => {
    const fieldId = addField(initialText);
    const newField: BranchFieldInterface = {
      index: getBranchFields({ branchId }).length,
      localId: fieldId,
      parentId: branchId,
      equation: EMPTY_EQUATION,
      tests: [],
      fieldType: fieldType || "REGULAR_FIELD"
    };
    setBranchFields(assoc(fieldId, newField));
    return newField;
  };

  const removeBranchField = ({ fieldId }: { fieldId: string }) => {
    const field = getField(fieldId);
    removeDraftState(fieldId);
    setBranchFields(dissoc(fieldId));
    if (field) {
      reindexFields(
        getBranchFields({ branchId: field.parentId }).filter(
          ({ localId }) => localId !== fieldId
        )
      );
    }
  };

  return {
    addBranchField,
    removeBranchField
  };
};
