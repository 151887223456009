import { Button, Form, Icon, Input, message } from "antd";
import { NativeButtonProps } from "antd/lib/button/button";
import { WrappedFormUtils } from "antd/lib/form/Form";
import authServiceClient from "authService/authServiceClient";
import { getFirstErrorType } from "authService/errorHandling";
import { AuthErrorTypes } from "authService/generated/graphql";
import MUTATION_CHANGE_PASSWORD from "authService/queries/MUTATION_CHANGE_PASSWORD";
import { MutationChangePasswordVariables } from "authService/queries/__generated__/MutationChangePassword";
import useUserData from "components/common/hooks/useUserData";
import { SimpleText } from "components/common/SimpleText";
import strings from "localisation/strings";
import React, { FormEvent } from "react";
import styled from "styled-components";

const StyledForm = styled(Form)`
  max-width: 300px;
`;

const B = (props: NativeButtonProps) => <Button {...props} />;
const LoginButton = styled(B)`
  width: 100%;
`;

export interface ChangePasswordFormProps {
  form: WrappedFormUtils;
}

const ChangePasswordFormComponent = ({ form }: ChangePasswordFormProps) => {
  const user = useUserData();

  const compareToFirstPassword = (rule: any, value: any, callback: any) => {
    if (value && value !== form.getFieldValue("newPassword")) {
      callback(strings("changePasswordForm.noMatchError"));
    } else {
      callback();
    }
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    form.validateFields(async (err, { oldPassword, newPassword }) => {
      if (!err) {
        try {
          await authServiceClient.mutate<MutationChangePasswordVariables>({
            mutation: MUTATION_CHANGE_PASSWORD,
            variables: {
              email: user?.primaryEmail || "",
              oldPassword,
              newPassword
            }
          });
          form.resetFields();
          message.success("Password successfully changed");
        } catch (err) {
          const errorType = getFirstErrorType(err);
          if (errorType === AuthErrorTypes.InvalidPassword) {
            form.setFields({
              oldPassword: {
                value: form.getFieldValue("oldPassword"),
                errors: [new Error(strings("login.invalidPassword"))]
              }
            });
          }
        }
      }
    });
  };

  const { getFieldDecorator } = form;
  return (
    <>
      <SimpleText color="secondary" t="changePasswordForm.title" />
      <StyledForm onSubmit={handleSubmit}>
        <Form.Item label={strings("changePasswordForm.current")}>
          {getFieldDecorator("oldPassword", {
            rules: [
              {
                required: true,
                message: strings("changePasswordForm.noPasswordError")
              }
            ]
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder={strings("changePasswordForm.current")}
            />
          )}
        </Form.Item>
        <Form.Item label={strings("changePasswordForm.newPassword")}>
          {getFieldDecorator("newPassword", {
            rules: [
              {
                required: true,
                message: strings("changePasswordForm.noPasswordError")
              }
            ]
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder={strings("changePasswordForm.newPassword")}
            />
          )}
        </Form.Item>
        <Form.Item label={strings("changePasswordForm.newConfirmation")}>
          {getFieldDecorator("confirmNewPassword", {
            rules: [
              {
                required: true,
                message: strings("changePasswordForm.noMatchError"),
                validator: compareToFirstPassword
              }
            ]
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder={strings("changePasswordForm.newConfirmation")}
            />
          )}
        </Form.Item>
        <Form.Item>
          <LoginButton type="primary" htmlType="submit">
            {strings("buttons.save")}
          </LoginButton>
        </Form.Item>
      </StyledForm>
    </>
  );
};

const ChangePasswordForm = Form.create<ChangePasswordFormProps>()(
  ChangePasswordFormComponent
);

export default ChangePasswordForm;
