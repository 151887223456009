import { Icon } from "antd";
import React from "react";
import { useSnapshotSaver } from "state/template/useSnapshotSaver";

interface TemplateStateSaverProps {
  projectId: string;
  templateId: string;
}

const TemplateStateSaver = ({
  projectId,
  templateId
}: TemplateStateSaverProps) => {
  const { status, triggerSave } = useSnapshotSaver({ projectId, templateId });

  if (status === "UNSAVED") {
    return <Icon type="sync" style={{ fontSize: 16, color: "red" }} spin />;
  }
  if (status === "ERROR") {
    return (
      <Icon
        type="exclamation-circle"
        style={{ fontSize: 16, color: "red", cursor: "pointer" }}
        theme="filled"
        onClick={triggerSave}
      />
    );
  }

  return <Icon type="check-circle" style={{ fontSize: 16, color: "green" }} />;
};

export default TemplateStateSaver;
