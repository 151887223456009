import { keys } from "ramda";
import {
  EquationOperator,
  EquationOperatorTypes,
  TextOperator,
  TextOperatorTypes
} from "templatio-evaluator/lib/main/evaluator/models/OperatorInterfaces";

export const textOperators = keys(TextOperatorTypes).map<TextOperator>(
  operator => ({
    operator: TextOperatorTypes[operator],
    type: "operator",
    operatorType: "textOperator"
  })
);

export const equationOperators = keys(EquationOperatorTypes).map<
  EquationOperator
>(operator => ({
  operator: EquationOperatorTypes[operator],
  type: "operator",
  operatorType: "equationOperator"
}));
