import { Modal } from "antd";
import strings from "localisation/strings";
import React from "react";
import UpsertTemplateForm from "./UpsertTemplateForm";
import { TemplateModalProps, useTemplateModal } from "./useTemplateModal";

interface AddTemplateModalProps extends TemplateModalProps {
  onCancel: () => any;
  visible: boolean;
}

const AddTemplateModal = ({
  visible,
  onCancel,
  ...props
}: AddTemplateModalProps) => {
  const {
    error,
    loading,
    onAddTemplate,
    onModalClose,
    setForm
  } = useTemplateModal({ ...props });
  return (
    <Modal
      title={strings("projectTemplates.addTemplateModal.title")}
      okText={error ? strings("buttons.tryAgain") : strings("buttons.add")}
      cancelText={strings("buttons.cancel")}
      confirmLoading={loading}
      visible={visible}
      onOk={onAddTemplate}
      onCancel={onCancel}
      afterClose={onModalClose}
    >
      <UpsertTemplateForm
        ref={ref => setForm(ref)}
        error={error}
        projectId={props.projectId}
      />
    </Modal>
  );
};

export default AddTemplateModal;
