import { useMutation, useSubscription } from "@apollo/react-hooks";
import MUTATION_PUBLISH_TEMPLATE from "../queries/MUTATION_PUBLISH_TEMPLATE";
import QUERY_LATEST_PUBLICATIONS from "../queries/QUERY_LATEST_PUBLICATIONS";
import {
  MutationPublishTemplate,
  MutationPublishTemplateVariables,
} from "../queries/__generated__/MutationPublishTemplate";
import {
  QueryLatestPublications,
  QueryLatestPublicationsVariables,
} from "../queries/__generated__/QueryLatestPublications";

export default (variables: QueryLatestPublicationsVariables) => {
  const { data, error, loading } = useSubscription<
    QueryLatestPublications,
    QueryLatestPublicationsVariables
  >(QUERY_LATEST_PUBLICATIONS, { variables });
  const [publish, { loading: isPublishing }] = useMutation<
    MutationPublishTemplate,
    MutationPublishTemplateVariables
  >(MUTATION_PUBLISH_TEMPLATE);

  return { data, error, loading, publish, isPublishing };
};
