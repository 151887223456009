import { ApolloError } from "apollo-client";
import { any, contains, curry, prop } from "ramda";
import slugify from "slugify";

export const isSlugFormatted = (text: string) => slugify(text) === text;

export const containsAll = curry(
  (strings: string[], text: string) =>
    strings.filter(s => !contains(s, text)).length === 0
);

export const isDuplicateSlugError = (err: ApolloError) => {
  const errors = err.graphQLErrors;
  if (
    any<string>(
      containsAll(["duplicate key", "slug_"]),
      errors.map(prop("message"))
    )
  ) {
    return true;
  }
  return false;
};
