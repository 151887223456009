import React, { useState } from "react";
import { Button } from "antd";

import strings from "localisation/strings";
import AddTemplateModal from "./AddTemplateModal";

export interface AddTemplateButtonProps {
  projectId: string;
  className?: string;
}

const AddTemplateButton = ({
  projectId,
  className
}: AddTemplateButtonProps) => {
  const [modalVisible, setModalVisible] = useState(false);
  const onCancel = () => setModalVisible(false);
  const onDone = () => {
    setModalVisible(false);
  };
  return (
    <div className={className}>
      <Button type="primary" onClick={() => setModalVisible(true)}>
        {strings("projectTemplates.addTemplateButton")}
      </Button>
      <AddTemplateModal
        projectId={projectId}
        visible={modalVisible}
        onCancel={onCancel}
        onDone={onDone}
      />
    </div>
  );
};

export default AddTemplateButton;
