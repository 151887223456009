import { DocumentNode } from "graphql";

export default interface Refetch<Variables> {
  query: DocumentNode;
  variables?: Variables;
}

export const refetch: <V>(
  query: DocumentNode
) => (
  variables: V
) => { query: DocumentNode; variables: V } = query => variables => ({
  query,
  variables
});
