import { useCommitEntityText } from "components/projectTemplateScreen/editors/common/useCommitEntityText";
import { DatasState } from "state/template/DatasState";
import { ExampleDataState } from "state/template/ExampleDataState";
import { capitalize } from "templatio-evaluator/lib/main/evaluator/models/Capitalization";
import DataEntityInterface from "templatio-evaluator/lib/main/evaluator/models/DataEntityInterface";

export const useChangeData = ({
  dataEntity
}: {
  dataEntity?: DataEntityInterface;
}) => {
  const { exampleData } = ExampleDataState.useContainer();
  const { updateData } = DatasState.useContainer();
  const { commitEntityText } = useCommitEntityText();

  const changeData = (newData: Partial<DataEntityInterface>) => {
    if (dataEntity) {
      const newEntity = updateData({
        ...dataEntity,
        ...newData
      });
      const exampleText = exampleData[newEntity.variableId];
      commitEntityText({
        entity: newEntity,
        text: capitalize(newEntity.capitalization)(
          `${exampleText}` || newEntity.name || ""
        )
      });
    }
  };

  return { changeData };
};
