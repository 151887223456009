import { Row } from "components/common/Flex";
import React, { ReactNode } from "react";
import { BranchFieldsState } from "state/template/BranchFieldsState";
import styled from "style/themed";
import { hasSlots } from "templatio-evaluator";
import BranchEquationTest from "templatio-evaluator/lib/main/evaluator/models/BranchEquationTest";
import { Equation } from "templatio-evaluator/lib/main/evaluator/models/EquationInterfaces";
import BranchCondition from "./BranchCondition";
import { MultiBranchButton } from "./MultiBranchButton";
import { RemoveBranchFieldButton } from "./RemoveBranchFieldButton";
import TestLogicButton from "./TestLogicButton";

const FieldHeader = styled.div`
  padding: ${({ theme }) => theme.margin.medium};
`;

export interface BranchFieldHeaderProps {
  fieldId: string;
  dragHandle?: ReactNode;
}

const BranchFieldHeader = ({ fieldId, dragHandle }: BranchFieldHeaderProps) => {
  const {
    getBranchEquation,
    setBranchEquation,
    getBranchEquationTests,
    setBranchEquationTests
  } = BranchFieldsState.useContainer();
  const logicTree = getBranchEquation(fieldId);
  const setLogicTree = (equation: Equation) =>
    setBranchEquation(fieldId, equation);
  const tests = getBranchEquationTests(fieldId);
  const setTests = (newTests: BranchEquationTest[]) =>
    setBranchEquationTests(fieldId, newTests);

  return (
    <FieldHeader>
      <Row mainAxis="space-between" crossAxis="center">
        {dragHandle}
        <Row grow={0} margin="small">
          <MultiBranchButton
            visible={hasSlots(logicTree)}
            branchFieldId={fieldId}
          />
          <Row margin="small" />
          <TestLogicButton
            logicTree={logicTree}
            tests={tests}
            setTests={setTests}
          />
          <Row margin="small" />
          <RemoveBranchFieldButton fieldId={fieldId} />
        </Row>
      </Row>
      <BranchCondition logicTree={logicTree} setLogicTree={setLogicTree} />
    </FieldHeader>
  );
};

export default BranchFieldHeader;
