const theme = {
  color: {
    background: {
      primary: "#ffffff",
      secondary: "#eeeeee",
      tertiary: "#c3c5c7",
      quaternary: "#edeff2",
      opposite: "#515052"
    },
    foreground: {
      primary: "#363e5b",
      secondary: "#767e9b",
      tertiary: "#c3c5c7",
      quaternary: "#edeff2",
      secondaryLight: "#3B4159",
      action: "#00AD8D",
      antiPrimary: "#ffffff",
      border: "#e8e8e8"
    },
    entities: {
      data: "#7AC328",
      synonym: "#B567C5",
      branch: "#5EA0EC",
      missing: "#FF0000"
    }
  },
  margin: {
    small: "4px",
    medium: "8px",
    large: "16px",
    xlarge: "32px"
  },
  font: {
    size: {
      small: "12px",
      medium: "14px",
      large: "20px",
      xlarge: "28px"
    }
  },
  border: {
    radius: {
      small: "3px",
      large: "11px"
    },
    color: {
      primary: "#ddd"
    }
  },
  icon: {
    size: {
      small: "12px",
      medium: "14px"
    }
  }
};

export type ThemeInterface = typeof theme;

export default theme;
