import { Icon } from "antd";
import { IconProps } from "antd/lib/icon";
import React from "react";
import globalTheme from "style/Theme";
import styled from "style/themed";

interface ThemedIconProps extends IconProps {
  iconTheme?: "filled" | "outlined" | "twoTone";
}

const ThemedIcon = ({ iconTheme, ...props }: ThemedIconProps) => (
  <Icon theme={iconTheme} {...props} />
);

interface TIconProps extends ThemedIconProps {
  padding?: keyof typeof globalTheme.margin;
  margin?: keyof typeof globalTheme.margin;
  marginVertical?: keyof typeof globalTheme.margin;
  marginHorizontal?: keyof typeof globalTheme.margin;
  color?: keyof typeof globalTheme.color.foreground;
  size?: keyof typeof globalTheme.icon.size;
}

export const TIcon = styled(ThemedIcon)<TIconProps>`
  ${({ padding, theme }) =>
    padding ? `padding: ${theme.margin[padding]};` : ""}
  ${({ margin, theme }) => (margin ? `margin: ${theme.margin[margin]};` : "")}
  ${({ marginHorizontal, theme }) =>
    marginHorizontal
      ? `margin-left: ${theme.margin[marginHorizontal]};
      margin-right: ${theme.margin[marginHorizontal]};`
      : ""}
  ${({ marginVertical, theme }) =>
    marginVertical
      ? `margin-top: ${theme.margin[marginVertical]}; margin-bottom: ${theme.margin[marginVertical]}`
      : ""}
  color: ${({ theme, color }) => theme.color.foreground[color || "primary"]};
  font-size: ${({ theme, size }) => theme.icon.size[size || "medium"]};
`;
