import { ApolloProvider } from "@apollo/react-hooks";
import { AppServiceClient, fakeClient } from "api/clients";
import ApolloClient from "apollo-client";
import React, { ReactNode, useEffect, useState } from "react";
import AuthState from "state/AuthState";

export interface HasuraProviderProps {
  children?: ReactNode;
}

const HasuraProvider = ({ children }: HasuraProviderProps) => {
  const { authState, tryRefreshingToken } = AuthState.useContainer();
  const authToken = authState.token;
  const [hasuraClient, setHasuraClient] = useState<
    ApolloClient<any> | undefined
  >();

  const stopClient = () => {
    if (hasuraClient) {
      hasuraClient.stop();
    }
  };

  useEffect(() => {
    stopClient();
    setHasuraClient(new AppServiceClient(tryRefreshingToken, authToken));
    return stopClient;
    // eslint-disable-next-line
  }, [authToken, tryRefreshingToken]);

  if (authState.status === "loggedOut" && hasuraClient) {
    stopClient();
    setHasuraClient(undefined);
  }

  return (
    <ApolloProvider client={hasuraClient || fakeClient}>
      {children}
    </ApolloProvider>
  );
};

export default HasuraProvider;
