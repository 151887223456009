import {
  RawDraftContentState,
  EditorState,
  ContentState,
  convertFromRaw
} from "draft-js";
import decorator from "components/projectTemplateScreen/entities/decorator";

export const createInitialState = (
  initialData?: RawDraftContentState | string
) => {
  if (initialData) {
    if (typeof initialData === "string") {
      return EditorState.createWithContent(
        ContentState.createFromText(initialData),
        decorator
      );
    }
    return EditorState.createWithContent(
      convertFromRaw(initialData),
      decorator
    );
  }
  return EditorState.createEmpty(decorator);
};
