import { last, prepend } from "ramda";
import { useState } from "react";
import EntityTypes from "templatio-evaluator/lib/main/evaluator/EntityTypes";
import FieldInterface from "templatio-evaluator/lib/main/evaluator/models/FieldInterface";
import SynonymFieldInterface from "templatio-evaluator/lib/main/evaluator/models/SynoymFieldInterface";
import { createContainer } from "unstated-next";
import { useGetEntity } from "./useGetEntity";
import { useGetField } from "./useGetField";

interface EntityEditor {
  id: string;
  type: EntityTypes | "TEMPLATE";
  entityTree: SynonymFieldInterface[];
}

const useEntityEditorState = (initialState?: {
  templateId: string;
  projectId: string;
}) => {
  const { templateId = "", projectId = "" } = initialState || {};
  const { getEntity } = useGetEntity();
  const { getField } = useGetField();

  const [editor, setEditor] = useState<EntityEditor>({
    id: templateId,
    type: "TEMPLATE",
    entityTree: []
  });

  const getEntityTree = (
    entityId: string,
    tree: FieldInterface[] = []
  ): FieldInterface[] => {
    const entityData = getEntity(entityId);
    if (entityData) {
      const { parentId: entityParentId } = entityData;
      if (templateId !== entityParentId) {
        const parentEntityField: FieldInterface | undefined = getField(
          entityParentId
        );
        if (parentEntityField) {
          const { parentId: fieldParentId } = parentEntityField;
          return getEntityTree(fieldParentId, prepend(parentEntityField, tree));
        }
      }
    }
    return tree;
  };

  const goOneLevelUp = () => {
    const { entityTree } = editor;
    const field = last(entityTree);
    if (field) {
      updateEditor({ id: field.parentId });
    } else {
      updateEditor({ id: templateId });
    }
  };

  const updateEditor = ({ id }: Pick<EntityEditor, "id">) => {
    const entityTree = getEntityTree(id);
    const entityData = getEntity(id);

    setEditor({
      id,
      type: entityData ? entityData.type : "TEMPLATE",
      entityTree
    });
  };

  const isEntityBeingEdited = (localId: string) => {
    const entityTree = editor.entityTree;
    return (
      editor.id === localId ||
      -1 < entityTree.findIndex(field => field.parentId === localId)
    );
  };

  return {
    editor,
    updateEditor,
    isEntityBeingEdited,
    getEntityTree,
    projectId,
    templateId,
    goOneLevelUp
  };
};

export const EntityEditorState = createContainer(useEntityEditorState);
