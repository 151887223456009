import gql from "graphql-tag";

export default gql`
  mutation QueryCreateExampleData(
    $projectId: uuid!
    $rows: jsonb!
    $dataVariables: [data_variables_insert_input!]!
  ) {
    createExampleData: insert_data_examples(
      objects: [{ project_id: $projectId, rows: $rows }]
      on_conflict: {
        constraint: data_examples_project_id_key
        update_columns: [rows]
      }
    ) {
      affected_rows
    }

    deleteDataVariables: delete_data_variables(
      where: { project_id: { _eq: $projectId } }
    ) {
      affected_rows
    }

    createDataVariables: insert_data_variables(objects: $dataVariables) {
      affected_rows
    }
  }
`;
