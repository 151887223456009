import { Breadcrumb } from "antd";
import strings from "localisation/strings";
import { Paths } from "navigation/Paths";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";

interface ProjectBreadcrumbItemsProps {
  projectSlug: string;
  projectName: string;
}

export const projectBreadcrumbItems = ({
  projectSlug,
  projectName
}: ProjectBreadcrumbItemsProps) => [
  <Breadcrumb.Item key={Paths.projects.path}>
    <Link to={Paths.projects.path}>{strings("breadcrumbs.projects")}</Link>
  </Breadcrumb.Item>,
  <Breadcrumb.Item key={Paths.projectTemplates.path}>
    <Link to={Paths.projectTemplates.withParams({ projectSlug })}>
      {projectName}
    </Link>
  </Breadcrumb.Item>
];

interface TemplateBreadcrumbItemsProps extends ProjectBreadcrumbItemsProps {
  templateSlug: string;
  templateName: string;
}

export const templateBreadcrumbItems = ({
  projectSlug,
  projectName,
  templateSlug,
  templateName
}: TemplateBreadcrumbItemsProps) => [
  ...projectBreadcrumbItems({ projectSlug, projectName }),
  <Breadcrumb.Item key={Paths.projectTemplate.path}>
    <Link
      to={Paths.projectTemplate.withParams({
        projectSlug,
        templateSlug
      })}
    >
      {templateName}
    </Link>
  </Breadcrumb.Item>
];

const RouteBreadcrumbs = ({ match }: RouteComponentProps<any>) => {
  console.log(match);
  return null;
};

export default withRouter(RouteBreadcrumbs);
