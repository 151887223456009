import { Form, Input, InputNumber } from "antd";
import { ValidationRule, WrappedFormUtils } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import styled from "styled-components";

interface FormFieldProps {
  fieldKey: string;
  label: string;
  form: WrappedFormUtils;
  rules?: ValidationRule[];
  type?: "number" | "textArea";
}

const FullWidthInputNumber = styled(InputNumber)`
  width: 100%;
`;

const FormField = ({ fieldKey, label, rules, type, form }: FormFieldProps) => {
  let component = <Input placeholder={label} />;
  if (type === "number") {
    component = <FullWidthInputNumber placeholder={label} type="number" />;
  }
  if (type === "textArea") {
    component = (
      <TextArea placeholder={label} autosize={{ minRows: 2, maxRows: 6 }} />
    );
  }
  return (
    <Form.Item label={label}>
      {form.getFieldDecorator(fieldKey, {
        rules
      })(component)}
    </Form.Item>
  );
};

export default FormField;
