import { Drawer } from "antd";
import { DrawerProps } from "antd/lib/drawer";
import React, { ReactNode, useEffect, useState } from "react";

export const UnmountingDrawer = ({
  visible,
  ...props
}: DrawerProps & { children: ReactNode }) => {
  const [isDrawerMounted, setIsDrawerMounted] = useState(visible);

  useEffect(() => {
    if (visible && !isDrawerMounted) {
      setIsDrawerMounted(true);
    }
  }, [visible, isDrawerMounted]);

  return (
    <>
      {isDrawerMounted && (
        <Drawer
          destroyOnClose
          afterVisibleChange={isVisible => {
            if (!isVisible) {
              setIsDrawerMounted(false);
            }
          }}
          visible={visible}
          {...props}
        />
      )}
    </>
  );
};
