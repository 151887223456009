import gql from "graphql-tag";

export default gql`
  query QueryMe($userId: uuid!) {
    users(where: { id: { _eq: $userId } }) {
      primaryEmail: primary_email
      id
    }
  }
`;
