import { update } from "ramda";
import SlotFieldInterface from "templatio-evaluator/lib/main/evaluator/models/SlotField";
import { BranchFieldsState } from "../BranchFieldsState";

export const useUpdateSlotField = () => {
  const {
    updateBranchField,
    getBranchField
  } = BranchFieldsState.useContainer();

  const updateSlotField = (field: SlotFieldInterface) => {
    const branchField = getBranchField({ fieldId: field.parentId });
    if (branchField) {
      const { slotFields = [] } = branchField;
      const newFields = update(field.index, field, slotFields);
      updateBranchField({
        ...branchField,
        slotFields: newFields
      });
    }
  };

  return { updateSlotField };
};
