import { Spin } from "antd";
import React from "react";
import AppContainer from "./AppContainer";

const LoadingScreen = () => (
  <AppContainer oppositeTheme>
    <Spin size="large" />
  </AppContainer>
);

export default LoadingScreen;
