import { assoc, Omit } from "ramda";
import { useState } from "react";
import { Snapshot, StateInterface } from "templatio-evaluator";
import EntityTypes from "templatio-evaluator/lib/main/evaluator/EntityTypes";
import SynonymEntityInterface from "templatio-evaluator/lib/main/evaluator/models/SynonymEntityInterface";
import { createContainer } from "unstated-next";
import uuid from "uuid/v4";

const makeSynonym = (
  data: Omit<SynonymEntityInterface, "entityId" | "type">
) => {
  const synonym: SynonymEntityInterface = {
    ...data,
    entityId: uuid(),
    type: EntityTypes.SYNONYM
  };
  return synonym;
};

const useSynonymsState = (
  initialState?: StateInterface<SynonymEntityInterface>
) => {
  const [synonyms, setSynonyms] = useState(initialState || {});

  const getSynonymEntity = ({ synonymId }: { synonymId: string }) =>
    synonyms[synonymId];

  const addSynonym = (
    data: Omit<SynonymEntityInterface, "entityId" | "type">
  ) => {
    const synonym = makeSynonym(data);
    setSynonyms(assoc(synonym.entityId, synonym));
    return synonym;
  };

  const updateSynonym = (newData: SynonymEntityInterface) => {
    setSynonyms(assoc(newData.entityId, newData));
    return newData;
  };

  const addFromSnapshot = (snapshot: Snapshot) => {
    setSynonyms({ ...synonyms, ...snapshot.synonymEntities });
  };

  return {
    synonyms,
    getSynonymEntity,
    addSynonym,
    updateSynonym,
    addFromSnapshot
  };
};

export const SynonymsState = createContainer(useSynonymsState);
