import { Input } from "antd";
import strings from "localisation/strings";
import React, { useEffect, useState } from "react";
import styled from "style/themed";

interface TextProps {
  isPlaceholder: boolean;
}

const Text = styled.span<TextProps>`
  color: ${({ theme, isPlaceholder }) =>
    isPlaceholder
      ? theme.color.foreground.tertiary
      : theme.color.foreground.primary};
  white-space: pre-line;
`;

const ClickableArea = styled.div`
  cursor: pointer;
  min-height: 32px;
  padding: 5px 0px;
`;

const MinWidthInput = styled(Input)`
  min-width: 200px;
`;

export interface EditableTextProps {
  children: string;
  onTextChanged?: (newText: string) => void;
  loading?: boolean;
  type?: "input" | "textArea";
  onPaste?: (text: string) => void;
  onCancel?: (currentText: string) => void;
  onChange?: (text: string) => void;
  onPastedText?: (text: string) => void;
  editing?: boolean;
}

export const EditableText = ({
  children,
  loading,
  onTextChanged = () => {},
  type = "input",
  onPaste,
  onCancel: onCancelFromProps = () => {},
  onChange: onChangeFromProps = () => {},
  editing: editingFromProps,
  onPastedText = () => {}
}: EditableTextProps) => {
  const [editing, setEditing] = useState(false);
  const [inputValue, setInputValue] = useState(children);
  useEffect(() => {
    setInputValue(children);
  }, [children]);
  const onUpdate = () => {
    setEditing(false);
    setInputValue(children);
    onTextChanged(inputValue);
  };
  const onCancel = () => {
    setEditing(false);
    onCancelFromProps(inputValue);
    setInputValue(children);
  };
  const [pasteWaiting, setPasteWaiting] = useState(false);

  useEffect(() => {
    if (editingFromProps === false) {
      onCancel();
    }
    // eslint-disable-next-line
  }, [editingFromProps]);
  useEffect(() => {
    if (pasteWaiting) {
      onPastedText(inputValue);
      setPasteWaiting(false);
    }
    // eslint-disable-next-line
  }, [inputValue]);

  const onEnter = (
    ev: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (ev.metaKey || ev.ctrlKey) {
      onUpdate();
    }
  };
  const onKeyDown = (
    ev: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (ev.key === "Escape" && editing) {
      ev.stopPropagation();
      onCancel();
    }
  };
  const onChange = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInputValue(ev.target.value);
    onChangeFromProps(ev.target.value);
  };
  const onInputPaste = (
    ev: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPasteWaiting(true);
    if (onPaste) {
      onPaste(ev.clipboardData.getData("Text"));
    }
  };
  if (!editing) {
    return (
      <ClickableArea onClick={() => setEditing(true)}>
        <Text isPlaceholder={!children}>
          {children || strings("commonComponents.editableText.placeholder")}
        </Text>
      </ClickableArea>
    );
  }

  const inputProps = {
    autoFocus: true,
    onPressEnter: onEnter,
    disabled: loading,
    value: inputValue,
    onChange,
    onKeyDown,
    onBlur: onUpdate,
    onPaste: onInputPaste
  };

  if (type === "textArea") {
    return <Input.TextArea {...inputProps} autosize={{ minRows: 2 }} />;
  }
  return <MinWidthInput {...inputProps} onPaste={onInputPaste} />;
};
