import { Popover } from "antd";
import { PopoverButtons } from "components/common/actions/PopoverButtons";
import TemplatioEditor, {
  TemplatioEditorProps
} from "components/projectTemplateScreen/TemplatioEditor";
import csv from "csvtojson";
import strings from "localisation/strings";
import React, { useState } from "react";

export interface CSVHandlingEditorProps extends TemplatioEditorProps {
  onCSVPasted: (csvData: string[][]) => void;
}

export const CSVHandlingEditor = ({
  onCSVPasted,
  ...props
}: CSVHandlingEditorProps) => {
  const [pastedText, setPastedText] = useState("");

  const handleCSVPaste = async (text: string) => {
    const pasted: string[][] = await csv({
      delimiter: [",", ";", "\t"],
      noheader: true,
      output: "csv"
    }).fromString(text);
    onCSVPasted(pasted);
  };

  const onConfirm = () => {
    handleCSVPaste(pastedText);
    setPastedText("");
  };

  const onChange = () => {
    if (pastedText) {
      setPastedText("");
    }
  };

  const onCancel = () => setPastedText("");
  return (
    <Popover
      title={strings(
        "projectTemplate.branchTools.slotFieldsTable.pasteCSVTitle"
      )}
      visible={!!pastedText}
      content={<PopoverButtons onCancel={onCancel} onConfirm={onConfirm} />}
    >
      <TemplatioEditor
        onHandleExternalSourcePaste={setPastedText}
        onTextChanged={onChange}
        {...props}
      />
    </Popover>
  );
};
