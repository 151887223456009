import { useEffect, useState } from "react";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

interface UseDebounceParams<T> {
  debounceBy?: number;
  initialValue: T;
}

const useDebouncedValue = <T,>({
  debounceBy = 500,
  initialValue
}: UseDebounceParams<T>) => {
  const [value, setValueInner] = useState(initialValue);
  const [debouncedValue, setDebouncedValue] = useState<T>(initialValue);
  const [change$] = useState(new Subject<T>());

  useEffect(() => {
    const subscription = change$
      .pipe(debounceTime(debounceBy))
      .subscribe(setDebouncedValue);
    return () => {
      subscription.unsubscribe();
    };
  }, [change$, debounceBy]);

  const setValue = (newValue: T) => {
    setValueInner(newValue);
    change$.next(newValue);
  };

  return { value, setValue, debouncedValue };
};

export default useDebouncedValue;
