import React, { Component } from "react";
import { Button } from "antd";

import strings from "localisation/strings";
import AddProjectModal from "./AddProjectModal";

class AddProjectButton extends Component<{}> {
  state = { modalVisible: false };

  showModal = () => this.setState({ modalVisible: true });

  onCancel = () => {
    this.setState({ modalVisible: false });
  };

  onDone = () => {
    this.setState({ modalVisible: false });
  };

  render() {
    return (
      <>
        <Button type="primary" onClick={this.showModal}>
          {strings("projects.addNew")}
        </Button>
        <AddProjectModal
          visible={this.state.modalVisible}
          onDone={this.onDone}
          onCancel={this.onCancel}
        />
      </>
    );
  }
}

export default AddProjectButton;
