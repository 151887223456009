import { useCallback } from "react";
import { BranchesState } from "state/template/BranchesState";
import { BranchFieldsState } from "state/template/BranchFieldsState";
import { DatasState } from "state/template/DatasState";
import { DraftStates } from "state/template/DraftStates";
import { SynonymFieldsState } from "state/template/SynonymFieldsState";
import { SynonymsState } from "state/template/SynonymsState";
import { LocalSnapshot } from "state/template/useSnapshot";

export const useSnapshotUpdate = () => {
  const { addFromSnapshot: addDraftStates } = DraftStates.useContainer();
  const { addFromSnapshot: addBranches } = BranchesState.useContainer();
  const { addFromSnapshot: addSynonyms } = SynonymsState.useContainer();
  const { addFromSnapshot: addDatas } = DatasState.useContainer();
  const {
    addFromSnapshot: addSynonymFields
  } = SynonymFieldsState.useContainer();
  const { addFromSnapshot: addBranchFields } = BranchFieldsState.useContainer();

  const mergeSnapshot = useCallback(
    (snapshot: LocalSnapshot) => {
      addDraftStates(snapshot);
      addBranches(snapshot);
      addSynonyms(snapshot);
      addDatas(snapshot);
      addSynonymFields(snapshot);
      addBranchFields(snapshot);
    },
    [
      addDraftStates,
      addBranches,
      addSynonyms,
      addDatas,
      addSynonymFields,
      addBranchFields
    ]
  );

  return { mergeSnapshot };
};
