import { Button, Icon } from "antd";
import { Column } from "components/common/Flex";
import { SimpleText } from "components/common/SimpleText";
import DataDropdown from "components/projectTemplateScreen/DataDropdown";
import { useDataVariable } from "components/projectTemplateScreen/editors/data/useDataVariable";
import { useChangeData } from "components/projectTemplateScreen/entities/renderers/useChangeData";
import strings from "localisation/strings";
import React from "react";
import { DatasState } from "state/template/DatasState";
import { Capitalization } from "templatio-evaluator/lib/main/evaluator/models/Capitalization";
import ToolsDoneButton from "../common/ToolsDoneButton";
import { ToolsSubtitle } from "../common/ToolsSubtitle";
import { ToolsTitle } from "../common/ToolsTitle";
import TreeViewEditor from "../synonym/TreeViewEditor";
import CapitalizationDropdown from "./CapitalizationDropdown";

interface DataToolsProps {
  templateId: string;
  entityId: string;
}

export const DataTools = ({ entityId, templateId }: DataToolsProps) => {
  const { getDataEntity } = DatasState.useContainer();
  const dataEntity = getDataEntity({ dataId: entityId });
  const { changeData } = useChangeData({ dataEntity });
  const { getVariableName } = useDataVariable();
  if (!dataEntity) {
    return null;
  }

  const onCapitalizationChanged = (capitalization: Capitalization) => {
    changeData({ capitalization });
  };

  return (
    <Column>
      <ToolsDoneButton />
      <TreeViewEditor entityId={entityId} />
      <ToolsTitle>{strings("projectTemplate.dataTools.title")}</ToolsTitle>
      <ToolsSubtitle>
        {strings("projectTemplate.dataTools.subtitle")}
      </ToolsSubtitle>
      <DataDropdown
        trigger={["click"]}
        onVariableSelected={variable =>
          changeData({ variableId: variable.id, name: variable.name })
        }
        selectedVariableId={dataEntity.variableId}
      >
        <Button>
          {getVariableName(dataEntity.variableId)}
          <Icon type="down" />
        </Button>
      </DataDropdown>
      <SimpleText marginVertical="medium">
        {`${strings("projectTemplate.dataTools.capitalization")}:`}
      </SimpleText>
      <CapitalizationDropdown
        onSelected={onCapitalizationChanged}
        selectedCapitalization={dataEntity.capitalization}
      />
    </Column>
  );
};
