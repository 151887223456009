import { Icon, Layout, Menu } from "antd";
import { ClickParam, MenuProps } from "antd/lib/menu";
import strings from "localisation/strings";
import React, { ReactNode } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import styled from "style/themed";
import CurrentUser from "./CurrentUser";
import AuthState from "state/AuthState";
import { Paths } from "navigation/Paths";

interface ThemedMenuProps extends MenuProps {
  menuTheme?: "dark" | "light";
}

const ThemedMenu = ({ menuTheme, ...props }: ThemedMenuProps) => (
  <Menu theme={menuTheme} {...props} />
);

type StyledMenuProps = ThemedMenuProps & { children: ReactNode };

const StyledMenu = styled(ThemedMenu)<StyledMenuProps>`
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.color.background.opposite} !important;
`;

const SidebarContainer = styled(Layout.Sider)`
  background-color: ${({ theme }) => theme.color.background.opposite};
`;

const Sidebar = ({ match, location }: RouteComponentProps) => {
  const { signOut } = AuthState.useContainer();
  const handleClick = ({ key }: ClickParam) => {
    if (key === "logout") {
      signOut();
      return;
    }
  };

  let optionalMenu: ReactNode[] = [];
  const pathSegments = location.pathname.split("/");
  let selectedKey = pathSegments[1] === "projects" ? "projects" : "";
  if (
    pathSegments[3] === "data" ||
    pathSegments[3] === "templates" ||
    pathSegments[3] === "members"
  ) {
    selectedKey = pathSegments[3];
    optionalMenu = [
      ...optionalMenu,
      <Menu.Item key="data">
        <Link to={Paths.projectData.withParams(match.params as any)}>
          <Icon type="table" />
          {strings("sidebar.items.data")}
        </Link>
      </Menu.Item>,
      <Menu.Item key="templates">
        <Link to={Paths.projectTemplates.withParams(match.params as any)}>
          <Icon type="code" />
          {strings("sidebar.items.templates")}
        </Link>
      </Menu.Item>,
      <Menu.Item key="members">
        <Link to={Paths.projectMembers.withParams(match.params as any)}>
          <Icon type="user" />
          {strings("sidebar.items.members")}
        </Link>
      </Menu.Item>
    ];
  }

  return (
    <SidebarContainer trigger={null} collapsible>
      <StyledMenu
        onClick={handleClick}
        selectedKeys={[selectedKey]}
        mode="inline"
        menuTheme="dark"
      >
        <Menu.Item key="projects">
          <Link to={Paths.projects.path}>
            <Icon type="project" />
            {strings("sidebar.items.projects")}
          </Link>
        </Menu.Item>
        {optionalMenu}
        <div style={{ flexGrow: 1 }} />
        <Menu.Item key="account">
          <Link to={Paths.account.withParams()}>
            <Icon type="user" />
            <CurrentUser />
          </Link>
        </Menu.Item>
        <Menu.Item key="logout">
          <Icon type="logout" />
          {strings("sidebar.items.logout")}
        </Menu.Item>
      </StyledMenu>
    </SidebarContainer>
  );
};

export default withRouter(Sidebar);
