import React from "react";
// import BranchCondition from "components/projectTemplateScreen/editors/branch/BranchCondition";
import styled from "style/themed";

const PlaygroundContainer = styled.div`
  display: flex;
  flex-grow: 1;
  max-width: 600px;
  padding: ${({ theme }) => theme.margin.xlarge};
  background-color: ${({ theme }) => theme.color.background.tertiary};
`;

const PlaygroundScreen = () => {
  return <PlaygroundContainer>{/* <BranchCondition /> */}</PlaygroundContainer>;
};

export default PlaygroundScreen;
