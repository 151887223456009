import gql from "graphql-tag";

export default gql`
  query QuerySearchMembersToAdd(
    $partialEmail: String!
    $excludedProjectId: uuid!
  ) {
    users(
      where: {
        _and: [
          { primary_email: { _ilike: $partialEmail } }
          {
            _not: {
              project_memberships: { project_id: { _eq: $excludedProjectId } }
            }
          }
        ]
      }
      limit: 10
    ) {
      id
      email: primary_email
    }
  }
`;
