import { Form, Input } from "antd";
import { WrappedFormUtils } from "antd/lib/form/Form";
import { InputProps } from "antd/lib/input";
import { ApolloError } from "apollo-client";
import strings from "localisation/strings";
import { append } from "ramda";
import React, { useEffect, useState } from "react";
import slugify from "slugify";
import { isDuplicateSlugError, isSlugFormatted } from "./utils";

const getValidationErrors = (inputValue?: string) => {
  let errors: Error[] | undefined;
  if (!inputValue) {
    errors = append(new Error(strings("slugInput.errors.empty")), errors || []);
  } else if (inputValue.length < 3) {
    errors = append(
      new Error(strings("slugInput.errors.tooShort")),
      errors || []
    );
  }
  if (!isSlugFormatted(inputValue || "")) {
    errors = append(
      new Error(strings("slugInput.errors.specialCharacters")),
      errors || []
    );
  }
  return errors;
};

const validator = (
  rule: any,
  inputValue: string | undefined,
  callback: (error?: Error) => void
) => {
  const errors = getValidationErrors(inputValue);
  if (errors) {
    callback(errors[0]);
  } else {
    callback();
  }
};

const getDuplicateErrors = (slugCount: number) => {
  let errors: Error[] | undefined;
  const checkDuplicate = slugCount !== undefined;
  if (checkDuplicate && slugCount) {
    errors = append(
      new Error(strings("slugInput.errors.duplicate")),
      errors || []
    );
  }
  return errors;
};

interface SlugInputItemProps extends InputProps {
  slugFrom?: string;
  formUtils: WrappedFormUtils;
  checkSlug: (slug: string) => void;
  sameSlugCount: number;
  error?: ApolloError;
}

const SlugInputItem = ({
  slugFrom = "",
  form,
  checkSlug,
  sameSlugCount,
  formUtils,
  error,
  ...inputProps
}: SlugInputItemProps) => {
  const inputValue: string | undefined = formUtils.getFieldValue("slug");
  const setInputValue = (value: string) =>
    formUtils.setFieldsValue({ slug: value });
  const [loading, setLoading] = useState(false);

  const setErrors = (errors?: Error[]) => {
    formUtils.setFields({
      slug: {
        value: inputValue,
        errors
      }
    });
  };

  useEffect(() => {
    if (error && isDuplicateSlugError(error)) {
      formUtils.setFields({
        slug: {
          value: formUtils.getFieldValue("slug"),
          errors: [new Error(strings("slugInput.errors.duplicate"))]
        }
      });
    }
    // eslint-disable-next-line
  }, [error]);

  useEffect(() => {
    setInputValue(slugify(slugFrom, { lower: true }));
    // eslint-disable-next-line
  }, [slugFrom]);

  useEffect(() => {
    if (!loading) {
      setErrors(getDuplicateErrors(sameSlugCount));
    }
    // eslint-disable-next-line
  }, [sameSlugCount, loading]);

  const validate = async () => {
    const errors = getValidationErrors(inputValue);
    if (!errors && inputValue) {
      setLoading(true);
      await checkSlug(inputValue);
      setLoading(false);
    }
  };

  return (
    <Form.Item label={strings("slugInput.label")}>
      {formUtils.getFieldDecorator("slug", {
        rules: [
          {
            validator
          }
        ]
      })(
        <Input
          {...inputProps}
          onBlur={validate}
          placeholder={strings("slugInput.label")}
        />
      )}
    </Form.Item>
  );
};

export default SlugInputItem;
