import { Table } from "antd";
import { TableProps } from "antd/lib/table";
import { fromPairs, KeyValuePair, Omit, pluck } from "ramda";
import React from "react";
import styled from "style/themed";
import { AddExampleDataButton } from "./AddExampleDataButton";
import { ExampleDataCell, ExampleDataCellProps } from "./ExampleDataCell";
import ExampleDataRow from "./ExampleDataRow";
import {
  QueryProjectData_project_dataVariables,
  QueryProjectData_project_exampleData
} from "./queries/__generated__/QueryProjectData";
import {
  RemoveExampleDataButton,
  RemoveExampleDataButtonProps
} from "./RemoveExampleDataButton";

const variablesToCols = (
  variables: QueryProjectData_project_dataVariables[]
) => {
  return variables.map(({ id, name }) => ({
    title: name,
    dataIndex: id,
    id,
    render: (props: CellProps) => <ExampleDataCell variableId={id} {...props} />
  }));
};

type CellProps = Omit<ExampleDataCellProps, "variableId">;

interface ColumnTypes {
  [variableId: string]: number | CellProps;
}

const FullWidthTable = styled(Table)<TableProps<any>>`
  flex: 1;
`;

export interface ExampleDataTableProps {
  exampleData: QueryProjectData_project_exampleData | null;
  variables: QueryProjectData_project_dataVariables[];
  projectId: string;
}

const ExampleDataTable = ({
  variables,
  exampleData,
  projectId
}: ExampleDataTableProps) => {
  const cols = [
    {
      dataIndex: "removeButton",
      render: (props: RemoveExampleDataButtonProps) => (
        <RemoveExampleDataButton {...props} />
      )
    },
    ...variablesToCols(variables)
  ];
  const variableIds = pluck("id", variables);
  const exampleDataRows = (exampleData
    ? exampleData.rows
    : []) as ExampleDataRow[];
  const rows = exampleDataRows.map((row, rowIndex): ColumnTypes & {
    removeButton: RemoveExampleDataButtonProps;
  } => {
    const cellProps: CellProps = {
      rows: exampleDataRows,
      rowIndex,
      projectId
    };
    const data = fromPairs(
      variableIds.map(id => [id, cellProps] as KeyValuePair<string, CellProps>)
    );
    return {
      key: rowIndex,
      removeButton: { rows: exampleDataRows, rowIndex, projectId },
      ...data
    };
  });
  return (
    <>
      <AddExampleDataButton
        projectId={projectId}
        rows={exampleDataRows}
        variableIds={variableIds}
      />
      <FullWidthTable
        size="small"
        dataSource={rows}
        columns={cols}
        scroll={{ x: true }}
      />
    </>
  );
};

export default ExampleDataTable;
