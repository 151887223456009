/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * unique or primary key constraints on table "data_examples"
 */
export enum data_examples_constraint {
  data_examples_pkey = "data_examples_pkey",
  data_examples_project_id_key = "data_examples_project_id_key",
}

/**
 * update columns of table "data_examples"
 */
export enum data_examples_update_column {
  created_at = "created_at",
  id = "id",
  project_id = "project_id",
  rows = "rows",
  updated_at = "updated_at",
}

export enum data_variable_types_enum {
  BOOLEAN = "BOOLEAN",
  NUMBER = "NUMBER",
  TEXT = "TEXT",
}

/**
 * unique or primary key constraints on table "data_variables"
 */
export enum data_variables_constraint {
  data_variables_pkey = "data_variables_pkey",
}

/**
 * update columns of table "data_variables"
 */
export enum data_variables_update_column {
  created_at = "created_at",
  id = "id",
  name = "name",
  project_id = "project_id",
  type = "type",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "project_member_invites"
 */
export enum project_member_invites_constraint {
  project_member_invites_pkey = "project_member_invites_pkey",
}

/**
 * update columns of table "project_member_invites"
 */
export enum project_member_invites_update_column {
  created_at = "created_at",
  created_by = "created_by",
  id = "id",
  invitee_email = "invitee_email",
  project_id = "project_id",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "project_members"
 */
export enum project_members_constraint {
  project_members_pkey = "project_members_pkey",
  project_members_project_id_user_id_key = "project_members_project_id_user_id_key",
}

/**
 * update columns of table "project_members"
 */
export enum project_members_update_column {
  created_at = "created_at",
  id = "id",
  project_id = "project_id",
  updated_at = "updated_at",
  user_id = "user_id",
}

/**
 * unique or primary key constraints on table "projects"
 */
export enum projects_constraint {
  projects_pkey = "projects_pkey",
  projects_slug_key = "projects_slug_key",
}

/**
 * update columns of table "projects"
 */
export enum projects_update_column {
  created_at = "created_at",
  id = "id",
  name = "name",
  owner_id = "owner_id",
  publishing_url = "publishing_url",
  slug = "slug",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "template_snapshots"
 */
export enum template_snapshots_constraint {
  template_snapshots_pkey = "template_snapshots_pkey",
  template_snapshots_template_id_key = "template_snapshots_template_id_key",
}

/**
 * update columns of table "template_snapshots"
 */
export enum template_snapshots_update_column {
  created_at = "created_at",
  id = "id",
  snapshot_data = "snapshot_data",
  template_id = "template_id",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "templates"
 */
export enum templates_constraint {
  templates_pkey = "templates_pkey",
  templates_slug_project_id_key = "templates_slug_project_id_key",
}

/**
 * update columns of table "templates"
 */
export enum templates_update_column {
  created_at = "created_at",
  created_by = "created_by",
  id = "id",
  name = "name",
  project_id = "project_id",
  slug = "slug",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "users"
 */
export enum users_constraint {
  users_auth_user_id_key = "users_auth_user_id_key",
  users_id_key = "users_id_key",
  users_pkey = "users_pkey",
}

/**
 * update columns of table "users"
 */
export enum users_update_column {
  auth_user_id = "auth_user_id",
  created_at = "created_at",
  id = "id",
  primary_email = "primary_email",
  updated_at = "updated_at",
}

/**
 * expression to compare columns of type String. All fields are combined with logical 'AND'.
 */
export interface String_comparison_exp {
  _eq?: string | null;
  _gt?: string | null;
  _gte?: string | null;
  _ilike?: string | null;
  _in?: string[] | null;
  _is_null?: boolean | null;
  _like?: string | null;
  _lt?: string | null;
  _lte?: string | null;
  _neq?: string | null;
  _nilike?: string | null;
  _nin?: string[] | null;
  _nlike?: string | null;
  _nsimilar?: string | null;
  _similar?: string | null;
}

/**
 * Boolean expression to filter rows from the table "data_examples". All fields are combined with a logical 'AND'.
 */
export interface data_examples_bool_exp {
  _and?: (data_examples_bool_exp | null)[] | null;
  _not?: data_examples_bool_exp | null;
  _or?: (data_examples_bool_exp | null)[] | null;
  created_at?: timestamptz_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  project?: projects_bool_exp | null;
  project_id?: uuid_comparison_exp | null;
  rows?: jsonb_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "data_examples"
 */
export interface data_examples_insert_input {
  created_at?: Hasura_timestamptz | null;
  id?: Hasura_uuid | null;
  project?: projects_obj_rel_insert_input | null;
  project_id?: Hasura_uuid | null;
  rows?: Hasura_jsonb | null;
  updated_at?: Hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "data_examples"
 */
export interface data_examples_obj_rel_insert_input {
  data: data_examples_insert_input;
  on_conflict?: data_examples_on_conflict | null;
}

/**
 * on conflict condition type for table "data_examples"
 */
export interface data_examples_on_conflict {
  constraint: data_examples_constraint;
  update_columns: data_examples_update_column[];
  where?: data_examples_bool_exp | null;
}

/**
 * expression to compare columns of type data_variable_types_enum. All fields are combined with logical 'AND'.
 */
export interface data_variable_types_enum_comparison_exp {
  _eq?: data_variable_types_enum | null;
  _in?: data_variable_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: data_variable_types_enum | null;
  _nin?: data_variable_types_enum[] | null;
}

/**
 * input type for inserting array relation for remote table "data_variables"
 */
export interface data_variables_arr_rel_insert_input {
  data: data_variables_insert_input[];
  on_conflict?: data_variables_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "data_variables". All fields are combined with a logical 'AND'.
 */
export interface data_variables_bool_exp {
  _and?: (data_variables_bool_exp | null)[] | null;
  _not?: data_variables_bool_exp | null;
  _or?: (data_variables_bool_exp | null)[] | null;
  created_at?: timestamptz_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  name?: String_comparison_exp | null;
  project?: projects_bool_exp | null;
  project_id?: uuid_comparison_exp | null;
  type?: data_variable_types_enum_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "data_variables"
 */
export interface data_variables_insert_input {
  created_at?: Hasura_timestamptz | null;
  id?: Hasura_uuid | null;
  name?: string | null;
  project?: projects_obj_rel_insert_input | null;
  project_id?: Hasura_uuid | null;
  type?: data_variable_types_enum | null;
  updated_at?: Hasura_timestamptz | null;
}

/**
 * on conflict condition type for table "data_variables"
 */
export interface data_variables_on_conflict {
  constraint: data_variables_constraint;
  update_columns: data_variables_update_column[];
  where?: data_variables_bool_exp | null;
}

/**
 * expression to compare columns of type jsonb. All fields are combined with logical 'AND'.
 */
export interface jsonb_comparison_exp {
  _contained_in?: Hasura_jsonb | null;
  _contains?: Hasura_jsonb | null;
  _eq?: Hasura_jsonb | null;
  _gt?: Hasura_jsonb | null;
  _gte?: Hasura_jsonb | null;
  _has_key?: string | null;
  _has_keys_all?: string[] | null;
  _has_keys_any?: string[] | null;
  _in?: Hasura_jsonb[] | null;
  _is_null?: boolean | null;
  _lt?: Hasura_jsonb | null;
  _lte?: Hasura_jsonb | null;
  _neq?: Hasura_jsonb | null;
  _nin?: Hasura_jsonb[] | null;
}

/**
 * input type for inserting array relation for remote table "project_member_invites"
 */
export interface project_member_invites_arr_rel_insert_input {
  data: project_member_invites_insert_input[];
  on_conflict?: project_member_invites_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "project_member_invites". All fields are combined with a logical 'AND'.
 */
export interface project_member_invites_bool_exp {
  _and?: (project_member_invites_bool_exp | null)[] | null;
  _not?: project_member_invites_bool_exp | null;
  _or?: (project_member_invites_bool_exp | null)[] | null;
  created_at?: timestamptz_comparison_exp | null;
  created_by?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  invitee_email?: String_comparison_exp | null;
  inviter?: users_bool_exp | null;
  project?: projects_bool_exp | null;
  project_id?: uuid_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "project_member_invites"
 */
export interface project_member_invites_insert_input {
  created_at?: Hasura_timestamptz | null;
  created_by?: Hasura_uuid | null;
  id?: Hasura_uuid | null;
  invitee_email?: string | null;
  inviter?: users_obj_rel_insert_input | null;
  project?: projects_obj_rel_insert_input | null;
  project_id?: Hasura_uuid | null;
  updated_at?: Hasura_timestamptz | null;
}

/**
 * on conflict condition type for table "project_member_invites"
 */
export interface project_member_invites_on_conflict {
  constraint: project_member_invites_constraint;
  update_columns: project_member_invites_update_column[];
  where?: project_member_invites_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "project_members"
 */
export interface project_members_arr_rel_insert_input {
  data: project_members_insert_input[];
  on_conflict?: project_members_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "project_members". All fields are combined with a logical 'AND'.
 */
export interface project_members_bool_exp {
  _and?: (project_members_bool_exp | null)[] | null;
  _not?: project_members_bool_exp | null;
  _or?: (project_members_bool_exp | null)[] | null;
  created_at?: timestamptz_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  project?: projects_bool_exp | null;
  project_id?: uuid_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  user_id?: uuid_comparison_exp | null;
}

/**
 * input type for inserting data into table "project_members"
 */
export interface project_members_insert_input {
  created_at?: Hasura_timestamptz | null;
  id?: Hasura_uuid | null;
  project?: projects_obj_rel_insert_input | null;
  project_id?: Hasura_uuid | null;
  updated_at?: Hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  user_id?: Hasura_uuid | null;
}

/**
 * on conflict condition type for table "project_members"
 */
export interface project_members_on_conflict {
  constraint: project_members_constraint;
  update_columns: project_members_update_column[];
  where?: project_members_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "projects"
 */
export interface projects_arr_rel_insert_input {
  data: projects_insert_input[];
  on_conflict?: projects_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "projects". All fields are combined with a logical 'AND'.
 */
export interface projects_bool_exp {
  _and?: (projects_bool_exp | null)[] | null;
  _not?: projects_bool_exp | null;
  _or?: (projects_bool_exp | null)[] | null;
  created_at?: timestamptz_comparison_exp | null;
  data_examples?: data_examples_bool_exp | null;
  data_variables?: data_variables_bool_exp | null;
  id?: uuid_comparison_exp | null;
  member_invites?: project_member_invites_bool_exp | null;
  members?: project_members_bool_exp | null;
  name?: String_comparison_exp | null;
  owner?: users_bool_exp | null;
  owner_id?: uuid_comparison_exp | null;
  publishing_url?: String_comparison_exp | null;
  slug?: String_comparison_exp | null;
  templates?: templates_bool_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "projects"
 */
export interface projects_insert_input {
  created_at?: Hasura_timestamptz | null;
  data_examples?: data_examples_obj_rel_insert_input | null;
  data_variables?: data_variables_arr_rel_insert_input | null;
  id?: Hasura_uuid | null;
  member_invites?: project_member_invites_arr_rel_insert_input | null;
  members?: project_members_arr_rel_insert_input | null;
  name?: string | null;
  owner?: users_obj_rel_insert_input | null;
  owner_id?: Hasura_uuid | null;
  publishing_url?: string | null;
  slug?: string | null;
  templates?: templates_arr_rel_insert_input | null;
  updated_at?: Hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "projects"
 */
export interface projects_obj_rel_insert_input {
  data: projects_insert_input;
  on_conflict?: projects_on_conflict | null;
}

/**
 * on conflict condition type for table "projects"
 */
export interface projects_on_conflict {
  constraint: projects_constraint;
  update_columns: projects_update_column[];
  where?: projects_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "template_snapshots"
 */
export interface template_snapshots_arr_rel_insert_input {
  data: template_snapshots_insert_input[];
  on_conflict?: template_snapshots_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "template_snapshots". All fields are combined with a logical 'AND'.
 */
export interface template_snapshots_bool_exp {
  _and?: (template_snapshots_bool_exp | null)[] | null;
  _not?: template_snapshots_bool_exp | null;
  _or?: (template_snapshots_bool_exp | null)[] | null;
  created_at?: timestamptz_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  snapshot_data?: jsonb_comparison_exp | null;
  template?: templates_bool_exp | null;
  template_id?: uuid_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "template_snapshots"
 */
export interface template_snapshots_insert_input {
  created_at?: Hasura_timestamptz | null;
  id?: Hasura_uuid | null;
  snapshot_data?: Hasura_jsonb | null;
  template?: templates_obj_rel_insert_input | null;
  template_id?: Hasura_uuid | null;
  updated_at?: Hasura_timestamptz | null;
}

/**
 * on conflict condition type for table "template_snapshots"
 */
export interface template_snapshots_on_conflict {
  constraint: template_snapshots_constraint;
  update_columns: template_snapshots_update_column[];
  where?: template_snapshots_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "templates"
 */
export interface templates_arr_rel_insert_input {
  data: templates_insert_input[];
  on_conflict?: templates_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "templates". All fields are combined with a logical 'AND'.
 */
export interface templates_bool_exp {
  _and?: (templates_bool_exp | null)[] | null;
  _not?: templates_bool_exp | null;
  _or?: (templates_bool_exp | null)[] | null;
  created_at?: timestamptz_comparison_exp | null;
  created_by?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  name?: String_comparison_exp | null;
  project?: projects_bool_exp | null;
  project_id?: uuid_comparison_exp | null;
  slug?: String_comparison_exp | null;
  snapshots?: template_snapshots_bool_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "templates"
 */
export interface templates_insert_input {
  created_at?: Hasura_timestamptz | null;
  created_by?: Hasura_uuid | null;
  id?: Hasura_uuid | null;
  name?: string | null;
  project?: projects_obj_rel_insert_input | null;
  project_id?: Hasura_uuid | null;
  slug?: string | null;
  snapshots?: template_snapshots_arr_rel_insert_input | null;
  updated_at?: Hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "templates"
 */
export interface templates_obj_rel_insert_input {
  data: templates_insert_input;
  on_conflict?: templates_on_conflict | null;
}

/**
 * on conflict condition type for table "templates"
 */
export interface templates_on_conflict {
  constraint: templates_constraint;
  update_columns: templates_update_column[];
  where?: templates_bool_exp | null;
}

/**
 * expression to compare columns of type timestamptz. All fields are combined with logical 'AND'.
 */
export interface timestamptz_comparison_exp {
  _eq?: Hasura_timestamptz | null;
  _gt?: Hasura_timestamptz | null;
  _gte?: Hasura_timestamptz | null;
  _in?: Hasura_timestamptz[] | null;
  _is_null?: boolean | null;
  _lt?: Hasura_timestamptz | null;
  _lte?: Hasura_timestamptz | null;
  _neq?: Hasura_timestamptz | null;
  _nin?: Hasura_timestamptz[] | null;
}

/**
 * Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'.
 */
export interface users_bool_exp {
  _and?: (users_bool_exp | null)[] | null;
  _not?: users_bool_exp | null;
  _or?: (users_bool_exp | null)[] | null;
  auth_user_id?: String_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  my_projects?: projects_bool_exp | null;
  primary_email?: String_comparison_exp | null;
  project_memberships?: project_members_bool_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "users"
 */
export interface users_insert_input {
  auth_user_id?: string | null;
  created_at?: Hasura_timestamptz | null;
  id?: Hasura_uuid | null;
  my_projects?: projects_arr_rel_insert_input | null;
  primary_email?: string | null;
  project_memberships?: project_members_arr_rel_insert_input | null;
  updated_at?: Hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "users"
 */
export interface users_obj_rel_insert_input {
  data: users_insert_input;
  on_conflict?: users_on_conflict | null;
}

/**
 * on conflict condition type for table "users"
 */
export interface users_on_conflict {
  constraint: users_constraint;
  update_columns: users_update_column[];
  where?: users_bool_exp | null;
}

/**
 * expression to compare columns of type uuid. All fields are combined with logical 'AND'.
 */
export interface uuid_comparison_exp {
  _eq?: Hasura_uuid | null;
  _gt?: Hasura_uuid | null;
  _gte?: Hasura_uuid | null;
  _in?: Hasura_uuid[] | null;
  _is_null?: boolean | null;
  _lt?: Hasura_uuid | null;
  _lte?: Hasura_uuid | null;
  _neq?: Hasura_uuid | null;
  _nin?: Hasura_uuid[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
