import { useQuery } from "@apollo/react-hooks";
import { Breadcrumb, Table } from "antd";
import { Column } from "components/common/Flex";
import TCard from "components/common/layout/TCard";
import { projectBreadcrumbItems } from "components/common/RouteBreadcrumbs";
import strings from "localisation/strings";
import React from "react";
import Loading from "screens/Loading";
import PlaceholderScreen from "screens/PlaceholderScreen";
import { AddDataVariableButton } from "./AddDataVariableButton";
import { DataTypeSelect, DataTypeSelectProps } from "./DataTypeSelect";
import { DataVariableName, DataVariableNameProps } from "./DataVariableName";
import QUERY_DATA_VARIABLES from "./queries/QUERY_DATA_VARIABLES";
import {
  QueryDataVariables,
  QueryDataVariablesVariables
} from "./queries/__generated__/QueryDataVariables";
import {
  RemoveDataVariableButton,
  RemoveDataVariableButtonProps
} from "./RemoveDataVariableButton";

const columns = [
  {
    dataIndex: "removeButton",
    render: (props: RemoveDataVariableButtonProps) => (
      <RemoveDataVariableButton {...props} />
    )
  },
  {
    title: strings("dataTypes.columns.name"),
    dataIndex: "name",
    render: (props: DataVariableNameProps) => <DataVariableName {...props} />
  },
  {
    title: strings("dataTypes.columns.type"),
    dataIndex: "type",
    render: (props: DataTypeSelectProps) => <DataTypeSelect {...props} />
  }
];

interface ColumnTypes {
  key: string;
  removeButton: RemoveDataVariableButtonProps;
  name: DataVariableNameProps;
  type: DataTypeSelectProps;
}

const DataTypes = ({ projectId }: { projectId: string }) => {
  const { data, loading } = useQuery<
    QueryDataVariables,
    QueryDataVariablesVariables
  >(QUERY_DATA_VARIABLES, {
    variables: { projectId }
  });

  if (loading) {
    return <Loading />;
  }

  if (data && data.project) {
    const { project } = data;
    return (
      <Column>
        <Breadcrumb>
          {projectBreadcrumbItems({
            projectSlug: project.slug,
            projectName: project.name
          })}
          <Breadcrumb.Item>{strings("dataTypes.breadCrumb")}</Breadcrumb.Item>
        </Breadcrumb>
        <TCard>
          <h1>{strings("dataTypes.title")}</h1>
          <Table
            pagination={false}
            columns={columns}
            dataSource={(project.dataVariables || []).map(
              (variable): ColumnTypes => {
                const componentProps = {
                  projectId,
                  variable
                };
                return {
                  key: variable.id,
                  removeButton: { projectId, variableId: variable.id },
                  name: componentProps,
                  type: componentProps
                };
              }
            )}
          />
          <AddDataVariableButton projectId={projectId} />
        </TCard>
      </Column>
    );
  }
  return <PlaceholderScreen />;
};

export default DataTypes;
