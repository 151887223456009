import { useMutation } from "@apollo/react-hooks";
import { message, Select } from "antd";
import strings from "localisation/strings";
import { keys } from "ramda";
import React, { useState } from "react";
import { data_variable_types_enum } from "__generated__/AppGlobalTypes";
import QUERY_UPDATE_VARIABLE from "./queries/QUERY_UPDATE_VARIABLE";
import { refetchDataVariables } from "./queries/refetch";
import { QueryDataVariables_project_dataVariables } from "./queries/__generated__/QueryDataVariables";
import {
  QueryUpdateVariable,
  QueryUpdateVariableVariables
} from "./queries/__generated__/QueryUpdateVariable";

const dataStrings = {
  [data_variable_types_enum.TEXT]: strings("dataTypes.types.TEXT"),
  [data_variable_types_enum.NUMBER]: strings("dataTypes.types.NUMBER"),
  [data_variable_types_enum.BOOLEAN]: strings("dataTypes.types.BOOLEAN")
};

export interface DataTypeSelectProps {
  projectId: string;
  variable: QueryDataVariables_project_dataVariables;
}

export const DataTypeSelect = ({
  projectId,
  variable
}: DataTypeSelectProps) => {
  const [loading, setLoading] = useState(false);
  const [updateVariable] = useMutation<
    QueryUpdateVariable,
    QueryUpdateVariableVariables
  >(QUERY_UPDATE_VARIABLE);

  const onUpdate = async (newType: data_variable_types_enum) => {
    setLoading(true);
    try {
      await updateVariable({
        variables: {
          name: variable.name,
          type: newType,
          variableId: variable.id
        },
        refetchQueries: [refetchDataVariables({ projectId })]
      });
    } catch (err) {
      message.error(strings("dataTypes.updateFailed"));
    }
    setLoading(false);
  };

  return (
    <Select
      defaultValue={variable.type}
      dropdownMatchSelectWidth={false}
      onSelect={onUpdate}
      loading={loading}
      value={variable.type}
    >
      {keys(data_variable_types_enum).map(key => (
        <Select.Option key={key} value={key}>
          {dataStrings[key]}
        </Select.Option>
      ))}
    </Select>
  );
};
