import { Button } from "antd";
import { Row } from "components/common/Flex";
import strings from "localisation/strings";
import React from "react";

export interface PopoverButtonsProps {
  onCancel: () => void;
  onConfirm: () => void;
}

export const PopoverButtons = ({
  onCancel,
  onConfirm
}: PopoverButtonsProps) => {
  return (
    <Row mainAxis="flex-end">
      <Button
        size="small"
        onMouseDown={ev => ev.preventDefault()}
        onClick={onCancel}
      >
        {strings("buttons.no")}
      </Button>
      <Row marginHorizontal="small" grow={0} />
      <Button
        size="small"
        onMouseDown={ev => ev.preventDefault()}
        onClick={onConfirm}
        type="primary"
      >
        {strings("buttons.yes")}
      </Button>
    </Row>
  );
};
