import { SpreadRow } from "components/common/Flex";
import { MarginedDivider } from "components/common/MarginedDivider";
import { SimpleText } from "components/common/SimpleText";
import React from "react";
import { EntityEditorState } from "state/template/EntityEditorState";
import EntityTypes from "templatio-evaluator/lib/main/evaluator/EntityTypes";
import { RightSidebar } from "../RightSidebar";
import BranchTools from "./branch/BranchTools";
import { DataTools } from "./data/DataTools";
import SynonymTools from "./synonym/SynonymTools";

interface SidebarToolsProps {
  templateId: string;
}

export const SidebarTools = ({ templateId }: SidebarToolsProps) => {
  const {
    editor: { type: editorType, id }
  } = EntityEditorState.useContainer();

  return (
    <RightSidebar collapsed={editorType === "TEMPLATE"}>
      <SpreadRow>
        <SimpleText as="h3" t="projectTemplate.rightSidebar.title" />
      </SpreadRow>
      <MarginedDivider margin="medium" />
      {editorType === EntityTypes.SYNONYM && (
        <SynonymTools templateId={templateId} entityId={id} />
      )}
      {editorType === EntityTypes.BRANCH && (
        <BranchTools templateId={templateId} entityId={id} />
      )}
      {editorType === EntityTypes.DATA && (
        <DataTools templateId={templateId} entityId={id} />
      )}
    </RightSidebar>
  );
};
