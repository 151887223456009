import { convertToRaw, EditorState, Modifier, SelectionState } from "draft-js";
import { createInitialState } from "./createInitialState";
import { EntitySelection } from "./selectionToNewContent";

export const applyEntitySelections = (
  editorState: EditorState,
  selections: EntitySelection[][]
) => {
  let contentState = editorState.getCurrentContent();
  const blocks = contentState.getBlocksAsArray();

  selections.map((block, blockIndex) =>
    block.forEach(({ data, rangeInBlock: { start, end } }) => {
      contentState = contentState.createEntity(data.type, "IMMUTABLE", data);
      const entityKey = contentState.getLastCreatedEntityKey();
      const selectionState = SelectionState.createEmpty(
        blocks[blockIndex].getKey()
      ).merge({
        anchorOffset: start,
        focusOffset: end
      }) as SelectionState;
      contentState = Modifier.applyEntity(
        contentState,
        selectionState,
        entityKey
      );
    })
  );
  return createInitialState(convertToRaw(contentState));
};
