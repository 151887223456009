import { Switch } from "antd";
import React from "react";
import { BranchFieldsState } from "state/template/BranchFieldsState";
import { useAddRemoveBranchField } from "state/template/expensive/useAddRemoveBranchField";

interface DefaultFieldSwitchProps {
  entityId: string;
}

export const DefaultFieldSwitch = ({ entityId }: DefaultFieldSwitchProps) => {
  const { getDefaultFieldId } = BranchFieldsState.useContainer();
  const defaultFieldId = getDefaultFieldId({ branchId: entityId });
  const { addBranchField, removeBranchField } = useAddRemoveBranchField();

  const toggleDefault = (setDefault: boolean) => {
    if (setDefault) {
      addBranchField({ branchId: entityId, fieldType: "DEFAULT_FIELD" });
    } else {
      removeBranchField({ fieldId: defaultFieldId || "" });
    }
  };

  return <Switch checked={!!defaultFieldId} onChange={toggleDefault} />;
};
