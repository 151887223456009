import { assoc, move, prop } from "ramda";
import { useState } from "react";
import {
  getBranchFields as getFields,
  isDefaultBranchField,
  isNotDefaultBranchField,
  Snapshot,
  StateInterface
} from "templatio-evaluator";
import BranchEquationTest from "templatio-evaluator/lib/main/evaluator/models/BranchEquationTest";
import BranchFieldInterface from "templatio-evaluator/lib/main/evaluator/models/BranchFieldInterface";
import { Equation } from "templatio-evaluator/lib/main/evaluator/models/EquationInterfaces";
import FieldInterface from "templatio-evaluator/lib/main/evaluator/models/FieldInterface";
import { createContainer } from "unstated-next";

const EMPTY_EQUATION: Equation = { type: "equation" };

const useBranchFieldsState = (
  initialState?: StateInterface<BranchFieldInterface>
) => {
  const [branchFields, setBranchFields] = useState(initialState || {});

  const getBranchField = ({ fieldId }: { fieldId: string }) =>
    branchFields[fieldId];

  const getBranchFields = ({
    branchId,
    includeDefault = true
  }: {
    branchId: string;
    includeDefault?: boolean;
  }) => {
    const fields = getFields({ branchId, branchFields });
    return includeDefault ? fields : fields.filter(isNotDefaultBranchField);
  };

  const getDefaultFieldId = ({
    branchId
  }: {
    branchId: string;
  }): string | undefined => {
    const defaultField = getFields({ branchId, branchFields }).filter(
      isDefaultBranchField
    )[0];
    return defaultField ? defaultField.localId : undefined;
  };

  const assocBranchField = (field: FieldInterface) => {
    const newField: BranchFieldInterface = {
      ...field,
      index: getBranchFields({ branchId: field.parentId }).length,
      equation: EMPTY_EQUATION,
      tests: [],
      fieldType: "REGULAR_FIELD"
    };
    setBranchFields(assoc(field.localId, newField));
    return newField;
  };

  const reindexFields = (fields: BranchFieldInterface[]) => {
    fields.filter(isNotDefaultBranchField).forEach((field, index) => {
      setBranchFields(
        assoc(field.localId, {
          ...field,
          index
        })
      );
    });
  };

  const moveBranchField = ({
    branchId,
    from,
    to
  }: {
    branchId: string;
    from: number;
    to: number;
  }) => {
    const movedFields = move(
      from,
      to,
      getBranchFields({ branchId, includeDefault: false })
    );
    reindexFields(movedFields);
  };

  const setBranchEquation = (fieldId: string, equation: Equation) => {
    const oldField = getBranchField({ fieldId });
    if (!oldField) {
      return;
    }
    const newField = { ...oldField, equation };
    setBranchFields(assoc(fieldId, newField));
    return newField;
  };

  const getBranchEquation = (fieldId: string) => {
    const field = getBranchField({ fieldId });
    return (field && prop("equation")(field)) || EMPTY_EQUATION;
  };

  const setBranchEquationTests = (
    fieldId: string,
    tests: BranchEquationTest[]
  ) => {
    const oldField = getBranchField({ fieldId });
    if (!oldField) {
      return;
    }
    const newField = { ...oldField, tests };
    setBranchFields(assoc(fieldId, newField));
    return newField;
  };

  const getBranchEquationTests = (fieldId: string) => {
    const field = getBranchField({ fieldId });
    return (field && (prop("tests")(field) as BranchEquationTest[])) || [];
  };

  const updateBranchField = (field: BranchFieldInterface) => {
    const newFields = assoc(field.localId, field, branchFields);
    setBranchFields(newFields);
    reindexFields(
      getFields({ branchId: field.parentId, branchFields: newFields })
    );
  };

  const addFromSnapshot = (snapshot: Snapshot) => {
    setBranchFields({ ...branchFields, ...snapshot.branchFields });
  };

  return {
    assocBranchField,
    branchFields,
    getBranchField,
    getBranchFields,
    moveBranchField,
    getBranchEquation,
    setBranchEquation,
    setBranchEquationTests,
    getBranchEquationTests,
    updateBranchField,
    addFromSnapshot,
    reindexFields,
    setBranchFields,
    getDefaultFieldId
  };
};

export const BranchFieldsState = createContainer(useBranchFieldsState);
