import "antd/dist/antd.less";
import TRouter from "navigation/TRouter";
import * as React from "react";
import AuthState from "state/AuthState";
import theme from "style/Theme";
import { ThemeProvider } from "style/themed";
import "./App.css";
import HasuraProvider from "navigation/HasuraProvider";

class App extends React.Component {
  public render() {
    return (
      <AuthState.Provider>
        <HasuraProvider>
          <ThemeProvider theme={theme}>
            <TRouter />
          </ThemeProvider>
        </HasuraProvider>
      </AuthState.Provider>
    );
  }
}

export default App;
