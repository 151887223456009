import { assoc } from "ramda";
import { useState } from "react";
import {
  getSynonymFields as getFields,
  Snapshot,
  StateInterface
} from "templatio-evaluator";
import SynonymFieldInterface from "templatio-evaluator/lib/main/evaluator/models/SynoymFieldInterface";
import { createContainer } from "unstated-next";

const useSynonymFieldsState = (
  initialState?: StateInterface<SynonymFieldInterface>
) => {
  const [synonymFields, setSynonymFields] = useState(initialState || {});

  const getSynonymField = ({ fieldId }: { fieldId: string }) =>
    synonymFields[fieldId];

  const getSynonymFields = ({ synonymId }: { synonymId: string }) => {
    return getFields({ synonymId, synonymFields });
  };

  const assocSynonymField = (field: SynonymFieldInterface) => {
    setSynonymFields(assoc(field.localId, field));
    return field;
  };

  const addFromSnapshot = (snapshot: Partial<Snapshot>) => {
    setSynonymFields({ ...synonymFields, ...snapshot.synonymFields });
  };

  return {
    assocSynonymField,
    synonymFields,
    getSynonymField,
    getSynonymFields,
    setSynonymFields,
    addFromSnapshot
  };
};

export const SynonymFieldsState = createContainer(useSynonymFieldsState);
