import { useMutation } from "@apollo/react-hooks";
import { Button, message } from "antd";
import refetchProjectData from "components/projectDataScreen/queries/refetchProjectData";
import strings from "localisation/strings";
import React, { useState } from "react";
import QUERY_REMOVE_DATA_VARIABLE from "./queries/QUERY_REMOVE_DATA_VARIABLE";
import { refetchDataVariables } from "./queries/refetch";
import {
  QueryRemoveDataVariable,
  QueryRemoveDataVariableVariables
} from "./queries/__generated__/QueryRemoveDataVariable";

export interface RemoveDataVariableButtonProps {
  projectId: string;
  variableId: string;
}

export const RemoveDataVariableButton = ({
  projectId,
  variableId
}: RemoveDataVariableButtonProps) => {
  const [loading, setLoading] = useState(false);
  const [removeVariable] = useMutation<
    QueryRemoveDataVariable,
    QueryRemoveDataVariableVariables
  >(QUERY_REMOVE_DATA_VARIABLE);

  const onRemove = async () => {
    setLoading(true);
    try {
      await removeVariable({
        variables: {
          variableId
        },
        refetchQueries: [
          refetchDataVariables({ projectId }),
          refetchProjectData({ projectId })
        ]
      });
    } catch (err) {
      message.error(strings("dataTypes.removeFailed"));
    }
    setLoading(false);
  };
  return <Button icon="delete" loading={loading} onClick={onRemove} />;
};
