import { useCallback } from "react";
import SlotFieldInterface from "templatio-evaluator/lib/main/evaluator/models/SlotField";
import { BranchFieldsState } from "../BranchFieldsState";
import { DraftStates } from "../DraftStates";

export const useSlottedBranchField = () => {
  const {
    updateBranchField,
    getBranchField
  } = BranchFieldsState.useContainer();
  const { createEmptyStates } = DraftStates.useContainer();

  const getSlotFields = ({ branchFieldId }: { branchFieldId: string }) => {
    const branchField = getBranchField({ fieldId: branchFieldId });
    let slotFields: SlotFieldInterface[] = [];
    if (branchField && branchField.slotFields) {
      slotFields = branchField.slotFields;
    }
    return slotFields;
  };

  const addSlottedField = useCallback(
    (field: SlotFieldInterface) => {
      const branchField = getBranchField({ fieldId: field.parentId });
      if (branchField) {
        const { slotFields = [] } = branchField;
        const newField: SlotFieldInterface = {
          ...field,
          index: slotFields.length
        };
        const newFields = [...slotFields, newField];
        createEmptyStates([newField.localId]);
        updateBranchField({
          ...branchField,
          slotFields: newFields
        });
      }
    },
    [updateBranchField, getBranchField, createEmptyStates]
  );

  return { getSlotFields, addSlottedField };
};
