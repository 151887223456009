import { Divider } from "antd";
import { DividerProps } from "antd/lib/divider";
import globalTheme from "style/Theme";
import styled from "style/themed";

interface MarginedDividerProps extends DividerProps {
  margin: keyof typeof globalTheme.margin;
}

export const MarginedDivider = styled(Divider)<MarginedDividerProps>`
  &&& {
    margin: ${({ theme, type, margin }) =>
      type === "vertical"
        ? `0 ${theme.margin[margin]}`
        : `${theme.margin[margin]} 0`};
  }
`;
