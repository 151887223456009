import React from "react";
import styled from "style/themed";
import TemplatioEditor from "./TemplatioEditor";

const EditorContainer = styled.div`
  background: ${({ theme }) => theme.color.background.primary};
  padding: ${({ theme }) => theme.margin.medium};
  border: 1px solid #ddd;
  flex: 1;
  .DraftEditor-root {
    height: 100%;
  }
  .DraftEditor-editorContainer {
    height: 100%;
  }
  .public-DraftEditor-content {
    height: 100%;
    overflow-y: scroll;
  }
`;

export interface TemplateEditorAreaProps {
  templateId: string;
}

const TemplateEditorArea = ({ templateId }: TemplateEditorAreaProps) => {
  return (
    <EditorContainer>
      <TemplatioEditor draftStateId={templateId} />
    </EditorContainer>
  );
};

export default TemplateEditorArea;
