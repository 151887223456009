import { Form, message } from "antd";
import { WrappedFormUtils } from "antd/lib/form/Form";
import { ApolloError } from "apollo-client";
import FormField from "components/forms/FormField";
import SlugInputItem from "components/forms/SlugInputItem";
import { useCheckSlug } from "components/forms/useCheckSlug";
import strings from "localisation/strings";
import React, { useEffect } from "react";

export interface ProjectFormFields {
  name: string;
  slug: string;
}

export interface CreateProjectFormProps {
  form: WrappedFormUtils<ProjectFormFields>;
  error: ApolloError | undefined;
}

const CreateProjectFormComponent = ({
  form,
  error
}: CreateProjectFormProps) => {
  const { checkSlug, sameSlugCount } = useCheckSlug({
    type: "projectSlug"
  });
  const { getFieldValue } = form;

  useEffect(() => {
    if (error) {
      message.error(strings("createProjectForm.createFailed"));
    }
  }, [error]);

  return (
    <Form layout="vertical">
      <FormField
        form={form}
        fieldKey="name"
        label={strings("createProjectForm.fields.name.label")}
        rules={[
          {
            required: true,
            message: strings("createProjectForm.fields.name.message")
          }
        ]}
      />
      <SlugInputItem
        checkSlug={checkSlug}
        sameSlugCount={sameSlugCount}
        slugFrom={getFieldValue("name")}
        formUtils={form}
        error={error}
      />
    </Form>
  );
};

const CreateProjectForm = Form.create<CreateProjectFormProps>()(
  CreateProjectFormComponent
);

export default CreateProjectForm;
