import { differenceWith, prop } from "ramda";
import { useCallback } from "react";
import SlotFieldInterface from "templatio-evaluator/lib/main/evaluator/models/SlotField";
import { BranchFieldsState } from "../BranchFieldsState";
import { DraftStates } from "../DraftStates";

const reindex = (
  field: SlotFieldInterface,
  index: number
): SlotFieldInterface => ({ ...field, index });

const sameLocalId = (field1: SlotFieldInterface, field2: SlotFieldInterface) =>
  field1.localId === field2.localId;
const localId = prop("localId");

export const useReplaceSlotFields = () => {
  const {
    updateBranchField,
    getBranchField
  } = BranchFieldsState.useContainer();
  const { createEmptyStates, removeDraftStates } = DraftStates.useContainer();

  const adjustDraftStates = useCallback(
    (oldFields: SlotFieldInterface[], newFields: SlotFieldInterface[]) => {
      const localIdsToRemove = differenceWith(
        sameLocalId,
        oldFields,
        newFields
      ).map(localId);
      removeDraftStates(localIdsToRemove);
      const localIdsToAdd = differenceWith(
        sameLocalId,
        newFields,
        oldFields
      ).map(localId);
      createEmptyStates(localIdsToAdd);
    },
    [createEmptyStates, removeDraftStates]
  );

  const replaceSlotFields = useCallback(
    (parentId: string, newFields: SlotFieldInterface[]) => {
      const branchField = getBranchField({ fieldId: parentId });
      if (branchField) {
        const slotFields = newFields.map(reindex);
        adjustDraftStates(branchField.slotFields || [], slotFields);
        updateBranchField({
          ...branchField,
          slotFields
        });
      }
    },
    [getBranchField, adjustDraftStates, updateBranchField]
  );

  return { replaceSlotFields };
};
