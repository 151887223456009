import gql from "graphql-tag";

export default gql`
  mutation UpdateTemplate($templateId: uuid!, $name: String!, $slug: String!) {
    update: update_templates(
      where: { id: { _eq: $templateId } }
      _set: { name: $name, slug: $slug }
    ) {
      affected_rows
    }
  }
`;
