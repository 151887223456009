import { message } from "antd";
import RaisedButton from "components/common/actions/RaisedButton";
import usePublications from "components/projectTemplateScreen/hooks/usePublications";
import React from "react";
import { toServerSnapshot, useSnapshot } from "state/template/useSnapshot";

interface PreviewButtonProps {
  projectId: string;
  templateId: string;
  projectSlug: string;
  templateSlug: string;
  publishingUrl?: string | null;
}

export default ({
  templateId,
  projectId,
  publishingUrl,
  projectSlug,
  templateSlug,
}: PreviewButtonProps) => {
  const { data, publish, isPublishing } = usePublications({ templateId });
  const { snapshot } = useSnapshot({ projectId, templateId });

  const loading =
    isPublishing || data?.publications[0]?.status === "PUBLISHING";

  const onPublish = async () => {
    if (!!publishingUrl) {
      try {
        await publish({
          variables: {
            templateId,
            publishingUrl: `${publishingUrl}/projects/${projectSlug}/templates/${templateSlug}/publish`,
            snapshot: toServerSnapshot(snapshot),
          },
        });
      } catch (err) {
        console.error(err);
        message.error("Failed to publish template");
      }
    }
  };

  return (
    <RaisedButton
      disabled={!publishingUrl || loading}
      loading={loading}
      onClick={onPublish}
      shape="round"
      icon="file-text"
    >
      {loading ? "Publishing" : "Publish"}
    </RaisedButton>
  );
};
