import { Button, Checkbox, Form, Icon, Input } from "antd";
import { NativeButtonProps } from "antd/lib/button/button";
import { WrappedFormUtils } from "antd/lib/form/Form";
import { AuthErrorTypes } from "authService/generated/graphql";
import { EmailPassword } from "authService/hooks/useEmailAuth";
import strings from "localisation/strings";
import { Paths } from "navigation/Paths";
import React, { FormEvent, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledForm = styled(Form)`
  max-width: 300px;
`;

const SpreadRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const B = (props: NativeButtonProps) => <Button {...props} />;
const LoginButton = styled(B)`
  width: 100%;
`;

export interface LoginFormProps {
  form: WrappedFormUtils;
  onLogin: (params: EmailPassword) => any;
  errors: AuthErrorTypes[];
  loading?: boolean;
}

const LoginFormComponent = ({
  form,
  onLogin,
  errors,
  loading,
}: LoginFormProps) => {
  useEffect(() => {
    const fields = errors.reduce((acc, type) => {
      if (type === AuthErrorTypes.InvalidEmail) {
        return {
          ...acc,
          email: {
            value: form.getFieldValue("email"),
            errors: [new Error(strings("login.noSuchUser"))],
          },
        };
      }
      if (type === AuthErrorTypes.InvalidPassword) {
        return {
          ...acc,
          password: {
            value: form.getFieldValue("password"),
            errors: [new Error(strings("login.invalidPassword"))],
          },
        };
      }
      return acc;
    }, {});
    form.setFields(fields);
    // eslint-disable-next-line
  }, [errors]);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    form.validateFields((err, { email, password, remember }) => {
      if (!err) {
        onLogin({ email, password, rememberUser: remember });
      }
    });
  };

  const { getFieldDecorator } = form;
  return (
    <StyledForm onSubmit={handleSubmit}>
      <Form.Item>
        {getFieldDecorator("email", {
          rules: [{ required: true, message: strings("login.usernameError") }],
        })(
          <Input
            prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder={strings("login.usernamePlaceholder")}
          />
        )}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator("password", {
          rules: [{ required: true, message: strings("login.passwordError") }],
        })(
          <Input
            prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
            type="password"
            placeholder={strings("login.passwordPlaceholder")}
          />
        )}
      </Form.Item>
      <Form.Item>
        <SpreadRow>
          {getFieldDecorator("remember", {
            valuePropName: "checked",
            initialValue: true,
          })(<Checkbox>{strings("login.rememberMe")}</Checkbox>)}
          <Link to={Paths.forgotPassword.path}>
            {strings("login.forgotPassword")}
          </Link>
        </SpreadRow>
        <LoginButton type="primary" htmlType="submit" loading={loading}>
          {strings("login.logIn")}
        </LoginButton>
        {strings("login.orRegister")}
        <Link to={Paths.register.path}>{strings("login.registerNow")}</Link>
      </Form.Item>
    </StyledForm>
  );
};

const LoginForm = Form.create<LoginFormProps>()(LoginFormComponent);

export default LoginForm;
