import { EditorState, Modifier, SelectionState } from "draft-js";
import EntityInterface from "templatio-evaluator/lib/main/evaluator/models/EntityInterface";

const commitText = (
  text: string,
  draftState: EditorState,
  entityData: EntityInterface
) => {
  const contentState = draftState.getCurrentContent();
  const blockMap = contentState.getBlockMap();
  let selection: SelectionState | undefined;
  let foundEntityKey: string | undefined;
  blockMap.forEach(contentBlock => {
    if (contentBlock) {
      contentBlock.findEntityRanges(
        character => {
          const entityKey = character.getEntity();
          if (entityKey !== null) {
            const data: EntityInterface = contentState
              .getEntity(entityKey)
              .getData();
            if (data.entityId === entityData.entityId) {
              foundEntityKey = entityKey;
              return true;
            }
          }
          return false;
        },
        (start, end) => {
          selection = SelectionState.createEmpty(contentBlock.getKey()).merge({
            anchorOffset: start,
            focusOffset: end
          }) as SelectionState;
        }
      );
    }
  });
  if (selection) {
    const contentStateWithNewEntity = foundEntityKey
      ? contentState.replaceEntityData(foundEntityKey, entityData)
      : contentState;
    const newContentState = Modifier.replaceText(
      contentStateWithNewEntity,
      selection,
      text,
      undefined,
      foundEntityKey
    );
    const newDraftState = EditorState.push(
      draftState,
      newContentState,
      "apply-entity"
    );
    return newDraftState;
  }
  return draftState;
};

export default commitText;
