import gql from "graphql-tag";

export default gql`
  mutation MutationPublishTemplate(
    $templateId: uuid!
    $snapshot: jsonb!
    $publishingUrl: String!
  ) {
    publications: insert_publications(
      objects: {
        template_id: $templateId
        snapshot_data: $snapshot
        publishing_webhook_url: $publishingUrl
      }
    ) {
      inserted: returning {
        id
      }
    }
  }
`;
