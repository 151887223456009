import { gatewayCient } from "api/clients";
import gatewayLogin from "appGateway/gatewayLogin";
import authServiceClient from "authService/authServiceClient";
import { getFirstErrorType } from "authService/errorHandling";
import { AuthErrorTypes } from "authService/generated/graphql";
import refreshToken from "authService/refreshToken";
import authServiceSignOut from "authService/signOut";
import { useEffect, useState } from "react";
import { createContainer } from "unstated-next";

export type AuthStatus = "loading" | "loggedIn" | "loggedOut" | "error";

export interface AuthState {
  status: AuthStatus;
  token?: string;
  userId?: string | null;
}

const useAuthState = () => {
  const [authState, setAuthState] = useState<AuthState>({
    status: "loading"
  });

  useEffect(() => {
    tryRefreshingToken();
    // eslint-disable-next-line
  }, []);

  const signOut = async () => {
    await authServiceSignOut();
    gatewayCient.clearStore();
    authServiceClient.clearStore();
    setAuthState({ status: "loggedOut" });
  };

  const loggedIn = ({
    token,
    userId
  }: {
    token: string;
    userId?: string | null;
  }) => {
    setAuthState({ status: "loggedIn", token, userId });
  };

  const onError = () => {
    setAuthState(current => ({ ...current, status: "error" }));
  };

  const tryRefreshingToken = async () => {
    try {
      const { token: refreshedToken } = await refreshToken();
      const { token, userId } = await gatewayLogin(refreshedToken);
      if (token) {
        loggedIn({ token, userId });
      } else {
        onError();
      }
    } catch (error) {
      const refreshErrorType = getFirstErrorType(error);
      if (refreshErrorType === AuthErrorTypes.InvalidRefreshToken) {
        signOut();
      } else {
        onError();
      }
    }
  };

  return {
    loggedIn,
    authState,
    signOut,
    tryRefreshingToken,
    onError
  };
};
export default createContainer(useAuthState);
