import { useExampleData } from "components/common/hooks/useExampleData";
import { createContainer } from "unstated-next";

const useExampleDataState = (initialState?: { projectId: string }) => {
  const exampleData = useExampleData(
    (initialState && initialState.projectId) || ""
  );

  return { exampleData: exampleData[0] || {} };
};

export const ExampleDataState = createContainer(useExampleDataState);
