import { useCommitEntityText } from "components/projectTemplateScreen/editors/common/useCommitEntityText";
import { useDataVariable } from "components/projectTemplateScreen/editors/data/useDataVariable";
import { ContentState } from "draft-js";
import React, { ReactNode } from "react";
import { EntityEditorState } from "state/template/EntityEditorState";
import { ExampleDataState } from "state/template/ExampleDataState";
import { capitalize } from "templatio-evaluator/lib/main/evaluator/models/Capitalization";
import DataEntityInterface from "templatio-evaluator/lib/main/evaluator/models/DataEntityInterface";
import { EntityText } from "./EntityText";

interface DataProps {
  children: ReactNode;
  contentState: ContentState;
  entityKey: string;
  decoratedText: string;
  offsetKey: string;
}

const DataRenderer = ({
  offsetKey,
  children,
  entityKey,
  contentState,
  decoratedText
}: DataProps) => {
  const {
    updateEditor,
    isEntityBeingEdited
  } = EntityEditorState.useContainer();
  const { commitEntityText } = useCommitEntityText();
  const { getVariableName } = useDataVariable();

  const dataEntity: DataEntityInterface = contentState
    .getEntity(entityKey)
    .getData();
  const { exampleData } = ExampleDataState.useContainer();
  const exampleText = exampleData[dataEntity.variableId];
  const variableName = getVariableName(dataEntity.variableId);
  if (exampleText) {
    const text = capitalize(dataEntity.capitalization)(`${exampleText}`);
    if (text !== decoratedText) {
      commitEntityText({
        entity: dataEntity,
        text
      });
    }
  } else if (variableName) {
    const text = variableName;
    if (text !== decoratedText) {
      commitEntityText({
        entity: dataEntity,
        text
      });
    }
  }
  return (
    <EntityText
      missing={!variableName}
      entityType="data"
      onMouseDown={(ev: any) => ev.preventDefault()}
      onClick={() => updateEditor({ id: dataEntity.entityId })}
      data-offset-key={offsetKey}
      isBeingEdited={isEntityBeingEdited(dataEntity.entityId)}
    >
      {children}
    </EntityText>
  );
};

export default DataRenderer;
