import gql from "graphql-tag";

export default gql`
  mutation CreateProject($name: String!, $slug: String!) {
    created: insert_projects(objects: [{ name: $name, slug: $slug }]) {
      project: returning {
        name
        id
      }
    }
  }
`;
