import { flatten, slice } from "ramda";
import React, { useMemo } from "react";
import { useAddRemoveSynonymFields } from "state/template/expensive/useAddRemoveSynonymField";
import { SynonymFieldsState } from "state/template/SynonymFieldsState";
import SynonymField from "./SynonymField";
import HelpLabel from "components/common/texts/HelpLabel";

export interface SynonymFieldsProps {
  synonymId: string;
}

const SynonymFields = ({ synonymId }: SynonymFieldsProps) => {
  const { removeSynonymField, updateFields } = useAddRemoveSynonymFields();
  const { getSynonymFields } = SynonymFieldsState.useContainer();
  return useMemo(() => {
    const fields = getSynonymFields({ synonymId });
    return (
      <>
        <HelpLabel t="synonymFields.label" helpT="synonymFields.help" />
        {fields.map(({ localId }, index) => {
          const onRemove = () => removeSynonymField(localId);
          const onCSVPasted = (csv: string[][]) => {
            const flatCsv = flatten<string>(csv);
            const fieldsToUpdate = slice(
              index,
              Math.min(fields.length, index + flatCsv.length),
              fields
            );
            updateFields(synonymId, fieldsToUpdate, flatCsv);
          };
          return (
            <SynonymField
              key={localId}
              fieldId={localId}
              onRemoveField={onRemove}
              onCSVPasted={onCSVPasted}
            />
          );
        })}
      </>
    );
  }, [removeSynonymField, getSynonymFields, synonymId, updateFields]);
};

export default SynonymFields;
