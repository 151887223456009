import { AutoComplete } from "antd";
import { QueryProjectDataVariables_project_dataVariables as ProjectDataVariables } from "components/projectTemplateScreen/queries/__generated__/QueryProjectDataVariables";
import React from "react";
import { EquationVariable } from "templatio-evaluator/lib/main/evaluator/models/EquationInterfaces";
import { Operator } from "templatio-evaluator/lib/main/evaluator/models/OperatorInterfaces";

export const renderVariablesGroup = (variables: ProjectDataVariables[]) => (
  <AutoComplete.OptGroup
    key="dataVariables"
    label={<span>Data variables</span>}
  >
    {variables.map(variable => {
      const equationVariable: EquationVariable = {
        type: "equationVariable",
        variableId: variable.id
      };
      return (
        <AutoComplete.Option
          key={variable.id}
          value={JSON.stringify(equationVariable)}
        >
          {variable.name}
        </AutoComplete.Option>
      );
    })}
  </AutoComplete.OptGroup>
);

export const renderOperatorsGroup = (operators: Operator[], label: string) => (
  <AutoComplete.OptGroup key={label} label={<span>{label}</span>}>
    {operators.map(op => (
      <AutoComplete.Option key={op.operator} value={JSON.stringify(op)}>
        {op.operator}
      </AutoComplete.Option>
    ))}
  </AutoComplete.OptGroup>
);
