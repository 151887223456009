import { Button, Result } from "antd";
import AppContainer from "components/common/layout/AppContainer";
import strings from "localisation/strings";
import React from "react";

const AuthErrorScreen = () => (
  <AppContainer>
    <Result
      status="403"
      title={strings("authErrorScreen.title")}
      extra={
        <Button onClick={() => window.location.reload()}>
          {strings("authErrorScreen.reloadButton")}
        </Button>
      }
    />
  </AppContainer>
);

export default AuthErrorScreen;
