import { useMutation } from "@apollo/react-hooks";
import { Button, message } from "antd";
import { Row } from "components/common/Flex";
import strings from "localisation/strings";
import { fromPairs, KeyValuePair, prepend } from "ramda";
import React, { useState } from "react";
import ExampleDataRow from "./ExampleDataRow";
import QUERY_UPDATE_EXAMPLTE_DATA from "./queries/QUERY_UPDATE_EXAMPLTE_DATA";
import refetchProjectData from "./queries/refetchProjectData";
import {
  QueryUpdateExampleData,
  QueryUpdateExampleDataVariables
} from "./queries/__generated__/QueryUpdateExampleData";

interface AddExampleDataButtonProps {
  projectId: string;
  rows: ExampleDataRow[];
  variableIds: string[];
}

export const AddExampleDataButton = ({
  projectId,
  variableIds,
  rows
}: AddExampleDataButtonProps) => {
  const [loading, setLoading] = useState(false);
  const [updateExampleData] = useMutation<
    QueryUpdateExampleData,
    QueryUpdateExampleDataVariables
  >(QUERY_UPDATE_EXAMPLTE_DATA);

  const onAdd = async () => {
    setLoading(true);
    try {
      const newRow = fromPairs(
        variableIds.map(id => [id, ""] as KeyValuePair<string, string>)
      );
      await updateExampleData({
        variables: {
          projectId,
          rows: prepend(newRow, rows)
        },
        refetchQueries: [refetchProjectData({ projectId })]
      });
    } catch (err) {
      message.error(strings("projectData.addFailed"));
    }
    setLoading(false);
  };
  return (
    <Row grow={0} marginVertical="medium">
      <Button type="primary" loading={loading} onClick={onAdd}>
        {strings("projectData.addExampleData")}
      </Button>
    </Row>
  );
};
