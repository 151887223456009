import { EditorState } from "draft-js";
import { assoc, reverse } from "ramda";
import { DraftStates } from "state/template/DraftStates";
import { useGetEntity } from "state/template/useGetEntity";
import commitText from "state/templateStateHelpers/commitText";
import { StateInterface } from "templatio-evaluator";
import { EntityEditorState } from "../EntityEditorState";
import { useGetField } from "../useGetField";

export const useChangeEntityTreeText = ({ fieldId }: { fieldId: string }) => {
  const { getDraftState, addFromSnapshot } = DraftStates.useContainer();
  const { getEntity } = useGetEntity();
  const { getEntityTree, templateId } = EntityEditorState.useContainer();
  const { getField } = useGetField();

  const changeText = (editorState: EditorState) => {
    const rootField = getField(fieldId);
    const entityId = rootField ? rootField.parentId : "";
    // Child to parent
    const fieldTree = reverse(getEntityTree(entityId));
    let entity = getEntity(entityId);
    let text = editorState.getCurrentContent().getPlainText();
    let snap = [...fieldTree].reduce(
      (acc, field) => {
        const draftState = getDraftState(field.localId);
        if (draftState && entity && !entity.name) {
          const stateWithText = commitText(
            text || "(Placeholder)",
            draftState,
            entity
          );
          entity = getEntity(field.parentId);
          text = stateWithText.getCurrentContent().getPlainText();
          return assoc(field.localId, stateWithText, acc);
        }
        return acc;
      },
      {} as StateInterface<EditorState>
    );
    const templateEditorState = getDraftState(templateId);
    if (templateEditorState && entity && !entity.name) {
      snap = assoc(
        templateId,
        commitText(text || "(Placeholder)", templateEditorState, entity),
        snap
      );
    }
    addFromSnapshot({ draftStates: snap });
  };

  return { changeText };
};
