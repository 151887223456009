import gql from "graphql-tag";

export default gql`
  mutation AddTemplate($projectId: uuid!, $name: String!, $slug: String!) {
    addTemplate: insert_templates(
      objects: { project_id: $projectId, name: $name, slug: $slug }
    ) {
      affected_rows
    }
  }
`;
