import strings from "localisation/strings";
import TranslationPaths from "localisation/TranslationPaths";
import React, { ReactNode } from "react";
import globalTheme, { ThemeInterface } from "style/Theme";
import styled from "style/themed";
import { StyledComponentProps } from "styled-components";

export interface SimpleTextProps {
  format?: (x: string) => string;
  children?: ReactNode;
  padding?: keyof typeof globalTheme.margin;
  margin?: keyof typeof globalTheme.margin | number;
  marginVertical?: keyof typeof globalTheme.margin;
  marginHorizontal?: keyof typeof globalTheme.margin;
  color?: keyof typeof globalTheme.color.foreground;
  fontSize?: keyof typeof globalTheme.font.size;
  bold?: boolean;
  block?: boolean;
  t?: TranslationPaths;
}

export const StyledSpan = styled.span<SimpleTextProps>`
  ${({ block }) => (block ? "display: block;" : "")}
  ${({ padding, theme }) =>
    padding ? `padding: ${theme.margin[padding]};` : ""}
  ${({ margin, theme }) => {
    if (typeof margin === "string") {
      return `margin: ${theme.margin[margin]};`;
    }
    if (typeof margin === "number") {
      return `margin: ${margin}px;`;
    }
  }}
  ${({ marginHorizontal, theme }) =>
    marginHorizontal
      ? `margin-left: ${theme.margin[marginHorizontal]};
      margin-right: ${theme.margin[marginHorizontal]};`
      : ""}
  ${({ marginVertical, theme }) =>
    marginVertical
      ? `margin-top: ${theme.margin[marginVertical]}; margin-bottom: ${theme.margin[marginVertical]};`
      : ""}
  color: ${({ theme, color }) => theme.color.foreground[color || "primary"]};
  font-size: ${({ theme, fontSize }) => theme.font.size[fontSize || "medium"]};
  ${({ bold }) => (bold ? "font-weight: bold;" : "")}
`;

export const SimpleText = ({
  t,
  children,
  format = x => x,
  ...props
}: StyledComponentProps<"span", ThemeInterface, SimpleTextProps, any> &
  SimpleTextProps) => (
  <StyledSpan {...props}>{t ? format(strings(t)) : children}</StyledSpan>
);
