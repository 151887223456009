import { assoc, Omit } from "ramda";
import { useState } from "react";
import { Snapshot, StateInterface } from "templatio-evaluator";
import EntityTypes from "templatio-evaluator/lib/main/evaluator/EntityTypes";
import BranchEntityInterface from "templatio-evaluator/lib/main/evaluator/models/BranchEntityInterface";
import { createContainer } from "unstated-next";
import uuid from "uuid/v4";

const makeBranch = (data: Omit<BranchEntityInterface, "entityId" | "type">) => {
  const branch: BranchEntityInterface = {
    ...data,
    entityId: uuid(),
    type: EntityTypes.BRANCH
  };
  return branch;
};

const useBranchesState = (
  initialState?: StateInterface<BranchEntityInterface>
) => {
  const [branches, setBranches] = useState(initialState || {});

  const getBranchEntity = ({ branchId }: { branchId: string }) =>
    branches[branchId];

  const addBranch = (
    data: Omit<BranchEntityInterface, "entityId" | "type">
  ) => {
    const branch = makeBranch(data);
    setBranches(assoc(branch.entityId, branch));
    return branch;
  };

  const updateBranch = (newData: BranchEntityInterface) => {
    setBranches(assoc(newData.entityId, newData));
    return newData;
  };

  const addFromSnapshot = (snapshot: Snapshot) => {
    setBranches({ ...branches, ...snapshot.branchEntities });
  };

  return {
    branches,
    getBranchEntity,
    addBranch,
    updateBranch,
    addFromSnapshot
  };
};

export const BranchesState = createContainer(useBranchesState);
