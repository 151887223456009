import { Button, Drawer, Pagination } from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import { DrawerProps } from "antd/lib/drawer";
import { Column, Row } from "components/common/Flex";
import { useExampleData } from "components/common/hooks/useExampleData";
// import { evaluateSnapshot } from "evaluator/snapshotReducers/evaluateSnapshot2";
import React, { useState } from "react";
import { toServerSnapshot, useSnapshot } from "state/template/useSnapshot";
import styled from "style/themed";
import { evaluateSnapshot } from "templatio-evaluator";
import { ExampleDataPreview } from "./ExampleDataPreview";

const SnapshotText = styled.pre`
  padding: ${({ theme }) => theme.margin.medium};
  border: 1px solid ${({ theme }) => theme.border.color.primary};
  border-radius: ${({ theme }) => theme.border.radius.small};
  white-space: pre-wrap;
`;

const HTMLPreview = styled.iframe`
  height: 600px;
  border: 1px solid ${({ theme }) => theme.border.color.primary};
`;

const TypeButtonGroup = styled(ButtonGroup)`
  margin-bottom: ${({ theme }) => theme.margin.medium};
`;

interface SnapshotPreviewDrawerProps extends DrawerProps {
  projectId: string;
  templateId: string;
}

type PreviewType = "text" | "html";

export const SnapshotPreviewDrawer = ({
  projectId,
  templateId,
  ...drawerProps
}: SnapshotPreviewDrawerProps) => {
  const { snapshot } = useSnapshot({ projectId, templateId });
  const rows = useExampleData(projectId);
  const [rowIndex, setRowIndex] = useState(0);
  const [previewType, setPreviewType] = useState<PreviewType>("text");

  const row = rows[rowIndex] || {};

  const text = evaluateSnapshot({
    snapshot: toServerSnapshot(snapshot),
    data: row
  });

  return (
    <Drawer width={600} bodyStyle={{ padding: 0 }} {...drawerProps}>
      <Column margin="small">
        <ExampleDataPreview data={row} />
        <Row marginVertical="medium">
          <Pagination
            pageSize={1}
            current={rowIndex + 1}
            total={rows.length}
            onChange={page => setRowIndex(page - 1)}
          />
        </Row>
        <TypeButtonGroup>
          <Button
            type="primary"
            icon="font-size"
            disabled={previewType === "text"}
            onClick={() => setPreviewType("text")}
          />
          <Button
            type="primary"
            icon="html5"
            disabled={previewType === "html"}
            onClick={() => setPreviewType("html")}
          />
        </TypeButtonGroup>
        {previewType === "html" && (
          <HTMLPreview srcDoc={text} title="Preview iframe" />
        )}
        {previewType === "text" && <SnapshotText>{text}</SnapshotText>}
      </Column>
    </Drawer>
  );
};
