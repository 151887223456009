import gql from "graphql-tag";

export default gql`
  query QueryProjectDataVariables($projectId: uuid!) {
    project: projects_by_pk(id: $projectId) {
      id
      dataVariables: data_variables {
        id
        name
        type
      }
    }
  }
`;
