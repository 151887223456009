import TInput from "components/common/inputs/TInput";
import HelpLabel from "components/common/texts/HelpLabel";
import React, { ChangeEvent } from "react";
import { BranchesState } from "state/template/BranchesState";
import { useCommitEntityText } from "../common/useCommitEntityText";

export interface BranchNameInputProps {
  branchId: string;
}

export const BranchNameInput = ({ branchId }: BranchNameInputProps) => {
  const { getBranchEntity, updateBranch } = BranchesState.useContainer();
  const { commitEntityText } = useCommitEntityText();

  const branch = getBranchEntity({ branchId });

  if (!branch) {
    return null;
  }

  const changeBranchName = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    const newBranch = { ...branch, name };
    commitEntityText({ entity: newBranch, text: name });
    updateBranch(newBranch);
  };

  return (
    <>
      <HelpLabel t="branchNameInput.label" helpT="branchNameInput.help" />
      <TInput
        value={branch.name}
        onChange={changeBranchName}
        placeholderT="branchNameInput.label"
      />
    </>
  );
};
