import { useQuery } from "@apollo/react-hooks";
import { EntityEditorState } from "state/template/EntityEditorState";
import QUERY_PROJECT_DATA_VARIABLES from "../queries/QUERY_PROJECT_DATA_VARIABLES";
import {
  QueryProjectDataVariables,
  QueryProjectDataVariablesVariables
} from "../queries/__generated__/QueryProjectDataVariables";

const useProjectData = () => {
  const { projectId } = EntityEditorState.useContainer();
  const { data } = useQuery<
    QueryProjectDataVariables,
    QueryProjectDataVariablesVariables
  >(QUERY_PROJECT_DATA_VARIABLES, {
    variables: { projectId }
  });

  if (data && data.project) {
    return data.project.dataVariables;
  }
};

export default useProjectData;
