import { Column } from "components/common/Flex";
import { MarginedDivider } from "components/common/MarginedDivider";
import strings from "localisation/strings";
import React from "react";
import ToolsDoneButton from "../common/ToolsDoneButton";
import { ToolsTitle } from "../common/ToolsTitle";
import AddSynonymField from "./AddSynonymField";
import SynonymFields from "./SynonymFields";
import { SynonymNameInput } from "./SynonymNameInput";
import TreeViewEditor from "./TreeViewEditor";

export interface SynonymToolsProps {
  templateId: string;
  entityId: string;
}

const SynonymTools = ({ templateId, entityId }: SynonymToolsProps) => {
  return (
    <Column>
      <ToolsDoneButton />
      <TreeViewEditor entityId={entityId} />
      <ToolsTitle>{strings("projectTemplate.synonymTools.title")}</ToolsTitle>
      <SynonymNameInput synonymId={entityId} />
      <MarginedDivider margin="large" />
      <SynonymFields synonymId={entityId} />
      <AddSynonymField entityId={entityId} />
    </Column>
  );
};

export default SynonymTools;
