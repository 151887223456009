import { ContentState } from "draft-js";
import React, { ReactNode } from "react";
import { EntityEditorState } from "state/template/EntityEditorState";
import BranchEntityInterface from "templatio-evaluator/lib/main/evaluator/models/BranchEntityInterface";
import { EntityText } from "./EntityText";

interface BranchProps {
  children: ReactNode;
  contentState: ContentState;
  entityKey: string;
  decoratedText: string;
  offsetKey: string;
}

const BranchRenderer = ({
  contentState,
  entityKey,
  children,
  decoratedText,
  ...other
}: BranchProps) => {
  const {
    updateEditor,
    isEntityBeingEdited
  } = EntityEditorState.useContainer();

  const { entityId }: BranchEntityInterface = contentState
    .getEntity(entityKey)
    .getData();

  return (
    <EntityText
      entityType="branch"
      onMouseDown={(ev: any) => ev.preventDefault()}
      onClick={() => updateEditor({ id: entityId })}
      data-offset-key={other.offsetKey}
      isBeingEdited={isEntityBeingEdited(entityId)}
    >
      {children}
    </EntityText>
  );
};

export default BranchRenderer;
