import gql from "graphql-tag";

export default gql`
  mutation QueryEmailLogin(
    $email: String!
    $password: String!
    $rememberUser: Boolean
  ) {
    login: loginEmailPassword(
      email: $email
      password: $password
      rememberUser: $rememberUser
    ) {
      token
    }
  }
`;
