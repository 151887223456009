import { Popover } from "antd";
import { PopoverButtons } from "components/common/actions/PopoverButtons";
import { EditableText } from "components/common/EditableText";
import csv from "csvtojson";
import strings from "localisation/strings";
import {
  assoc,
  findIndex,
  fromPairs,
  KeyValuePair,
  mergeDeepRight
} from "ramda";
import React, { memo, useState } from "react";
import { useReplaceSlotFields } from "state/template/expensive/useReplaceSlotFields";
import { useUpdateSlotField } from "state/template/slotFields/useUpdateSlotField";
import styled from "style/themed";
import SlotFieldInterface from "templatio-evaluator/lib/main/evaluator/models/SlotField";
import uuid from "uuid/v4";

const isKeyValue = (
  pair?: KeyValuePair<string, string>
): pair is KeyValuePair<string, string> => !!pair;

const Container = styled.div`
  max-width: 200px;
`;

export interface SlotFieldsCellProps {
  rowIndex: number;
  fields: SlotFieldInterface[];
  slotId: string;
  columnIds: string[];
}

const InnerCell = memo(
  ({
    rowIndex,
    fields,
    slotId,
    columnIds,
    replaceSlotFields
  }: SlotFieldsCellProps & {
    replaceSlotFields: (parentId: string, fields: SlotFieldInterface[]) => void;
  }) => {
    const field = fields[rowIndex];
    const { slotValues } = field;
    const value = slotValues[slotId];
    const { updateSlotField } = useUpdateSlotField();
    const [pastedText, setPastedText] = useState("");
    const [editing, setEditing] = useState<boolean | undefined>(undefined);

    const onTextChanged = (text: string) => {
      const newValues = assoc(slotId, text, slotValues);
      updateSlotField({ ...field, slotValues: newValues });
    };

    const onPaste = async (text: string) => {
      const pasted: string[][] = await csv({
        delimiter: [",", ";", "\t"],
        noheader: true,
        output: "csv"
      }).fromString(text);
      const columnIndex = findIndex(id => id === slotId, columnIds);
      const newFields = [...fields];
      pasted.forEach((row, iRow) => {
        const oldField: SlotFieldInterface = fields[iRow + rowIndex] || {
          index: -1,
          parentId: field.parentId,
          localId: uuid(),
          slotValues: {}
        };
        const values = fromPairs(
          row
            .map((col, iCol) => {
              const colId = columnIds[iCol + columnIndex];
              if (colId) {
                return [colId, col] as KeyValuePair<string, string>;
              }
              return undefined;
            })
            .filter(isKeyValue)
        );
        newFields[iRow + rowIndex] = {
          ...oldField,
          slotValues: mergeDeepRight(oldField.slotValues, values)
        };
      });
      replaceSlotFields(field.parentId, newFields);
    };

    const onConfirm = () => {
      onPaste(pastedText);
      setPastedText("");
      setEditing(false);
    };

    const onChange = (text: string) => {
      if (pastedText && pastedText !== text) {
        setPastedText("");
      }
    };

    const onCancel = () => {
      setPastedText("");
      setEditing(undefined);
    };

    return (
      <Container>
        <Popover
          title={strings(
            "projectTemplate.branchTools.slotFieldsTable.pasteCSVTitle"
          )}
          visible={!!pastedText}
          content={<PopoverButtons onCancel={onCancel} onConfirm={onConfirm} />}
        >
          <EditableText
            onPastedText={setPastedText}
            type="textArea"
            onTextChanged={onTextChanged}
            onCancel={onCancel}
            onChange={onChange}
            editing={editing}
          >
            {value}
          </EditableText>
        </Popover>
      </Container>
    );
  }
);

export const SlotFieldsCell = (props: SlotFieldsCellProps) => {
  const { replaceSlotFields } = useReplaceSlotFields();
  return <InnerCell {...props} replaceSlotFields={replaceSlotFields} />;
};
