export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any,
};

export enum AuthErrorTypes {
  UnknownError = 'UNKNOWN_ERROR',
  AppIdMissing = 'APP_ID_MISSING',
  NoSuchUser = 'NO_SUCH_USER',
  InvalidAppId = 'INVALID_APP_ID',
  InvalidEmail = 'INVALID_EMAIL',
  InvalidPassword = 'INVALID_PASSWORD',
  DuplicateEmail = 'DUPLICATE_EMAIL',
  InvalidRefreshToken = 'INVALID_REFRESH_TOKEN',
  InvalidEmailTemplateId = 'INVALID_EMAIL_TEMPLATE_ID',
  EmailSendingFailed = 'EMAIL_SENDING_FAILED',
  EmailVerificationRequired = 'EMAIL_VERIFICATION_REQUIRED',
  EmailNotRegistered = 'EMAIL_NOT_REGISTERED',
  InvalidVerificationToken = 'INVALID_VERIFICATION_TOKEN',
  ServiceAccountTokenRequired = 'SERVICE_ACCOUNT_TOKEN_REQUIRED',
  ServiceAccountNotFound = 'SERVICE_ACCOUNT_NOT_FOUND',
  UpdatingCustomClaimsFailed = 'UPDATING_CUSTOM_CLAIMS_FAILED'
}

export type AuthPayload = {
   __typename?: 'AuthPayload',
  token: Scalars['String'],
};


export type Mutation = {
   __typename?: 'Mutation',
  signupEmailPassword: AuthPayload,
  loginEmailPassword: AuthPayload,
  changePassword: Scalars['Boolean'],
  authenticateWithOauthProvider: Scalars['String'],
  refreshAuthToken: AuthPayload,
  logout: Scalars['Boolean'],
  verifyEmail: AuthPayload,
  resendEmailVerification: Scalars['Boolean'],
  sendEmailByTemplateName: Scalars['Boolean'],
  setCustomClaims: Scalars['Boolean'],
};


export type MutationSignupEmailPasswordArgs = {
  email: Scalars['String'],
  password: Scalars['String']
};


export type MutationLoginEmailPasswordArgs = {
  email: Scalars['String'],
  password: Scalars['String'],
  rememberUser?: Maybe<Scalars['Boolean']>
};


export type MutationChangePasswordArgs = {
  email: Scalars['String'],
  oldPassword: Scalars['String'],
  newPassword: Scalars['String']
};


export type MutationAuthenticateWithOauthProviderArgs = {
  providerType: Scalars['String']
};


export type MutationVerifyEmailArgs = {
  verificationToken: Scalars['String']
};


export type MutationResendEmailVerificationArgs = {
  email: Scalars['String']
};


export type MutationSendEmailByTemplateNameArgs = {
  emailTo: Scalars['String'],
  templateName: Scalars['String'],
  emailContext: Scalars['JSONObject']
};


export type MutationSetCustomClaimsArgs = {
  userId: Scalars['String'],
  customClaims: Scalars['JSONObject']
};

export type Query = {
   __typename?: 'Query',
  hello?: Maybe<Scalars['String']>,
  authorizeServiceAccount: AuthPayload,
  userEmails: Array<Scalars['String']>,
};


export type QueryAuthorizeServiceAccountArgs = {
  clientId: Scalars['String'],
  clientSecret: Scalars['String']
};


export type QueryUserEmailsArgs = {
  userId: Scalars['String'],
  verifiedOnly: Scalars['Boolean']
};
