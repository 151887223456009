import { Spin } from "antd";
import React from "react";
import styled from "style/themed";

const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const Loading = () => (
  <Container>
    <Spin />
  </Container>
);

export default Loading;
