import { Row } from "components/common/Flex";
import { EditorInputContainer } from "components/common/inputs/EditorInputContainer";
import { SimpleText } from "components/common/SimpleText";
import HelpLabel from "components/common/texts/HelpLabel";
import TemplatioEditor from "components/projectTemplateScreen/TemplatioEditor";
import React from "react";
import { BranchFieldsState } from "state/template/BranchFieldsState";
import styled from "style/themed";
import { DefaultFieldSwitch } from "./DefaultFieldSwitch";

const FieldArea = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.color.background.tertiary};
  background-color: ${({ theme }) => theme.color.background.tertiary};
`;

const EditorContainer = styled(EditorInputContainer)`
  border-radius: 0 0 4px 4px;
  border: 1px solid ${({ theme }) => theme.color.background.primary};
`;

export interface DefaultBranchFieldProps {
  entityId: string;
}

const DefaultBranchField = ({ entityId }: DefaultBranchFieldProps) => {
  const { getDefaultFieldId } = BranchFieldsState.useContainer();
  const fieldId = getDefaultFieldId({ branchId: entityId });

  return (
    <>
      <HelpLabel t="defaultBranchField.label" helpT="defaultBranchField.help" />
      <FieldArea>
        <Row margin="medium">
          <DefaultFieldSwitch entityId={entityId} />
          <SimpleText
            t={
              fieldId
                ? "defaultBranchField.enabled"
                : "defaultBranchField.disabled"
            }
            marginHorizontal="medium"
          />
        </Row>
        {!!fieldId && (
          <EditorContainer>
            <TemplatioEditor draftStateId={fieldId} />
          </EditorContainer>
        )}
      </FieldArea>
    </>
  );
};

export default DefaultBranchField;
