import useTemplateBySlug from "components/common/hooks/useTemplateBySlug";
import LoadingScreen from "components/common/layout/LoadingScreen";
import ProjectTemplate, {
  ProjectTemplateProps,
} from "components/projectTemplateScreen/ProjectTemplate";
import {
  PopperReferenceContext,
  VirtualReference,
} from "components/projectTemplateScreen/VirtualReference";
import { WithProjectSlug, WithTemplateSlug } from "navigation/Paths";
import React, { useContext } from "react";
import { RouteComponentProps } from "react-router";
import styled from "style/themed";
import AppScreen from "./AppScreen";
import Loading from "./Loading";

const TemplateScreen = styled(AppScreen)`
  padding: 0;
  flex-direction: row;
`;

type ProjectTemplateScreenProps = WithProjectSlug & WithTemplateSlug;

const PopperReferenceProvider = (props: ProjectTemplateProps) => {
  const popperReference = useContext(PopperReferenceContext);
  return (
    <TemplateScreen onScroll={popperReference.update}>
      <React.Suspense fallback={<Loading />}>
        <ProjectTemplate {...props} />
      </React.Suspense>
    </TemplateScreen>
  );
};

const ProjectTemplateScreen = ({
  match,
}: RouteComponentProps<ProjectTemplateScreenProps>) => {
  const { projectSlug, templateSlug } = match.params;
  const { template, loading } = useTemplateBySlug({
    projectSlug,
    templateSlug,
  });

  if (loading) {
    return <LoadingScreen />;
  }

  if (template) {
    return (
      <PopperReferenceContext.Provider value={new VirtualReference()}>
        <PopperReferenceProvider
          projectId={template.project.id}
          templateId={template.id}
          projectSlug={projectSlug}
          templateSlug={templateSlug}
        />
      </PopperReferenceContext.Provider>
    );
  }
  return null;
};

export default ProjectTemplateScreen;
