import { Layout } from "antd";
import React, { ReactNode } from "react";
import styled from "style/themed";

const SidebarContainer = styled(Layout.Sider)`
  &&& {
    background-color: ${({ theme }) => theme.color.background.primary};
    border-left: 1px solid ${({ theme }) => theme.color.foreground.border};
    padding: ${({ theme }) => theme.margin.medium};
    overflow-y: scroll;
  }

  .ant-layout-sider-children {
    height: auto;
  }
`;

interface RightSidebarProps {
  children?: ReactNode;
  collapsed: boolean;
}

export const RightSidebar = ({ children, collapsed }: RightSidebarProps) => {
  return (
    <SidebarContainer
      collapsed={collapsed}
      width={400}
      collapsible
      collapsedWidth={0}
      trigger={null}
    >
      {children}
    </SidebarContainer>
  );
};
