import { ContentState } from "draft-js";
import React, { ReactNode } from "react";
import { EntityEditorState } from "state/template/EntityEditorState";
import SynonymEntityInterface from "templatio-evaluator/lib/main/evaluator/models/SynonymEntityInterface";
import { EntityText } from "./EntityText";

interface SynonymProps {
  children: ReactNode;
  contentState: ContentState;
  entityKey: string;
  decoratedText: string;
  offsetKey: string;
}

const SynonymRenderer = ({
  contentState,
  entityKey,
  children,
  decoratedText,
  ...other
}: SynonymProps) => {
  const { entityId }: SynonymEntityInterface = contentState
    .getEntity(entityKey)
    .getData();

  const {
    updateEditor,
    isEntityBeingEdited
  } = EntityEditorState.useContainer();
  return (
    <EntityText
      entityType="synonym"
      onMouseDown={(ev: any) => ev.preventDefault()}
      onClick={() => updateEditor({ id: entityId })}
      data-offset-key={other.offsetKey}
      isBeingEdited={isEntityBeingEdited(entityId)}
    >
      {children}
    </EntityText>
  );
};

export default SynonymRenderer;
