import useProjectBySlug from "components/common/hooks/useProjectBySlug";
import LoadingScreen from "components/common/layout/LoadingScreen";
import ProjectDataDetails from "components/projectDataScreen/ProjectDataDetails";
import { WithProjectSlug } from "navigation/Paths";
import React from "react";
import { RouteComponentProps } from "react-router";
import styled from "style/themed";
import AppScreen from "./AppScreen";
import Loading from "./Loading";

const Screen = styled(AppScreen)`
  padding: ${({ theme }) => theme.margin.medium};
  flex-direction: column;
`;

const ProjectDataScreen = ({ match }: RouteComponentProps<WithProjectSlug>) => {
  const { projectSlug } = match.params;
  const { project, loading } = useProjectBySlug(projectSlug);

  if (loading) {
    return <LoadingScreen />;
  }

  if (project) {
    return (
      <Screen>
        <React.Suspense fallback={<Loading />}>
          <ProjectDataDetails projectId={project.id} />
        </React.Suspense>
      </Screen>
    );
  }
  return null;
};

export default ProjectDataScreen;
