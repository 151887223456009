import { ContentDistribution, ContentPosition, SelfPosition } from "csstype";
import globalTheme from "style/Theme";
import styled from "style/themed";

interface RowProps {
  mainAxis?: ContentDistribution | ContentPosition | "normal";
  crossAxis?: SelfPosition | "baseline" | "normal" | "stretch";
  padding?: keyof typeof globalTheme.margin;
  margin?: keyof typeof globalTheme.margin;
  marginVertical?: keyof typeof globalTheme.margin;
  marginHorizontal?: keyof typeof globalTheme.margin;
  grow?: number;
}

export const Row = styled.div<RowProps>`
  display: flex;
  flex: 1;
  ${({ grow }) => (typeof grow === "number" ? `flex-grow: ${grow};` : "")}
  ${({ mainAxis }) => (mainAxis ? `justify-content: ${mainAxis};` : "")}
  ${({ crossAxis }) => (crossAxis ? `align-items: ${crossAxis};` : "")}
  ${({ padding, theme }) =>
    padding ? `padding: ${theme.margin[padding]};` : ""}
  ${({ margin, theme }) => (margin ? `margin: ${theme.margin[margin]};` : "")}
  ${({ marginHorizontal, theme }) =>
    marginHorizontal
      ? `margin-left: ${theme.margin[marginHorizontal]};
      margin-right: ${theme.margin[marginHorizontal]};`
      : ""}
  ${({ marginVertical, theme }) =>
    marginVertical
      ? `margin-top: ${theme.margin[marginVertical]}; margin-bottom: ${theme.margin[marginVertical]}`
      : ""}
`;

export const Column = styled(Row)`
  flex-direction: column;
`;

export const SpreadRow = styled(Row)`
  justify-content: space-between;
  align-items: ${({ crossAxis }) => crossAxis || "baseline"};
`;
