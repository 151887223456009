import { useQuery } from "@apollo/react-hooks";
import QUERY_PROJECT_BY_SLUG from "./queries/QUERY_PROJECT_BY_SLUG";
import {
  QueryProjectBySlug,
  QueryProjectBySlugVariables
} from "./queries/__generated__/QueryProjectBySlug";

export default (projectSlug: string) => {
  const { data, ...rest } = useQuery<
    QueryProjectBySlug,
    QueryProjectBySlugVariables
  >(QUERY_PROJECT_BY_SLUG, {
    variables: {
      projectSlug
    }
  });

  return { project: data && data.projects && data.projects[0], ...rest };
};
