import { remove } from "ramda";
import { useCallback } from "react";
import SlotFieldInterface from "templatio-evaluator/lib/main/evaluator/models/SlotField";
import { BranchFieldsState } from "../BranchFieldsState";
import { DraftStates } from "../DraftStates";

export const useRemoveSlotField = () => {
  const {
    updateBranchField,
    getBranchField
  } = BranchFieldsState.useContainer();
  const { removeDraftState } = DraftStates.useContainer();

  const removeSlotField = useCallback(
    ({ parentId, localId, index }: SlotFieldInterface) => {
      const branchField = getBranchField({ fieldId: parentId });
      if (branchField) {
        const newFields = remove(index, 1, branchField.slotFields || []);
        removeDraftState(localId);
        updateBranchField({ ...branchField, slotFields: newFields });
      }
    },
    [getBranchField, removeDraftState, updateBranchField]
  );

  return removeSlotField;
};
