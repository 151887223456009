import { Result } from "antd";
import strings from "localisation/strings";
import React from "react";

export default ({ email }: { email: string }) => {
  // const onResend = () => {
  //   console.log("Resent email");
  // };
  return (
    <Result
      status="warning"
      title={strings("verifyEmail.title", { email })}
      // extra={
      //   email ? (
      //     <Button type="primary" key="console" onClick={onResend}>
      //       {strings("verifyEmail.resendButton")}
      //     </Button>
      //   ) : null
      // }
    />
  );
};
