import useProjectData from "components/projectTemplateScreen/hooks/useProjectData";
import { convertToRaw, EditorState } from "draft-js";
import { evolve, map } from "ramda";
import { createInitialState } from "state/templateStateHelpers/createInitialState";
import {
  EVALUATOR_VERSION,
  ServerSnapshot,
  Snapshot,
  StateInterface
} from "templatio-evaluator";
import { BranchesState } from "./BranchesState";
import { BranchFieldsState } from "./BranchFieldsState";
import { DatasState } from "./DatasState";
import { DraftStates } from "./DraftStates";
import { SynonymFieldsState } from "./SynonymFieldsState";
import { SynonymsState } from "./SynonymsState";

export interface LocalSnapshot extends Snapshot {
  draftStates: StateInterface<EditorState>;
}

export const fromServerSnapshot = (serverSnapshot: ServerSnapshot) => {
  return evolve({
    draftStates: map(createInitialState)
  })(serverSnapshot as any) as LocalSnapshot;
};

export const toServerSnapshot = (localSnapshot: LocalSnapshot) => {
  return evolve({
    draftStates: map<EditorState, any>(editorState =>
      convertToRaw(editorState.getCurrentContent())
    )
  })(localSnapshot as any) as ServerSnapshot;
};

export const useSnapshot = ({
  projectId,
  templateId
}: {
  projectId: string;
  templateId: string;
}) => {
  const { draftStates } = DraftStates.useContainer();
  const { branches: branchEntities } = BranchesState.useContainer();
  const { datas: dataEntities } = DatasState.useContainer();
  const { synonyms: synonymEntities } = SynonymsState.useContainer();
  const { synonymFields } = SynonymFieldsState.useContainer();
  const { branchFields } = BranchFieldsState.useContainer();
  const projectData = useProjectData();

  const snapshot: LocalSnapshot = {
    dataVariables: projectData || [],
    projectId,
    templateId,
    draftStates,
    branchEntities,
    dataEntities,
    synonymEntities,
    branchFields,
    synonymFields,
    evaluatorVersion: EVALUATOR_VERSION
  };

  return { snapshot };
};
