import { CompositeDecorator } from "draft-js";
import EntityTypes from "templatio-evaluator/lib/main/evaluator/EntityTypes";
import findEntitiesByType from "./findEntitiesByType";
import BranchRenderer from "./renderers/BranchRenderer";
import DataRenderer from "./renderers/DataRenderer";
import SynonymRenderer from "./renderers/SynonymRenderer";

const decorator = new CompositeDecorator([
  {
    strategy: findEntitiesByType(EntityTypes.SYNONYM),
    component: SynonymRenderer
  },
  {
    strategy: findEntitiesByType(EntityTypes.BRANCH),
    component: BranchRenderer
  },
  {
    strategy: findEntitiesByType(EntityTypes.DATA),
    component: DataRenderer
  }
]);

export default decorator;
