import { Tag as AntdTag, Tooltip } from "antd";
import { TagProps } from "antd/lib/tag";
import { QueryProjectDataVariables_project_dataVariables as ProjectDataVariables } from "components/projectTemplateScreen/queries/__generated__/QueryProjectDataVariables";
import strings from "localisation/strings";
import { find, propEq } from "ramda";
import React from "react";
import styled from "style/themed";
import {
  isEquation,
  isEquationOperand,
  isEquationOperator,
  isOperator
} from "templatio-evaluator";
import {
  Equation,
  EquationElement,
  EquationLiteral,
  EquationOperand,
  EquationSlot,
  EquationVariable
} from "templatio-evaluator/lib/main/evaluator/models/EquationInterfaces";
import { Operator } from "templatio-evaluator/lib/main/evaluator/models/OperatorInterfaces";

const Tag = styled(AntdTag)`
  margin: ${({ theme }) => theme.margin.small};
  text-overflow: ellipsis;
  max-width: 100px;
  overflow: hidden;
`;

export interface EquationRendererProps {
  equationAST: Equation;
  dataVariables: ProjectDataVariables[];
  isRoot?: boolean;
}

type EquationRow = EquationElement | Equation | undefined;

const flattenLogicTree = (logicTree: Equation): EquationRow[][] => {
  const { left, operator, right } = logicTree;

  if (isEquation(left) && isEquationOperator(operator)) {
    const leftTree = flattenLogicTree(left);
    if (isEquation(right)) {
      return [...leftTree, [operator, right.left, right.operator, right.right]];
    }
    return [...leftTree, [operator]];
  }

  return [[left, operator, right]];
};

export const FlatEquationRenderer = ({
  equationAST,
  dataVariables
}: EquationRendererProps) => {
  const flatTree = flattenLogicTree(equationAST);
  return (
    <>
      {flatTree.map((row, index) => (
        <div key={index}>
          {row.map((element, i) => {
            if (isEquationOperand(element)) {
              return (
                <EquationOperandRenderer
                  key={i}
                  operand={element}
                  dataVariables={dataVariables}
                />
              );
            }
            if (isOperator(element)) {
              return <OperatorRenderer key={i} operator={element} />;
            }
            return null;
          })}
        </div>
      ))}
    </>
  );
};

const EquationRenderer = ({
  equationAST,
  dataVariables,
  isRoot = false
}: EquationRendererProps) => {
  const { left, right, operator } = equationAST;
  return (
    <>
      {!isRoot && <span>(</span>}
      {!!left && (
        <EquationOperandRenderer operand={left} dataVariables={dataVariables} />
      )}
      {!!operator && <OperatorRenderer operator={operator} />}
      {!!right && (
        <EquationOperandRenderer
          operand={right}
          dataVariables={dataVariables}
        />
      )}
      {!isRoot && <span>)</span>}
    </>
  );
};

const EquationOperandRenderer = ({
  operand,
  dataVariables
}: {
  operand: EquationOperand;
  dataVariables: ProjectDataVariables[];
}) => {
  if (operand.type === "equation") {
    return (
      <EquationRenderer equationAST={operand} dataVariables={dataVariables} />
    );
  }
  if (operand.type === "equationLiteral") {
    return <EquationLiteralRenderer equationLiteral={operand} />;
  }
  if (operand.type === "equationSlot") {
    return <EquationSlotRenderer equationSlot={operand} />;
  }
  return (
    <EquationVariableRenderer
      equationVariable={operand}
      dataVariables={dataVariables}
    />
  );
};

const EquationTag = ({
  value,
  ...props
}: { value: string | number | boolean } & TagProps) => {
  return (
    <Tooltip title={value}>
      <Tag {...props}>{value}</Tag>
    </Tooltip>
  );
};

const OperatorRenderer = ({ operator }: { operator: Operator }) => (
  <EquationTag color="red" value={operator.operator} />
);

const EquationLiteralRenderer = ({
  equationLiteral
}: {
  equationLiteral: EquationLiteral;
}) => <EquationTag color="blue" value={equationLiteral.value} />;

const EquationSlotRenderer = ({
  equationSlot
}: {
  equationSlot: EquationSlot;
}) => <EquationTag color="black" value={`{{${equationSlot.name}}}`} />;

const EquationVariableRenderer = ({
  equationVariable,
  dataVariables
}: {
  equationVariable: EquationVariable;
  dataVariables: ProjectDataVariables[];
}) => {
  const variable = find(
    propEq("id", equationVariable.variableId),
    dataVariables
  );
  return variable ? (
    <EquationTag color="green" value={variable.name} />
  ) : (
    <EquationTag
      color="#ff0000"
      value={strings("projectTemplate.missingVariable")}
    />
  );
};

export default EquationRenderer;
