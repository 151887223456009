import { useMutation } from "@apollo/react-hooks";
import { message } from "antd";
import { EditableText } from "components/common/EditableText";
import strings from "localisation/strings";
import React, { useState } from "react";
import QUERY_UPDATE_VARIABLE from "./queries/QUERY_UPDATE_VARIABLE";
import { refetchDataVariables } from "./queries/refetch";
import { QueryDataVariables_project_dataVariables } from "./queries/__generated__/QueryDataVariables";
import {
  QueryUpdateVariable,
  QueryUpdateVariableVariables
} from "./queries/__generated__/QueryUpdateVariable";

export interface DataVariableNameProps {
  projectId: string;
  variable: QueryDataVariables_project_dataVariables;
}

export const DataVariableName = ({
  projectId,
  variable
}: DataVariableNameProps) => {
  const [loading, setLoading] = useState(false);
  const [updateVariable] = useMutation<
    QueryUpdateVariable,
    QueryUpdateVariableVariables
  >(QUERY_UPDATE_VARIABLE);

  const onUpdate = async (newName: string) => {
    setLoading(true);
    try {
      await updateVariable({
        variables: {
          name: newName,
          type: variable.type,
          variableId: variable.id
        },
        refetchQueries: [refetchDataVariables({ projectId })]
      });
    } catch (err) {
      message.error(strings("dataTypes.updateFailed"));
    }
    setLoading(false);
  };
  return (
    <EditableText loading={loading} onTextChanged={onUpdate}>
      {variable.name}
    </EditableText>
  );
};
