import Input, { InputProps } from "antd/lib/input";
import strings from "localisation/strings";
import TranslationPaths from "localisation/TranslationPaths";
import React from "react";

interface TInputProps extends InputProps {
  placeholderT?: TranslationPaths;
}

const TInput = ({ placeholderT, ...props }: TInputProps) => (
  <Input placeholder={placeholderT && strings(placeholderT)} {...props} />
);

export default TInput;
