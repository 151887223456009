import { BranchFieldsState } from "state/template/BranchFieldsState";
import { SynonymFieldsState } from "state/template/SynonymFieldsState";

export const useGetField = () => {
  const { getBranchField } = BranchFieldsState.useContainer();
  const { getSynonymField } = SynonymFieldsState.useContainer();

  const getField = (fieldId: string) => {
    return getSynonymField({ fieldId }) || getBranchField({ fieldId });
  };

  return { getField };
};
