import gql from "graphql-tag";

export default gql`
  mutation QueryUpdateVariable(
    $variableId: uuid!
    $type: data_variable_types_enum!
    $name: String!
  ) {
    updateVariable: update_data_variables(
      where: { id: { _eq: $variableId } }
      _set: { type: $type, name: $name }
    ) {
      affected_rows
    }
  }
`;
