import { useQuery } from "@apollo/react-hooks";
import { Breadcrumb } from "antd";
import { Column, Row } from "components/common/Flex";
import { templateBreadcrumbItems } from "components/common/RouteBreadcrumbs";
import React from "react";
import { Manager } from "react-popper";
import Loading from "screens/Loading";
import PlaceholderScreen from "screens/PlaceholderScreen";
import styled from "style/themed";
import { ServerSnapshot } from "templatio-evaluator";
import { SidebarTools } from "./editors/SidebarTools";
import { PreviewButton } from "./editors/template/PreviewButton";
import PublishButton from "./editors/template/PublishButton";
import QUERY_TEMPLATE_SNAPSHOT from "./queries/QUERY_TEMPLATE_SNAPSHOT";
import {
  QueryTemplateSnapshot,
  QueryTemplateSnapshotVariables
} from "./queries/__generated__/QueryTemplateSnapshot";
import TemplateEditorArea from "./TemplateEditorArea";
import { TemplateStateProvider } from "./TemplateStateProvider";
import TemplateStateSaver from "./TemplateStateSaver";

const BreadcrumbArea = styled(Breadcrumb)`
  &&& {
    margin-left: ${({ theme }) => theme.margin.large};
  }
`;

const CenterAreaContainer = styled(Column)`
  position: relative;
  min-width: 0;
`;

const CenterArea = styled(Column)`
  overflow-y: scroll;
`;

const PreviewButtonPositioner = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: ${({ theme }) => theme.margin.medium};
  right: ${({ theme }) => theme.margin.medium};
`;

const MarginMedium = styled.div`
  margin: ${({ theme }) => theme.margin.medium};
`;

export interface ProjectTemplateProps {
  projectId: string;
  templateId: string;
  projectSlug: string;
  templateSlug: string;
}

const ProjectTemplate = ({
  projectId,
  templateId,
  projectSlug,
  templateSlug
}: ProjectTemplateProps) => {
  const { data, loading, error } = useQuery<
    QueryTemplateSnapshot,
    QueryTemplateSnapshotVariables
  >(QUERY_TEMPLATE_SNAPSHOT, {
    variables: { templateId },
    fetchPolicy: "network-only"
  });

  if (loading) {
    return <Loading />;
  }
  if (error || !data || !data.template) {
    return <PlaceholderScreen />;
  }

  const [firstSnap] = data.template.snapshots;
  const snap: ServerSnapshot | undefined = firstSnap && firstSnap.snapshotData;
  return (
    <TemplateStateProvider
      templateId={templateId}
      projectId={projectId}
      snapshot={snap}
    >
      <Manager>
        <CenterAreaContainer>
          <CenterArea padding="medium">
            <Row grow={0} crossAxis="center" marginVertical="small">
              <TemplateStateSaver
                projectId={projectId}
                templateId={templateId}
              />
              <BreadcrumbArea>
                {templateBreadcrumbItems({
                  projectSlug: data.template.project.slug,
                  projectName: data.template.project.name,
                  templateName: data.template.name,
                  templateSlug: data.template.slug
                })}
              </BreadcrumbArea>
            </Row>
            <TemplateEditorArea templateId={templateId} />
          </CenterArea>
          <PreviewButtonPositioner>
            <PublishButton
              projectId={projectId}
              templateId={templateId}
              projectSlug={projectSlug}
              templateSlug={templateSlug}
              publishingUrl={data.template.project.publishingUrl}
            />
            <MarginMedium />
            <PreviewButton projectId={projectId} templateId={templateId} />
          </PreviewButtonPositioner>
        </CenterAreaContainer>
        <SidebarTools templateId={templateId} />
      </Manager>
    </TemplateStateProvider>
  );
};

export default ProjectTemplate;
