import { Button } from "antd";
import React, { useState } from "react";
import UpdateTemplateModal from "./UpdateTemplateModal";

export interface UpdateTemplateButtonProps {
  projectId: string;
  templateId: string;
  templateName: string;
  templateSlug: string;
  className?: string;
}

const UpdateTemplateButton = ({
  projectId,
  templateId,
  templateName,
  templateSlug,
  className
}: UpdateTemplateButtonProps) => {
  const [modalVisible, setModalVisible] = useState(false);
  const onCancel = () => setModalVisible(false);
  const onDone = () => {
    setModalVisible(false);
  };
  return (
    <div className={className} onClick={(ev: any) => ev.stopPropagation()}>
      <Button icon="edit" onClick={ev => setModalVisible(true)} />
      <UpdateTemplateModal
        projectId={projectId}
        templateId={templateId}
        visible={modalVisible}
        onCancel={onCancel}
        onDone={onDone}
        initialName={templateName}
        initialSlug={templateSlug}
      />
    </div>
  );
};

export default UpdateTemplateButton;
