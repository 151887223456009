export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
};

export type Mutation = {
   __typename?: 'Mutation',
  login: Scalars['String'],
};


export type MutationLoginArgs = {
  token: Scalars['String']
};

export type Query = {
   __typename?: 'Query',
  hello: Scalars['String'],
};

export enum ThApiErrorTypes {
  TokenRefreshNeeded = 'TOKEN_REFRESH_NEEDED',
  InvalidToken = 'INVALID_TOKEN',
  LoginFailed = 'LOGIN_FAILED'
}
