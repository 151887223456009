import { AutoComplete, Input } from "antd";
import { OptionProps, SelectValue } from "antd/lib/select";
import useProjectData from "components/projectTemplateScreen/hooks/useProjectData";
import { QueryProjectDataVariables_project_dataVariables as ProjectDataVariables } from "components/projectTemplateScreen/queries/__generated__/QueryProjectDataVariables";
import strings from "localisation/strings";
import React, { ReactElement, useState } from "react";
import styled from "style/themed";
import {
  addToEquation,
  deleteLastElement,
  findLastElement,
  findLastEquation,
  isComplete,
  isEquationOperator,
  isEquationVariable,
  isTextOperator
} from "templatio-evaluator";
import { DataVariableTypes } from "templatio-evaluator/lib/main/evaluator/models/DataVariableTypes";
import {
  Equation,
  EquationLiteral,
  EquationSlot,
  EquationVariable
} from "templatio-evaluator/lib/main/evaluator/models/EquationInterfaces";
import { Operator } from "templatio-evaluator/lib/main/evaluator/models/OperatorInterfaces";
import uuid from "uuid/v4";
import { FlatEquationRenderer } from "./EquationRenderer";
import { equationOperators, textOperators } from "./Operators";
import { renderOperatorsGroup, renderVariablesGroup } from "./OptionsRenderers";

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const ConditionAutocomplete = styled(AutoComplete)`
  margin: ${({ theme }) => theme.margin.small};
`;

const parseJson = (maybeJson: any) => {
  try {
    return JSON.parse(`${maybeJson}`);
  } catch (err) {}
};

const LITERAL = "LITERAL";
const SLOT = "SLOT";

const compileOptions = (
  inputValue: string,
  equation: Equation,
  variables: ProjectDataVariables[]
) => {
  const lastEquation = findLastEquation(equation);
  const lastElement = findLastElement(equation);
  let options: JSX.Element[] = [];
  if (isTextOperator(lastElement) && inputValue) {
    options = [
      ...options,
      <AutoComplete.OptGroup key="textLiteral" label="Text literal">
        <AutoComplete.Option value={LITERAL}>{inputValue}</AutoComplete.Option>
      </AutoComplete.OptGroup>,
      <AutoComplete.OptGroup key="textSlot" label="Text slot">
        <AutoComplete.Option value={SLOT}>
          {`{{${inputValue}}}`}
        </AutoComplete.Option>
      </AutoComplete.OptGroup>
    ];
  }
  if (
    !lastElement ||
    isTextOperator(lastElement) ||
    isEquationOperator(lastElement)
  ) {
    options = [...options, renderVariablesGroup(variables)];
  }
  if (isEquationVariable(lastElement) && !lastEquation.operator) {
    options = [
      ...options,
      renderOperatorsGroup(textOperators, "Text operators")
    ];
  }
  if (isComplete(lastEquation) && !isEquationOperator(lastElement)) {
    options = [
      ...options,
      renderOperatorsGroup(equationOperators, "Equation operators")
    ];
  }
  return options;
};

const filterOption = (
  filterInputValue: string,
  option: ReactElement<OptionProps>
) => {
  if (typeof option.props.children === "string") {
    return (
      option.props.children
        .toUpperCase()
        .indexOf(filterInputValue.toUpperCase()) !== -1
    );
  }
  return false;
};

interface BranchConditionProps {
  logicTree: Equation;
  setLogicTree: (logicTree: Equation) => void;
  autoCompleteRef?: React.RefObject<AutoComplete>;
}

const BranchCondition = ({
  logicTree,
  setLogicTree,
  autoCompleteRef
}: BranchConditionProps) => {
  const dataVariables = useProjectData() || [];
  const [inputValue, setInputValue] = useState("");
  const [lastEvent, setLastEvent] = useState<"down" | "up">("up");
  const [deleteOnUp, setDeleteOnUp] = useState(false);

  const onOptionSelected = (selectedValue: SelectValue) => {
    const selected: EquationVariable | Operator | undefined = parseJson(
      selectedValue
    );
    if (!selected) {
      if (selectedValue === LITERAL) {
        addLiteral();
      }
      if (selectedValue === SLOT) {
        addSlot();
      }
    } else {
      setLogicTree(addToEquation(logicTree, selected));
      setInputValue("");
    }
  };

  const onEnter = () => {
    const lastElement = findLastElement(logicTree);
    if (isTextOperator(lastElement)) {
      addLiteral();
    }
  };

  const addLiteral = () => {
    const operand: EquationLiteral = {
      type: "equationLiteral",
      dataType: DataVariableTypes.TEXT,
      value: inputValue
    };
    setLogicTree(addToEquation(logicTree, operand));
    setInputValue("");
  };

  const addSlot = () => {
    const operand: EquationSlot = {
      type: "equationSlot",
      dataType: DataVariableTypes.TEXT,
      id: uuid(),
      name: inputValue
    };
    setLogicTree(addToEquation(logicTree, operand));
    setInputValue("");
  };

  return (
    <Column>
      <FlatEquationRenderer
        equationAST={logicTree}
        dataVariables={dataVariables}
        isRoot
      />
      <ConditionAutocomplete
        ref={autoCompleteRef}
        defaultActiveFirstOption={false}
        value={inputValue}
        onSearch={setInputValue}
        dataSource={compileOptions(inputValue, logicTree, dataVariables)}
        onSelect={onOptionSelected}
        filterOption={filterOption}
      >
        <Input
          onPressEnter={onEnter}
          onKeyDown={e => {
            if (e.keyCode === 8) {
              if (lastEvent === "up") {
                if (!inputValue) {
                  setDeleteOnUp(true);
                }
                setLastEvent("down");
              }
            }
          }}
          onKeyUp={e => {
            if (e.keyCode === 8) {
              if (deleteOnUp) {
                setLogicTree(deleteLastElement(logicTree));
              }
              setDeleteOnUp(false);
              setLastEvent("up");
            }
          }}
          placeholder={strings("branchCondition.equationInputPlaceholder")}
        />
      </ConditionAutocomplete>
    </Column>
  );
};

export default BranchCondition;
