import useUserData from "components/common/hooks/useUserData";
import React from "react";

const CurrentUser = () => {
  const user = useUserData();

  if (!user?.primaryEmail) {
    return <span>Account</span>;
  }

  return <span>{user.primaryEmail}</span>;
};
export default CurrentUser;
