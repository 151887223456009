import { ApolloError } from "apollo-client";
import gatewayLogin from "appGateway/gatewayLogin";
import { getFirstErrorType } from "authService/errorHandling";
import { AuthErrorTypes } from "authService/generated/graphql";
import loginWithEmail from "authService/loginWithEmail";
import signUpWithEmail from "authService/signUpWithEmail";
import verifyEmail from "authService/verifyEmail";
import { useState } from "react";
import AuthState from "state/AuthState";

export interface EmailPassword {
  email: string;
  password: string;
  rememberUser?: boolean;
}

export default () => {
  const { onError, loggedIn } = AuthState.useContainer();
  const [error, setError] = useState<ApolloError>();
  const [errorType, setErrorType] = useState<AuthErrorTypes>();
  const [loading, setLoading] = useState(false);

  const authenticate = async <T>(
    params: T,
    method: (
      params: T
    ) => Promise<{
      token: string | null | undefined;
      userId: string | null | undefined;
    }>
  ) => {
    setError(undefined);
    setErrorType(undefined);
    setLoading(true);
    try {
      const { token: loginToken } = await method(params);
      const { token, userId } = await gatewayLogin(loginToken);
      setLoading(false);
      if (token) {
        loggedIn({ token, userId });
      } else {
        onError();
      }
    } catch (error) {
      setLoading(false);
      setError(error);
      setErrorType(getFirstErrorType(error));
    }
  };

  const login = async (params: EmailPassword) =>
    await authenticate(params, loginWithEmail);
  const signUp = async (params: EmailPassword) =>
    await authenticate(params, signUpWithEmail);
  const emailVerification = async (token: string) => {
    await authenticate(token, verifyEmail);
  };

  return { login, signUp, emailVerification, error, errorType, loading };
};
