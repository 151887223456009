import { List } from "antd";
import { ListProps } from "antd/lib/list";
import useSubscription from "components/common/hooks/useSubscription";
import strings from "localisation/strings";
import { Paths } from "navigation/Paths";
import React from "react";
import { Link } from "react-router-dom";
import Loading from "screens/Loading";
import DeleteProjectButton from "./DeleteProjectButton";
import PROJECTS_QUERY from "./queries/PROJECTS_QUERY";
import { Projects, Projects_projects } from "./queries/__generated__/Projects";

const ProjectsList = (
  props: Partial<ListProps<Projects_projects>> & { addedProjects?: [] }
) => {
  const { data, loading } = useSubscription<Projects>(PROJECTS_QUERY);

  if (loading) {
    return <Loading />;
  }

  if (data) {
    return (
      <List
        dataSource={data.projects}
        renderItem={(item: Projects_projects) => {
          return (
            <List.Item
              key={item.id}
              actions={[
                <DeleteProjectButton key={item.id} projectId={item.id} />
              ]}
            >
              <List.Item.Meta
                title={
                  <Link
                    to={Paths.projectTemplates.withParams({
                      projectSlug: item.slug
                    })}
                  >
                    {item.name}
                  </Link>
                }
                description={
                  <>
                    <span>
                      {strings("projects.owner")}
                      {item.owner.email}
                    </span>
                    {item.members.length ? (
                      <span>
                        <br />
                        {strings("projects.collaborators")}
                        {item.members
                          .map(({ user: { email } }) => email)
                          .join(", ")}
                      </span>
                    ) : null}
                  </>
                }
              />
            </List.Item>
          );
        }}
        {...props}
      />
    );
  }
  return null;
};

export default ProjectsList;
