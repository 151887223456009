import { AuthErrorTypes } from "authService/generated/graphql";
import useEmailAuth from "authService/hooks/useEmailAuth";
import AppContainer from "components/common/layout/AppContainer";
import VerifyEmail from "components/loginScreen/VerifyEmail";
import RegisterForm from "components/registerScreen/registerForm";
import { Paths } from "navigation/Paths";
import React, { useState } from "react";
import { Redirect } from "react-router";
import AuthState from "state/AuthState";

const RegisterScreen = () => {
  const { signUp, errorType } = useEmailAuth();
  const { authState } = AuthState.useContainer();
  const [email, setEmail] = useState("");

  if (authState.status === "loggedIn") {
    return <Redirect to={Paths.projects.path} />;
  }

  if (errorType === AuthErrorTypes.EmailVerificationRequired) {
    return (
      <AppContainer>
        <VerifyEmail email={email} />
      </AppContainer>
    );
  }

  const onRegister = (params: { email: string; password: string }) => {
    setEmail(params.email);
    signUp(params);
  };

  return (
    <AppContainer>
      <RegisterForm
        onRegister={onRegister}
        errors={errorType ? [errorType] : []}
      />
    </AppContainer>
  );
};

export default RegisterScreen;
