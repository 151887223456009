import { Button, Input, Table } from "antd";
import { Row, SpreadRow } from "components/common/Flex";
import useProjectData from "components/projectTemplateScreen/hooks/useProjectData";
import { assocPath, dissocPath, uniq } from "ramda";
import React, { ReactNode, useState } from "react";
import styled from "style/themed";
import { evaluateTree, findEquationVariables } from "templatio-evaluator";
import BranchEquationTest from "templatio-evaluator/lib/main/evaluator/models/BranchEquationTest";
import {
  Equation,
  EquationVariable
} from "templatio-evaluator/lib/main/evaluator/models/EquationInterfaces";
import { FlatEquationRenderer } from "./EquationRenderer";

interface ColumnInterface {
  title: string;
  dataIndex: string;
  render: (text: any, record: any, index: number) => ReactNode;
}

const isColumn = (col?: ColumnInterface): col is ColumnInterface => !!col;

const MinWidthInput = styled(Input)`
  min-width: 80px;
`;

const EquationRendererContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.margin.large};
`;

interface EditableCellProps {
  onValueChanged?: (value: string) => void;
  initialValue?: string;
}

const EditableCell = ({ onValueChanged, initialValue }: EditableCellProps) => {
  const [value, setValue] = useState(initialValue || "");
  const onSave = () => {
    if (onValueChanged) {
      onValueChanged(value);
    }
  };

  return (
    <MinWidthInput
      value={value}
      onChange={ev => {
        setValue(ev.target.value);
      }}
      onBlur={onSave}
      onPressEnter={onSave}
    />
  );
};

const createEmptyTest = (equationVariables: EquationVariable[]) => {
  const emptyTest: BranchEquationTest = {};
  equationVariables.forEach(({ variableId }) => {
    emptyTest[variableId] = "";
  });
  return emptyTest;
};

export interface LogicTestMatrixProps {
  logicTree: Equation;
  tests: BranchEquationTest[];
  setTests: (tests: BranchEquationTest[]) => void;
}

const LogicTestMatrix = ({
  logicTree,
  tests,
  setTests
}: LogicTestMatrixProps) => {
  const projectVariables = useProjectData() || [];
  const equationVariables = uniq(findEquationVariables(logicTree));

  const initTests = () => {
    const emptyTest = createEmptyTest(equationVariables);
    setTests(tests.map(test => ({ ...emptyTest, ...test })));
  };

  const onAddTest = () => {
    const index = tests.length;
    const emptyTest = createEmptyTest(equationVariables);
    setTests(
      assocPath<BranchEquationTest, BranchEquationTest[]>([index], emptyTest)(
        tests
      )
    );
  };

  const onRemoveTest = (index: number) => {
    setTests(dissocPath<BranchEquationTest[]>([index])(tests));
  };

  const updateTest = (index: number, newTest: BranchEquationTest) => {
    const newTests = assocPath<BranchEquationTest, BranchEquationTest[]>(
      [index],
      {
        ...tests[index],
        ...newTest
      }
    )(tests);
    setTests(newTests);
  };

  const columns: ColumnInterface[] = equationVariables
    .map(({ variableId }) => {
      const dataVariable = projectVariables.find(({ id }) => id === variableId);
      if (dataVariable) {
        const column: ColumnInterface = {
          title: dataVariable.name,
          dataIndex: variableId,
          render: (text, record, index) => {
            if (typeof tests[index][variableId] !== "string") {
              initTests();
            }
            const setValue = (newValue: string) => {
              updateTest(index, {
                [variableId]: newValue
              });
            };
            return (
              <EditableCell onValueChanged={setValue} initialValue={text} />
            );
          }
        };
        return column;
      }
      return undefined;
    })
    .filter(isColumn);

  const resultColumn: ColumnInterface = {
    title: "Result",
    dataIndex: "result",
    render: (text, record, index) => {
      return (
        <SpreadRow key={index} crossAxis="center">
          <span>{`${evaluateTree(logicTree, tests[index] || {})}`}</span>
          <Row margin="small" />
          <Button icon="delete" onClick={() => onRemoveTest(index)} />
        </SpreadRow>
      );
    }
  };

  return (
    <>
      <EquationRendererContainer>
        <FlatEquationRenderer
          equationAST={logicTree}
          dataVariables={projectVariables}
        />
      </EquationRendererContainer>
      <Table
        bordered
        columns={[...columns, resultColumn]}
        dataSource={tests.map((test, key) => ({ ...test, key }))}
        scroll={{ x: true }}
        size="small"
        pagination={false}
      />
      <Row mainAxis="flex-end" margin="medium">
        <Button icon="plus" onClick={onAddTest} />
      </Row>
    </>
  );
};

export default LogicTestMatrix;
