import { Button } from "antd";
import { NativeButtonProps } from "antd/lib/button/button";
import strings from "localisation/strings";
import React from "react";
import { EntityEditorState } from "state/template/EntityEditorState";
import styled from "style/themed";

const DoneButton = styled(Button)<NativeButtonProps>`
  flex-shrink: 1;
  margin-bottom: ${({ theme }) => theme.margin.medium};
`;

const ToolsDoneButton = () => {
  const { goOneLevelUp } = EntityEditorState.useContainer();
  return (
    <div>
      <DoneButton onClick={goOneLevelUp}>{strings("buttons.done")}</DoneButton>
    </div>
  );
};

export default ToolsDoneButton;
