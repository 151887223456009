import { getVisibleSelectionRect } from "draft-js";
import { createContext } from "react";

export interface FakeClientRect {
  top: number;
  left: number;
  bottom: number;
  right: number;
  width: number;
  height: number;
}

export class VirtualReference {
  rect: FakeClientRect = {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: 0,
    height: 0
  };

  update = () => {
    const rect = getVisibleSelectionRect(window);
    if (rect) {
      this.rect = rect;
      this.rectChangedCallback(this.rect);
    }
  };

  rectChangedCallback(rect: FakeClientRect) {
    // Abstract to be implemented
  }

  getBoundingClientRect() {
    return this.rect;
  }

  get clientWidth() {
    return this.rect.width;
  }

  get clientHeight() {
    return this.rect.height;
  }
}

export const PopperReferenceContext = createContext(new VirtualReference());
