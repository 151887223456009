import gql from "graphql-tag";

export default gql`
  mutation QueryCreateProjectMemberInvite(
    $projectId: uuid!
    $inviteeEmail: String!
  ) {
    insert_project_member_invites(
      objects: { invitee_email: $inviteeEmail, project_id: $projectId }
    ) {
      returning {
        id
      }
    }
  }
`;
