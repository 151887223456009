import { useQuery } from "@apollo/react-hooks";
import QUERY_ME from "sidebar/queries/QUERY_ME";
import {
  QueryMe,
  QueryMeVariables
} from "sidebar/queries/__generated__/QueryMe";
import AuthState from "state/AuthState";

export default () => {
  const { authState } = AuthState.useContainer();
  const { data } = useQuery<QueryMe, QueryMeVariables>(QUERY_ME, {
    variables: { userId: authState.userId || "" }
  });

  return data?.users?.[0];
};
