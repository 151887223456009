import { Descriptions } from "antd";
import ExampleDataRow from "components/projectDataScreen/ExampleDataRow";
import useProjectData from "components/projectTemplateScreen/hooks/useProjectData";
import { toPairs } from "ramda";
import React from "react";

export const ExampleDataPreview = ({ data }: { data: ExampleDataRow }) => {
  const variables = useProjectData() || [];
  const dataWithVariableNames = toPairs(data).map(([variableId, value]) => {
    const variable = variables.find(({ id }) => id === variableId);
    return { name: variable ? variable.name : "", value };
  });
  return (
    <Descriptions column={1} bordered size="small">
      {dataWithVariableNames.map(({ name, value }) => (
        <Descriptions.Item key={name} label={name}>
          {value}
        </Descriptions.Item>
      ))}
    </Descriptions>
  );
};
