import { useEffect } from "react";
import { useChangeEntityTreeText } from "state/template/expensive/useChangeEntityTreeText";
import { useDraftState } from "state/template/expensive/useDraftState";

export const EntityTreeUpdater = ({ fieldId }: { fieldId: string }) => {
  const { changeText } = useChangeEntityTreeText({ fieldId });
  const { draftState } = useDraftState({ stateId: fieldId });

  useEffect(() => {
    if (draftState) {
      changeText(draftState);
    }
    // eslint-disable-next-line
  }, [draftState]);

  return null;
};
