import styled from "style/themed";

export const EditorInputContainer = styled.div`
  cursor: text;
  padding: ${({ theme }) => theme.margin.medium};
  transition: all 0.3s, height 0s;
  flex-grow: 1;
  background-color: ${({ theme }) => theme.color.background.primary};
  border: 1px solid ${({ theme }) => theme.color.foreground.border};
  :hover {
    border-color: ${({ theme }) => theme.color.foreground.action};
  }
`;
