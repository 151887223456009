import { Card } from "antd";
import ScreenTitle from "components/common/texts/ScreenTitle";
import ProjectsList from "components/projectsScreen/ProjectsList";
import ProjectsListActions from "components/projectsScreen/ProjectsListActions";
import React, { Component } from "react";
import AppScreen from "./AppScreen";
import Loading from "./Loading";

export default class ProjectsScreen extends Component {
  render() {
    return (
      <AppScreen>
        <ScreenTitle t="projects.pageTitle" />
        <React.Suspense fallback={<Loading />}>
          <Card>
            <ProjectsList footer={<ProjectsListActions />} />
          </Card>
        </React.Suspense>
      </AppScreen>
    );
  }
}
