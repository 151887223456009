import RaisedButton from "components/common/actions/RaisedButton";
import strings from "localisation/strings";
import React, { useState } from "react";
import { SnapshotPreviewDrawer } from "./SnapshotPreviewDrawer";

interface PreviewButtonProps {
  templateId: string;
  projectId: string;
}

export const PreviewButton = ({
  projectId,
  templateId,
}: PreviewButtonProps) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isDrawerMounted, setIsDrawerMounted] = useState(false);

  const openDrawer = () => {
    setIsDrawerVisible(true);
    setIsDrawerMounted(true);
  };

  const closeDrawer = () => {
    setIsDrawerVisible(false);
  };

  return (
    <>
      <RaisedButton
        onClick={openDrawer}
        shape="round"
        type="primary"
        icon="file-text"
      >
        {strings("projectTemplate.templateTools.previewButton")}
      </RaisedButton>
      {isDrawerMounted && (
        <SnapshotPreviewDrawer
          projectId={projectId}
          templateId={templateId}
          visible={isDrawerVisible}
          onClose={closeDrawer}
          destroyOnClose
          afterVisibleChange={(visible) => {
            if (!visible) {
              setIsDrawerMounted(false);
            }
          }}
        />
      )}
    </>
  );
};
