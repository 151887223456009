import { Button } from "antd";
import { UnmountingDrawer } from "components/common/UnmountingDrawer";
import React, { useState } from "react";
import { MultiBranchTools } from "./MultiBranchTools";

export interface MultiBranchButtonProps {
  branchFieldId: string;
  visible?: boolean;
}

export const MultiBranchButton = ({
  branchFieldId,
  visible
}: MultiBranchButtonProps) => {
  const [drawerVisible, setDrawerVisible] = useState(false);

  if (!visible) {
    return null;
  }

  return (
    <div onMouseDown={ev => ev.stopPropagation()}>
      <Button icon="bars" onClick={() => setDrawerVisible(true)} />
      <UnmountingDrawer
        onClose={() => {
          setDrawerVisible(false);
        }}
        visible={drawerVisible}
        width={600}
        bodyStyle={{ padding: 0 }}
      >
        <MultiBranchTools branchFieldId={branchFieldId} />
      </UnmountingDrawer>
    </div>
  );
};
