import { Button } from "antd";
import strings from "localisation/strings";
import React from "react";
import { useAddRemoveSynonymFields } from "state/template/expensive/useAddRemoveSynonymField";

export interface AddSynonymFieldProps {
  entityId: string;
}

const AddSynonymField = ({ entityId }: AddSynonymFieldProps) => {
  const { addSynonymField } = useAddRemoveSynonymFields();
  return (
    <div>
      <Button
        type="primary"
        onClick={() => addSynonymField({ synonymId: entityId })}
      >
        {strings("projectTemplate.synonymTools.addSynonymField.addButton")}
      </Button>
    </div>
  );
};

export default AddSynonymField;
