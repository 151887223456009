import gql from "graphql-tag";

export default gql`
  query QueryDataVariables($projectId: uuid!) {
    project: projects_by_pk(id: $projectId) {
      id
      name
      slug
      dataVariables: data_variables(order_by: { name: asc }) {
        id
        name
        type
      }
    }
  }
`;
