import { useMutation } from "@apollo/react-hooks";
import QUERY_UPSERT_TEMPLATE_SNAPSHOT from "components/projectTemplateScreen/queries/QUERY_UPSERT_TEMPLATE_SNAPSHOT";
import {
  QueryUpsertTemplateSnapshot,
  QueryUpsertTemplateSnapshotVariables
} from "components/projectTemplateScreen/queries/__generated__/QueryUpsertTemplateSnapshot";
import { equals } from "ramda";
import { useEffect, useState } from "react";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { ServerSnapshot } from "templatio-evaluator";
import { BranchesState } from "./BranchesState";
import { BranchFieldsState } from "./BranchFieldsState";
import { DatasState } from "./DatasState";
import { DraftStates } from "./DraftStates";
import { SynonymFieldsState } from "./SynonymFieldsState";
import { SynonymsState } from "./SynonymsState";
import { LocalSnapshot, toServerSnapshot, useSnapshot } from "./useSnapshot";

interface SnapshotChange {
  lastSnapshot: ServerSnapshot;
  snapshot: LocalSnapshot;
}

export const useSnapshotSaver = ({
  projectId,
  templateId
}: {
  projectId: string;
  templateId: string;
}) => {
  const { draftStates } = DraftStates.useContainer();
  const { branches: branchEntities } = BranchesState.useContainer();
  const { datas: dataEntities } = DatasState.useContainer();
  const { synonyms: synonymEntities } = SynonymsState.useContainer();
  const { synonymFields } = SynonymFieldsState.useContainer();
  const { branchFields } = BranchFieldsState.useContainer();
  const [lastSnapshot, setLastSnapshot] = useState({} as ServerSnapshot);
  const [change$] = useState(new Subject<SnapshotChange>());
  const [status, setStatus] = useState<"SAVED" | "UNSAVED" | "ERROR">(
    "UNSAVED"
  );
  const { snapshot: localSnapshot } = useSnapshot({ projectId, templateId });

  const [upsertTemplateSnapshot] = useMutation<
    QueryUpsertTemplateSnapshot,
    QueryUpsertTemplateSnapshotVariables
  >(QUERY_UPSERT_TEMPLATE_SNAPSHOT);

  const saveSnapshot = async ({
    lastSnapshot: lastSnap,
    snapshot
  }: SnapshotChange) => {
    const serverSnapshot = toServerSnapshot(snapshot);
    if (equals(lastSnap, serverSnapshot)) {
      setStatus("SAVED");
      return;
    }
    try {
      await upsertTemplateSnapshot({
        variables: {
          templateId,
          snapshot: serverSnapshot
        }
      });
    } catch (err) {
      setStatus("ERROR");
      return;
    }
    setLastSnapshot(serverSnapshot);
    setStatus("SAVED");
  };

  useEffect(() => {
    const subscription = change$
      .pipe(debounceTime(1000))
      .subscribe(saveSnapshot);
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line
  }, []);

  const triggerSave = () => {
    if (status !== "UNSAVED") {
      setStatus("UNSAVED");
    }
    change$.next({ lastSnapshot, snapshot: localSnapshot });
  };

  useEffect(() => {
    triggerSave();
    // eslint-disable-next-line
  }, [
    draftStates,
    branchEntities,
    dataEntities,
    synonymEntities,
    synonymFields,
    branchFields
  ]);

  return { status, triggerSave };
};
