import { Form, message } from "antd";
import { WrappedFormUtils } from "antd/lib/form/Form";
import { ApolloError } from "apollo-client";
import FormField from "components/forms/FormField";
import SlugInputItem from "components/forms/SlugInputItem";
import { useCheckSlug } from "components/forms/useCheckSlug";
import strings from "localisation/strings";
import React, { useEffect } from "react";

export interface TemplateFormFields {
  name: string;
  slug: string;
}

export interface UpsertTemplateFormProps {
  form: WrappedFormUtils<TemplateFormFields>;
  error?: ApolloError | undefined;
  projectId: string;
  initialName?: string;
  initialSlug?: string;
  visible?: boolean;
}

const UpsertTemplateFormComponent = ({
  form,
  error,
  projectId,
  initialName,
  initialSlug,
  visible
}: UpsertTemplateFormProps) => {
  const { checkSlug, sameSlugCount } = useCheckSlug({
    type: "templateSlug",
    projectId
  });
  const { getFieldValue } = form;

  useEffect(() => {
    if (visible) {
      form.setFields({
        name: { value: initialName }
      });
      if (initialSlug) {
        setTimeout(() => {
          form.setFields({
            slug: { value: initialSlug }
          });
        }, 100);
      }
    }
    // eslint-disable-next-line
  }, [initialName, visible]);

  useEffect(() => {
    if (error) {
      message.error(strings("upsertTemplateForm.createFailed"));
    }
  }, [error]);

  return (
    <Form layout="vertical">
      <FormField
        form={form}
        fieldKey="name"
        label={strings("upsertTemplateForm.fields.name.label")}
        rules={[
          {
            required: true,
            message: strings("upsertTemplateForm.fields.name.message")
          }
        ]}
      />
      <SlugInputItem
        checkSlug={checkSlug}
        sameSlugCount={sameSlugCount}
        slugFrom={getFieldValue("name")}
        formUtils={form}
        error={error}
      />
    </Form>
  );
};

const UpsertTemplateForm = Form.create<UpsertTemplateFormProps>()(
  UpsertTemplateFormComponent
);

export default UpsertTemplateForm;
