import React, { ReactNode } from "react";
import { Menu, Dropdown } from "antd";
import useProjectData from "./hooks/useProjectData";
import { DropDownProps } from "antd/lib/dropdown";

interface DataDropdownProps extends Partial<DropDownProps> {
  children?: ReactNode;
  onVariableSelected?: (variable: { id: string; name: string }) => void;
  selectedVariableId?: string;
}

const DataDropdown = ({
  children,
  onVariableSelected = () => {},
  selectedVariableId,
  ...dropdownProps
}: DataDropdownProps) => {
  const projectDataVariables = useProjectData() || [];
  const menu = (
    <Menu>
      {projectDataVariables.map(({ name, id }) => (
        <Menu.Item
          disabled={selectedVariableId === id}
          key={id}
          onClick={() => onVariableSelected({ id, name })}
        >
          {name}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} {...dropdownProps}>
      {children}
    </Dropdown>
  );
};

export default DataDropdown;
