import { useMutation } from "@apollo/react-hooks";
import { message } from "antd";
import { EditableText } from "components/common/EditableText";
import strings from "localisation/strings";
import { assocPath, isNil } from "ramda";
import React, { useState } from "react";
import ExampleDataRow from "./ExampleDataRow";
import QUERY_UPDATE_EXAMPLTE_DATA from "./queries/QUERY_UPDATE_EXAMPLTE_DATA";
import refetchProjectData from "./queries/refetchProjectData";
import {
  QueryUpdateExampleData,
  QueryUpdateExampleDataVariables
} from "./queries/__generated__/QueryUpdateExampleData";

export interface ExampleDataCellProps {
  projectId: string;
  rowIndex: number;
  variableId: string;
  rows: ExampleDataRow[];
}

export const ExampleDataCell = ({
  rowIndex,
  variableId,
  rows,
  projectId
}: ExampleDataCellProps) => {
  const [loading, setLoading] = useState(false);
  const [updateData] = useMutation<
    QueryUpdateExampleData,
    QueryUpdateExampleDataVariables
  >(QUERY_UPDATE_EXAMPLTE_DATA);

  const onUpdate = async (newText: string) => {
    setLoading(true);
    try {
      await updateData({
        variables: {
          projectId,
          rows: assocPath([rowIndex, variableId], newText, rows)
        },
        refetchQueries: [refetchProjectData({ projectId })]
      });
    } catch (err) {
      message.error(strings("projectData.exampleDataCell.updateFailed"));
    }
    setLoading(false);
  };

  const variableName = rows[rowIndex][variableId];
  return (
    <EditableText loading={loading} onTextChanged={onUpdate}>
      {`${!isNil(variableName) ? variableName : ""}`}
    </EditableText>
  );
};
