import gql from "graphql-tag";

export default gql`
  subscription QueryProjectMembers($projectId: uuid!) {
    project: projects_by_pk(id: $projectId) {
      id
      name
      slug
      members {
        user {
          id
          primaryEmail: primary_email
        }
      }
      owner {
        primaryEmail: primary_email
        id
      }
      memberInvites: member_invites {
        id
        inviteeEmail: invitee_email
      }
    }
  }
`;
