import { useQuery } from "@apollo/react-hooks";
import { Breadcrumb } from "antd";
import { Column } from "components/common/Flex";
import TCard from "components/common/layout/TCard";
import { projectBreadcrumbItems } from "components/common/RouteBreadcrumbs";
import React from "react";
import Loading from "screens/Loading";
import PlaceholderScreen from "screens/PlaceholderScreen";
import styled from "style/themed";
import AddTemplateButton from "./AddTemplateButton";
import PROJECT_TEMPLATES from "./queries/PROJECT_TEMPLATES";
import {
  ProjectTemplates as ProjectTemplatesInterface,
  ProjectTemplatesVariables
} from "./queries/__generated__/ProjectTemplates";
import TemplatesTable from "./TemplatesTable";

const AddButton = styled(AddTemplateButton)`
  margin-bottom: ${({ theme }) => theme.margin.medium};
`;

const ProjectTemplates = ({ projectId }: { projectId: string }) => {
  const { data, loading } = useQuery<
    ProjectTemplatesInterface,
    ProjectTemplatesVariables
  >(PROJECT_TEMPLATES, {
    variables: { projectId }
  });
  if (loading) {
    return <Loading />;
  }

  if (data && data.project) {
    const { project } = data;
    return (
      <Column>
        <Breadcrumb>
          {projectBreadcrumbItems({
            projectSlug: project.slug,
            projectName: project.name
          })}
        </Breadcrumb>
        <TCard>
          <AddButton projectId={projectId} />
          <TemplatesTable project={data.project} />
        </TCard>
      </Column>
    );
  }
  return <PlaceholderScreen />;
};

export default ProjectTemplates;
