import { useQuery } from "@apollo/react-hooks";
import ExampleDataRow from "components/projectDataScreen/ExampleDataRow";
import USE_EXAMPLE_DATA from "./queries/USE_EXAMPLE_DATA";
import {
  UseExampleData,
  UseExampleDataVariables
} from "./queries/__generated__/UseExampleData";

export const useExampleData = (projectId: string) => {
  const { data } = useQuery<UseExampleData, UseExampleDataVariables>(
    USE_EXAMPLE_DATA,
    {
      variables: {
        projectId
      }
    }
  );

  return ((data &&
    data.project &&
    data.project.exampleData &&
    data.project.exampleData.rows) ||
    []) as ExampleDataRow[];
};
