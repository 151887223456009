import gql from "graphql-tag";

export default gql`
  mutation QueryUpsertTemplateSnapshot($templateId: uuid!, $snapshot: jsonb!) {
    insert_template_snapshots(
      on_conflict: {
        constraint: template_snapshots_template_id_key
        update_columns: snapshot_data
      }
      objects: { snapshot_data: $snapshot, template_id: $templateId }
    ) {
      returning {
        id
        templateId: template_id
      }
    }
  }
`;
