import { Button } from "antd";
import { CSVHandlingEditor } from "components/projectTemplateScreen/CSVHandlingEditor";
import React from "react";
import styled from "style/themed";

const FieldArea = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
`;

const EditorContainer = styled.div`
  border: 1px solid #d9d9d9;
  padding: ${({ theme }) => theme.margin.medium};
  margin-bottom: ${({ theme }) => theme.margin.medium};
  border-radius: 4px;
  transition: all 0.3s, height 0s;
  margin-right: ${({ theme }) => theme.margin.medium};
  flex-grow: 1;
  :hover {
    border-color: #40a9ff;
  }
`;

export interface SynonymFieldProps {
  fieldId: string;
  onRemoveField: () => void;
  onCSVPasted: (csv: string[][]) => void;
}

const SynonymField = ({
  fieldId,
  onRemoveField,
  onCSVPasted
}: SynonymFieldProps) => {
  return (
    <FieldArea>
      <EditorContainer>
        <CSVHandlingEditor onCSVPasted={onCSVPasted} draftStateId={fieldId} />
      </EditorContainer>
      <Button icon="delete" onClick={onRemoveField} />
    </FieldArea>
  );
};

export default SynonymField;
