import gql from "graphql-tag";

export default gql`
  mutation MutationAddProjectMember($projectId: uuid!, $userId: uuid!) {
    insert_project_members(
      objects: { project_id: $projectId, user_id: $userId }
    ) {
      returning {
        id
      }
    }
  }
`;
