import gatewayLogin from "appGateway/gatewayLogin";
import authServiceClient from "authService/authServiceClient";
import QUERY_EMAIL_SIGNUP from "./queries/QUERY_EMAIL_SIGNUP";
import {
  QueryEmailSignup,
  QueryEmailSignupVariables
} from "./queries/__generated__/QueryEmailSignup";

export default async ({
  email,
  password
}: {
  email: string;
  password: string;
}) => {
  let authToken;
  const { data } = await authServiceClient.mutate<
    QueryEmailSignup,
    QueryEmailSignupVariables
  >({
    mutation: QUERY_EMAIL_SIGNUP,
    variables: { email, password }
  });

  authToken = data && data.signupEmailPassword.token;
  return await gatewayLogin(authToken);
};
