import { useMutation } from "@apollo/react-hooks";
import { message, Modal } from "antd";
import UploadData from "components/dataScreen/UploadData";
import { refetchDataVariables } from "components/dataTypesScreen/queries/refetch";
import strings from "localisation/strings";
import {
  difference,
  differenceWith,
  fromPairs,
  keys,
  KeyValuePair,
  pluck,
  symmetricDifference,
  toPairs,
  unnest
} from "ramda";
import React, { useState } from "react";
import styled from "style/themed";
import uuid from "uuid/v4";
import {
  data_variables_insert_input,
  data_variable_types_enum
} from "__generated__/AppGlobalTypes";
import QUERY_CREATE_EXAMPLE_DATA from "./queries/QUERY_CREATE_EXAMPLE_DATA";
import refetchProjectData from "./queries/refetchProjectData";
import {
  QueryCreateExampleData,
  QueryCreateExampleDataVariables
} from "./queries/__generated__/QueryCreateExampleData";
import { QueryProjectData_project_dataVariables } from "./queries/__generated__/QueryProjectData";

type CSVData = object[];

const csvToDataTable = (
  csvData: CSVData,
  projectId: string,
  variablesToAdd: string[],
  variablesToKeep: QueryProjectData_project_dataVariables[]
) => {
  const newVariables = variablesToAdd.map(name => {
    const id = uuid();
    const variables: data_variables_insert_input = {
      project_id: projectId,
      name,
      id,
      type: data_variable_types_enum.TEXT
    };
    return variables;
  });
  const allVariables = [
    ...variablesToKeep.map(
      ({ id, name, type }): data_variables_insert_input => ({
        project_id: projectId,
        id,
        name,
        type
      })
    ),
    ...newVariables
  ];
  const rows = csvData.map(row => {
    return fromPairs(
      unnest(
        toPairs(row as { [index: string]: string }).map(
          ([dataName, dataValue]) => {
            const ids = allVariables
              .filter(({ name }) => name === dataName)
              .map(({ id }) => `${id}`);
            return ids.map(
              id => [id, dataValue] as KeyValuePair<string, string>
            );
          }
        )
      )
    );
  });
  return {
    dataVariables: allVariables,
    rows
  };
};

const UploadButton = styled(UploadData)`
  align-self: flex-start;
`;

const getChanges = (
  oldVariables: QueryProjectData_project_dataVariables[],
  newVariables: CSVData
) => {
  const newNames = keys(newVariables[0] || {});
  const variablesToAdd = differenceWith(
    (name, variable) => variable.name === name,
    newNames,
    oldVariables
  );
  const variablesToRemove = differenceWith(
    (variable, name) => variable.name === name,
    oldVariables,
    newNames
  );
  const variablesToKeep = difference(oldVariables, variablesToRemove);
  return { variablesToAdd, variablesToKeep, variablesToRemove };
};

export interface CreateNewTableProps {
  projectId: string;
  projectDataVariables: QueryProjectData_project_dataVariables[];
}

const CreateNewTable = ({
  projectId,
  projectDataVariables
}: CreateNewTableProps) => {
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [rawData, setRawData] = useState<object[]>([]);
  const [createTable] = useMutation<
    QueryCreateExampleData,
    QueryCreateExampleDataVariables
  >(QUERY_CREATE_EXAMPLE_DATA);

  const oldNames = pluck("name", projectDataVariables);

  const onDataUploaded = (csvData: CSVData) => {
    const comparedNewNames = keys(csvData[0] || {});
    setRawData(csvData);
    if (symmetricDifference(comparedNewNames, oldNames).length > 0) {
      setModalVisible(true);
    } else {
      uploadVariables(csvData);
    }
  };

  const uploadVariables = async (csvData: CSVData) => {
    const changes = getChanges(projectDataVariables, csvData);
    const { dataVariables, rows } = csvToDataTable(
      csvData,
      projectId,
      changes.variablesToAdd,
      changes.variablesToKeep
    );
    setLoading(true);
    try {
      await createTable({
        variables: { projectId, rows, dataVariables },
        refetchQueries: [
          refetchProjectData({ projectId }),
          refetchDataVariables({ projectId })
        ]
      });
    } catch (err) {
      message.error(strings("projectData.uploadFailed"));
    }
    setModalVisible(false);
    setLoading(false);
  };

  const { variablesToAdd, variablesToRemove } = getChanges(
    projectDataVariables,
    rawData
  );
  return (
    <>
      <UploadButton
        onDataUploaded={onDataUploaded}
        loading={loading}
        accept=".csv"
      />
      <Modal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={() => uploadVariables(rawData)}
      >
        {variablesToRemove.length > 0 && (
          <p>
            {`${strings("projectData.removeVariables")}: `}
            {pluck("name", variablesToRemove).join(",")}
          </p>
        )}
        {variablesToAdd.length > 0 && (
          <p>
            {`${strings("projectData.addVariables")}: `}
            {variablesToAdd.join(",")}
          </p>
        )}
      </Modal>
    </>
  );
};

export default CreateNewTable;
