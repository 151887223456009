import { useLazyQuery } from "@apollo/react-hooks";
import { pathOr } from "ramda";
import QUERY_CHECK_PROJECT_SLUG from "./queries/QUERY_CHECK_PROJECT_SLUG";
import QUERY_CHECK_TEMPLATE_SLUG from "./queries/QUERY_CHECK_TEMPLATE_SLUG";
import {
  QueryCheckProjectSlug,
  QueryCheckProjectSlugVariables
} from "./queries/__generated__/QueryCheckProjectSlug";
import {
  QueryCheckTemplateSlug,
  QueryCheckTemplateSlugVariables
} from "./queries/__generated__/QueryCheckTemplateSlug";

const extractCount = pathOr(0, ["slugs", "total", "count"]);

interface ProjectSlugParams {
  type: "projectSlug";
}

interface TemplateSlugParams {
  type: "templateSlug";
  projectId: string;
}

export const useCheckSlug = (
  params: TemplateSlugParams | ProjectSlugParams
) => {
  const [checkTemplateSlug, { data: templateSlugData }] = useLazyQuery<
    QueryCheckTemplateSlug,
    QueryCheckTemplateSlugVariables
  >(QUERY_CHECK_TEMPLATE_SLUG);

  const [checkProjectSlug, { data: projectSlugData }] = useLazyQuery<
    QueryCheckProjectSlug,
    QueryCheckProjectSlugVariables
  >(QUERY_CHECK_PROJECT_SLUG);

  const checkSlug = (slug: string) => {
    if (params.type === "projectSlug") {
      return checkProjectSlug({ variables: { slug } });
    }
    return checkTemplateSlug({
      variables: { slug, projectId: params.projectId }
    });
  };

  const data =
    params.type === "projectSlug" ? projectSlugData : templateSlugData;
  const sameSlugCount = extractCount(data);

  return { checkSlug, sameSlugCount };
};
