import {
  DraftHandleValue,
  Editor,
  EditorProps,
  EditorState,
  Modifier
} from "draft-js";
import { assoc } from "ramda";
import React, { memo, RefObject, useEffect, useRef, useState } from "react";
import { BehaviorSubject } from "rxjs";
import { useDraftState } from "state/template/expensive/useDraftState";
import {
  fromServerSnapshot,
  LocalSnapshot,
  useSnapshot
} from "state/template/useSnapshot";
import { cloneSnapshot } from "templatio-evaluator";
import { EntityTreeUpdater } from "./editors/common/EntityTreeUpdater";
import InlineToolbar from "./InlineToolbar";
import {
  handleDraftEditorPastedText,
  registerCopySource
} from "./pasteHandler";
import { useSnapshotUpdate } from "./useSnapshotUpdate";

export interface TemplatioEditorProps extends Partial<EditorProps> {
  draftStateId: string;
  onHandleExternalSourcePaste?: (text: string) => void;
  onTextChanged?: (text: string) => void;
}

interface OptimizedEditorProps extends TemplatioEditorProps {
  editorRef: RefObject<Editor>;
  setDraftState: (newState: EditorState) => void;
  editorState: EditorState;
  mergeSnapshot: (snapshot: LocalSnapshot) => void;
}

const OptimizedEditor = memo(
  ({
    draftStateId,
    editorRef,
    editorState,
    setDraftState,
    mergeSnapshot,
    onHandleExternalSourcePaste,
    onTextChanged,
    ...props
  }: OptimizedEditorProps) => {
    const [pastedText, setPastedText] = useState("");

    useEffect(() => {
      if (pastedText) {
        if (onHandleExternalSourcePaste) {
          onHandleExternalSourcePaste(pastedText);
        }
        setPastedText("");
      }
      if (onTextChanged) {
        onTextChanged(editorState.getCurrentContent().getPlainText());
      }
      // eslint-disable-next-line
    }, [editorState]);

    const handlePaste = (
      text: string,
      html: string | undefined,
      currentState: EditorState
    ): DraftHandleValue => {
      const pasteContent = handleDraftEditorPastedText(html);
      if (pasteContent) {
        const { rawEditorState: rawContent, extraData } = pasteContent;
        if (extraData) {
          const { draftStates, ...newSnapshot } = fromServerSnapshot(
            cloneSnapshot(extraData, draftStateId, rawContent)
          );
          const pastedDraftState = draftStates[draftStateId];
          if (pastedDraftState) {
            const fragment = pastedDraftState.getCurrentContent().getBlockMap();
            const content = Modifier.replaceWithFragment(
              currentState.getCurrentContent(),
              currentState.getSelection(),
              fragment
            );
            mergeSnapshot({
              ...newSnapshot,
              draftStates: assoc(
                draftStateId,
                EditorState.push(currentState, content, "insert-fragment"),
                draftStates
              )
            });
            return "handled";
          }
        }
      }
      if (onHandleExternalSourcePaste) {
        setPastedText(text);
      }
      return "not-handled";
    };
    return (
      <>
        <Editor
          ref={editorRef}
          editorState={editorState}
          onChange={setDraftState}
          handlePastedText={handlePaste}
          {...props}
        />
        <InlineToolbar localId={draftStateId} />
        {editorState.getSelection().getHasFocus() && (
          <EntityTreeUpdater fieldId={draftStateId} />
        )}
      </>
    );
  }
);

const TemplatioEditor = ({ draftStateId, ...props }: TemplatioEditorProps) => {
  const { draftState: editorState, setDraftState } = useDraftState({
    stateId: draftStateId
  });
  const editorRef = useRef<Editor>(null);
  const { snapshot } = useSnapshot({ projectId: "", templateId: "" });
  const { mergeSnapshot } = useSnapshotUpdate();
  const [change$] = useState(new BehaviorSubject(snapshot));

  useEffect(() => {
    const unsubscribe = registerCopySource(editorRef, change$);
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    change$.next(snapshot);
  }, [snapshot, change$]);

  if (!editorState) {
    return null;
  }

  return (
    <OptimizedEditor
      draftStateId={draftStateId}
      editorRef={editorRef}
      editorState={editorState}
      mergeSnapshot={mergeSnapshot}
      setDraftState={setDraftState}
      {...props}
    />
  );
};

export default memo(TemplatioEditor);
