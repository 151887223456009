import gql from "graphql-tag";

export default gql`
  mutation MutationDeleteProjectMemberInvite($inviteId: uuid!) {
    delete_project_member_invites(where: { id: { _eq: $inviteId } }) {
      returning {
        id
      }
    }
  }
`;
