import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink, split } from "apollo-link";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { createHttpLink } from "apollo-link-http";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import { contains, path } from "ramda";
import { SubscriptionClient } from "subscriptions-transport-ws";

export const gatewayCient = new ApolloClient({
  link: createHttpLink({
    uri: process.env.REACT_APP_GATEWAY_URL
  }),
  cache: new InMemoryCache()
});

export const getAppLink = (triggerRefresh: () => void, authToken?: string) => {
  const errorLink = onError(({ graphQLErrors }) => {
    if (
      graphQLErrors &&
      graphQLErrors.find(
        err => path<string>(["extensions", "code"], err) === "invalid-jwt"
      )
    ) {
      triggerRefresh();
    }
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: authToken ? `Bearer ${authToken}` : ""
      }
    };
  });

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_HASURA_URL
  });

  const wsClient = new SubscriptionClient(
    `${process.env.REACT_APP_HASURA_WS_URL}`,
    {
      reconnect: true,
      connectionParams: () => {
        return {
          headers: {
            authorization: authToken ? `Bearer ${authToken}` : ""
          }
        };
      },
      connectionCallback: error => {
        if (typeof error === "string" && contains("JWTExpired", error)) {
          triggerRefresh();
        }
      }
    }
  );

  const wsLink = new WebSocketLink(wsClient);

  const transportLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );
  return {
    link: ApolloLink.from([errorLink, authLink, transportLink]),
    wsClient
  };
};

export class AppServiceClient extends ApolloClient<any> {
  constructor(triggerRefresh: () => void, authToken?: string) {
    const { link, wsClient } = getAppLink(triggerRefresh, authToken);
    super({
      link,
      cache: new InMemoryCache(),
      defaultOptions: {
        query: {
          fetchPolicy: "no-cache"
        }
      }
    });

    this.stop = () => {
      super.stop();
      wsClient.close();
    };
  }
}

export const fakeClient = new ApolloClient({
  link: createHttpLink({
    uri: process.env.REACT_APP_HASURA_URL
  }),
  cache: new InMemoryCache()
});
