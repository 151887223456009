import { BranchesState } from "state/template/BranchesState";
import { DatasState } from "state/template/DatasState";
import { SynonymsState } from "state/template/SynonymsState";
import EntityInterface from "templatio-evaluator/lib/main/evaluator/models/EntityInterface";

export const useGetEntity = () => {
  const { getSynonymEntity } = SynonymsState.useContainer();
  const { getBranchEntity } = BranchesState.useContainer();
  const { getDataEntity } = DatasState.useContainer();

  const getEntity = (entityId: string): EntityInterface | undefined => {
    return (
      getSynonymEntity({ synonymId: entityId }) ||
      getBranchEntity({ branchId: entityId }) ||
      getDataEntity({ dataId: entityId })
    );
  };

  return { getEntity };
};
