import { createInitialState } from "state/templateStateHelpers/createInitialState";
import uuid from "uuid/v4";
import { DraftStates } from "./DraftStates";
import { RawDraftContentState } from "draft-js";

export const useAddField = () => {
  const { setDraftState } = DraftStates.useContainer();
  const addField = (initialText?: string | RawDraftContentState) => {
    const draftState = createInitialState(initialText);
    const fieldId = uuid();
    setDraftState(fieldId, draftState);
    return fieldId;
  };
  return { addField };
};
