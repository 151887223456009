import gql from "graphql-tag";

export default gql`
  query QueryTemplateBySlug($projectSlug: String!, $templateSlug: String!) {
    templates(
      where: {
        _and: {
          project: { slug: { _eq: $projectSlug } }
          slug: { _eq: $templateSlug }
        }
      }
    ) {
      id
      project {
        id
      }
    }
  }
`;
