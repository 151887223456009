import globalTheme from "style/Theme";
import styled, { css } from "style/themed";

const whileEditingCss = css`
  border: 1px solid;
  border-radius: 2px;
`;

const missingCss = css`
  color: ${({ theme }) => theme.color.entities.missing};
`;

export interface EntityTextProps {
  isBeingEdited: boolean;
  entityType: keyof typeof globalTheme.color.entities;
  missing?: boolean;
}

export const EntityText = styled.span<EntityTextProps>`
  color: ${({ theme, entityType }) => theme.color.entities[entityType]};
  cursor: pointer;
  ${({ isBeingEdited }) => (isBeingEdited ? whileEditingCss : "")}
  // @ts-ignore
  ${({ missing }) => (missing ? missingCss : "")}

  :hover {
    background-color: black;
  }
`;
