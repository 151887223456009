import gql from "graphql-tag";

export default gql`
  query QueryTemplateSnapshot($templateId: uuid!) {
    template: templates_by_pk(id: $templateId) {
      id
      name
      slug
      snapshots {
        id
        snapshotData: snapshot_data
      }
      project {
        id
        name
        slug
        publishingUrl: publishing_url
      }
    }
  }
`;
