import { Table } from "antd";
import { EditorInputContainer } from "components/common/inputs/EditorInputContainer";
import TemplatioEditor from "components/projectTemplateScreen/TemplatioEditor";
import { fromPairs, KeyValuePair, prop } from "ramda";
import React from "react";
import styled from "style/themed";
import { findSlots } from "templatio-evaluator";
import { Equation } from "templatio-evaluator/lib/main/evaluator/models/EquationInterfaces";
import SlotFieldInterface from "templatio-evaluator/lib/main/evaluator/models/SlotField";
import { SlotFieldActions, SlotFieldActionsProps } from "./SlotFieldActions";
import { SlotFieldsCell, SlotFieldsCellProps } from "./SlotFieldsCell";
import { SlotFieldsTableHeader } from "./SlotFieldsTableHeader";

const ResultContainer = styled(EditorInputContainer)`
  width: 200px;
`;

export interface SlotFieldsTableProps {
  slotFields: SlotFieldInterface[];
  equation: Equation;
  branchFieldId: string;
}
export const SlotFieldsTable = ({
  slotFields,
  equation,
  branchFieldId
}: SlotFieldsTableProps) => {
  const equationSlots = findSlots(equation);
  const columnIds = equationSlots.map(prop("id"));

  return (
    <>
      <Table
        scroll={{ x: true }}
        size="small"
        pagination={false}
        footer={() => <SlotFieldsTableHeader branchFieldId={branchFieldId} />}
        dataSource={slotFields.map((field, rowIndex) => {
          const cells = fromPairs(
            equationSlots.map(({ id }) => {
              const cellProps: SlotFieldsCellProps = {
                rowIndex,
                columnIds,
                fields: slotFields,
                slotId: id
              };
              return [id, cellProps] as KeyValuePair<
                string,
                SlotFieldsCellProps
              >;
            })
          );
          const actionsProps: SlotFieldActionsProps = {
            field
          };
          return {
            key: rowIndex,
            result: field.localId,
            actions: actionsProps,
            ...cells
          };
        })}
      >
        {equationSlots.map(({ name, id }) => (
          <Table.Column
            key={id}
            title={name}
            dataIndex={id}
            render={props => <SlotFieldsCell {...props} />}
          />
        ))}
        <Table.Column
          key="result"
          dataIndex="result"
          title="Result"
          width={200}
          fixed="right"
          render={localId => (
            <ResultContainer>
              <TemplatioEditor draftStateId={localId} />
            </ResultContainer>
          )}
        />
        <Table.Column
          fixed="right"
          key="actions"
          dataIndex="actions"
          render={props => <SlotFieldActions {...props} />}
        />
      </Table>
    </>
  );
};
