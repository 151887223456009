import React, { useState } from "react";

import { Button, Modal } from "antd";
import strings from "localisation/strings";
import LogicTestMatrix, { LogicTestMatrixProps } from "./LogicTestMatrix";

const TestLogicButton = (props: LogicTestMatrixProps) => {
  const [modalOpened, setModalOpened] = useState(false);

  return (
    <div onMouseDown={ev => ev.stopPropagation()}>
      <Button icon="bug" onClick={() => setModalOpened(true)} />
      <Modal
        title={strings("projectTemplate.branchTools.testLogicModal.title")}
        visible={modalOpened}
        footer={null}
        onCancel={() => setModalOpened(false)}
      >
        <LogicTestMatrix {...props} />
      </Modal>
    </div>
  );
};

export default TestLogicButton;
