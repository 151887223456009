import { useMutation } from "@apollo/react-hooks";
import React from "react";
import MUTATION_ADD_PROJECT_MEMBER from "./queries/MUTATION_ADD_PROJECT_MEMBER";
import MUTATION_CREATE_PROJECT_MEMEBER_INVITE from "./queries/MUTATION_CREATE_PROJECT_MEMEBER_INVITE";
import {
  MutationAddProjectMember,
  MutationAddProjectMemberVariables
} from "./queries/__generated__/MutationAddProjectMember";
import {
  QueryCreateProjectMemberInvite,
  QueryCreateProjectMemberInviteVariables
} from "./queries/__generated__/QueryCreateProjectMemberInvite";
import UserAutocomplete from "./UserAutocomplete";

interface AddMemberInputProps {
  projectId: string;
}

export default ({ projectId }: AddMemberInputProps) => {
  const [addMember] = useMutation<
    MutationAddProjectMember,
    MutationAddProjectMemberVariables
  >(MUTATION_ADD_PROJECT_MEMBER);
  const [inviteMember] = useMutation<
    QueryCreateProjectMemberInvite,
    QueryCreateProjectMemberInviteVariables
  >(MUTATION_CREATE_PROJECT_MEMEBER_INVITE);

  const onUserSelected = async (userId: string) => {
    addMember({ variables: { userId, projectId } });
  };

  const onEmailSelected = (email: string) => {
    inviteMember({ variables: { projectId, inviteeEmail: email } });
  };

  return (
    <UserAutocomplete
      onEmailSelected={onEmailSelected}
      onUserSelected={onUserSelected}
      excludedProjectId={projectId}
    />
  );
};
