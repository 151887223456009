import gql from "graphql-tag";

export default gql`
  subscription Projects {
    projects {
      id
      name
      slug
      owner {
        id
        email: primary_email
      }
      members {
        user {
          id
          email: primary_email
        }
      }
    }
  }
`;
