import { useMutation } from "@apollo/react-hooks";
import { WrappedFormUtils } from "antd/lib/form/Form";
import { ApolloError } from "apollo-client";
import { useState } from "react";
import ADD_TEMPLATE from "./queries/ADD_TEMPLATE";
import refetchProjectTemplates from "./queries/refetchProjectTemplates";
import UPDATE_TEMPLATE from "./queries/UPDATE_TEMPLATE";
import {
  AddTemplate,
  AddTemplateVariables
} from "./queries/__generated__/AddTemplate";
import {
  UpdateTemplate,
  UpdateTemplateVariables
} from "./queries/__generated__/UpdateTemplate";
import { TemplateFormFields } from "./UpsertTemplateForm";

export interface TemplateModalProps {
  projectId: string;
  onError?: (error: ApolloError) => any;
  onDone: () => any;
}

export const useTemplateModal = ({
  projectId,
  onDone,
  onError
}: TemplateModalProps) => {
  const [error, setError] = useState<ApolloError | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [addTemplate] = useMutation<AddTemplate, AddTemplateVariables>(
    ADD_TEMPLATE
  );
  const [updateTemplate] = useMutation<UpdateTemplate, UpdateTemplateVariables>(
    UPDATE_TEMPLATE
  );
  let form: WrappedFormUtils<TemplateFormFields> | undefined;

  const onModalClose = () => {
    setError(undefined);
    if (form) {
      form.resetFields();
    }
  };
  const onAddTemplate = async () => {
    if (form) {
      form.validateFields(async (err, variables) => {
        setLoading(true);
        if (!err) {
          try {
            const result = await addTemplate({
              variables: { projectId, ...variables },
              refetchQueries: [refetchProjectTemplates({ projectId })]
            });
            if (result && result.data) {
              onDone();
            }
          } catch (err) {
            setError(err);
            if (onError) {
              onError(err);
            }
          }
        }
        setLoading(false);
      });
    }
  };
  const onUpdateTemplate = async ({ templateId }: { templateId: string }) => {
    if (form) {
      form.validateFields(async (err, variables) => {
        setLoading(true);
        if (!err) {
          try {
            const result = await updateTemplate({
              variables: { templateId, ...variables },
              refetchQueries: [refetchProjectTemplates({ projectId })]
            });
            if (result && result.data) {
              onDone();
            }
          } catch (err) {
            setError(err);
            if (onError) {
              onError(err);
            }
          }
        }
        setLoading(false);
      });
    }
  };

  return {
    onModalClose,
    onAddTemplate,
    onUpdateTemplate,
    loading,
    error,
    setForm: (formRef: any) => (form = formRef)
  };
};
