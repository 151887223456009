import React, { ReactNode } from "react";
import { Popper } from "react-popper";
import styled from "style/themed";
import { VirtualReference } from "./VirtualReference";

const ToolbarContainer = styled.div`
  z-index: 10;
`;

export interface ToolbarProps {
  referenceElement: VirtualReference;
  visible?: boolean;
  children?: ReactNode;
}

const Toolbar = ({ referenceElement, visible, children }: ToolbarProps) =>
  visible ? (
    <Popper referenceElement={referenceElement} positionFixed>
      {({ ref, style, placement, arrowProps, scheduleUpdate }) => {
        referenceElement.rectChangedCallback = scheduleUpdate;
        return (
          <ToolbarContainer
            onMouseDown={ev => ev.preventDefault()}
            ref={ref}
            style={style}
            data-placement={placement}
          >
            {children}
          </ToolbarContainer>
        );
      }}
    </Popper>
  ) : null;

export default Toolbar;
