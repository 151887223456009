import { Modal } from "antd";
import strings from "localisation/strings";
import React from "react";
import UpsertTemplateForm from "./UpsertTemplateForm";
import { TemplateModalProps, useTemplateModal } from "./useTemplateModal";

interface UpdateTemplateModalProps extends TemplateModalProps {
  onCancel: () => any;
  visible: boolean;
  templateId: string;
  initialName?: string;
  initialSlug?: string;
}

const UpdateTemplateModal = ({
  visible,
  onCancel,
  templateId,
  initialName,
  initialSlug,
  ...props
}: UpdateTemplateModalProps) => {
  const {
    error,
    loading,
    onUpdateTemplate,
    onModalClose,
    setForm
  } = useTemplateModal(props);

  return (
    <Modal
      title={strings("projectTemplates.updateTemplateModal.title")}
      okText={error ? strings("buttons.tryAgain") : strings("buttons.update")}
      cancelText={strings("buttons.cancel")}
      confirmLoading={loading}
      visible={visible}
      onOk={() => onUpdateTemplate({ templateId })}
      onCancel={onCancel}
      afterClose={onModalClose}
    >
      <UpsertTemplateForm
        ref={ref => setForm(ref)}
        error={error}
        projectId={props.projectId}
        initialName={initialName}
        initialSlug={initialSlug}
        visible={visible}
      />
    </Modal>
  );
};

export default UpdateTemplateModal;
