import { Button } from "antd";
import strings from "localisation/strings";
import React from "react";
import { useAddRemoveBranchField } from "state/template/expensive/useAddRemoveBranchField";

export interface AddBranchFieldProps {
  entityId: string;
}

const AddBranchField = ({ entityId }: AddBranchFieldProps) => {
  const { addBranchField } = useAddRemoveBranchField();
  return (
    <div>
      <Button
        type="primary"
        onClick={() => addBranchField({ branchId: entityId })}
      >
        {strings("projectTemplate.branchTools.addBranchField.addButton")}
      </Button>
    </div>
  );
};

export default AddBranchField;
