import MaintenanceModeCheck from "authService/MaintenanceModeCheck";
import { Paths } from "navigation/Paths";
import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import AccountScreen from "screens/AccountScreen";
import AppFrame from "screens/AppFrame";
import DataTypesScreen from "screens/DataTypesScreen";
import EmailVerificationScreen from "screens/EmailVerificationScreen";
import LoginScreen from "screens/LoginScreen";
import MaintenanceScreen from "screens/MaintenanceScreen";
import PlaceholderScreen from "screens/PlaceholderScreen";
import PlaygroundScreen from "screens/PlaygroundScreen";
import ProjectDataScreen from "screens/ProjectDataScreen";
import ProjectMemebrsScreen from "screens/ProjectMembersScreen";
import ProjectsScreen from "screens/ProjectsScreen";
import ProjectTemplateScreen from "screens/ProjectTemplateScreen";
import ProjectTemplatesScreen from "screens/ProjectTemplatesScreen";
import RegisterScreen from "screens/RegisterScreen";

const RedirectToDashboard = () => <Redirect to={Paths.projects.path} />;

const TRouter = () => (
  <BrowserRouter>
    <Route path="/" component={MaintenanceModeCheck} />
    <Switch>
      {/* Maintenance */}
      <Route
        path={Paths.maintenance.path}
        component={MaintenanceScreen}
        exact
      />
      {/* Login and register */}
      <Route exact path="/" component={RedirectToDashboard} />
      <Route exact path={Paths.login.path} component={LoginScreen} />
      <Route
        exact
        path={Paths.forgotPassword.path}
        component={PlaceholderScreen}
      />
      <Route exact path={Paths.register.path} component={RegisterScreen} />
      <Route
        exact
        path={Paths.emailVerification.path}
        component={EmailVerificationScreen}
      />
      <Route exact path={Paths.playground.path} component={PlaygroundScreen} />
      <Route
        exact
        path={[
          Paths.projects.path,
          Paths.projectData.path,
          Paths.projectTemplates.path,
          Paths.projectTemplate.path,
          Paths.projectDataTypes.path,
          Paths.projectMembers.path,
          Paths.account.path
        ]}
      >
        <AppFrame>
          <Switch>
            <Route
              exact
              path={Paths.projects.path}
              component={ProjectsScreen}
            />
            <Route
              exact
              path={Paths.projectData.path}
              component={ProjectDataScreen}
            />
            <Route
              exact
              path={Paths.projectTemplates.path}
              component={ProjectTemplatesScreen}
            />
            <Route
              exact
              path={Paths.projectTemplate.path}
              component={ProjectTemplateScreen}
            />
            <Route
              exact
              path={Paths.projectDataTypes.path}
              component={DataTypesScreen}
            />
            <Route
              exact
              path={Paths.projectMembers.path}
              component={ProjectMemebrsScreen}
            />
            <Route exact path={Paths.account.path} component={AccountScreen} />
          </Switch>
        </AppFrame>
      </Route>
      <Route component={PlaceholderScreen} />
    </Switch>
  </BrowserRouter>
);

export default TRouter;
