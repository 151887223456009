import { Button, Icon, Upload } from "antd";
import { RcFile, UploadProps } from "antd/lib/upload/interface";
import csv from "csvtojson";
import strings from "localisation/strings";
import React, { Component } from "react";
import styled from "style/themed";

export interface UploadDataProps extends UploadProps {
  onDataUploaded?: (data: object[]) => void;
  loading?: boolean;
}

const UploadIcon = styled(Icon)`
  margin-right: ${({ theme }) => theme.margin.small};
`;

export default class UploadData extends Component<UploadDataProps> {
  onLoadEnd = (reader: FileReader) => async () => {
    const content = reader.result as string;
    const data = await csv({ delimiter: "auto" }).fromString(content);
    const { onDataUploaded } = this.props;
    if (onDataUploaded) {
      onDataUploaded(data);
    }
  };

  handleChange = (file: File | undefined) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = this.onLoadEnd(reader);
      reader.readAsText(file);
    }
  };

  beforeUpload = (file: RcFile) => {
    this.handleChange(file);
    return false;
  };

  render() {
    const { className, loading } = this.props;
    return (
      <Upload
        className={className}
        beforeUpload={this.beforeUpload}
        fileList={[]}
        {...this.props}
      >
        <Button loading={loading}>
          {loading !== true && <UploadIcon type="upload" />}
          {strings("projectData.uploadButton")}
        </Button>
      </Upload>
    );
  }
}
