import { gatewayCient } from "api/clients";
import { getFirstErrorType } from "authService/errorHandling";
import refreshToken from "authService/refreshToken";
import { ThApiErrorTypes } from "./generated/graphql";
import QUERY_GATEWAY_LOGIN from "./queries/QUERY_GATEWAY_LOGIN";
import {
  QueryGatewayLogin,
  QueryGatewayLoginVariables
} from "./queries/__generated__/QueryGatewayLogin";

export const tryGatewayLogin = async (authToken?: string | null) => {
  if (!authToken) {
    throw new Error("Missing auth token");
  }
  const { data: gatewayData } = await gatewayCient.mutate<
    QueryGatewayLogin,
    QueryGatewayLoginVariables
  >({ mutation: QUERY_GATEWAY_LOGIN, variables: { token: authToken } });
  const userId = gatewayData && gatewayData.userId;
  return { userId, token: authToken };
};

export default async (authToken?: string | null) => {
  try {
    return await tryGatewayLogin(authToken);
  } catch (error) {
    const errorType = getFirstErrorType<ThApiErrorTypes>(error);
    if (errorType === ThApiErrorTypes.TokenRefreshNeeded) {
      const { token } = await refreshToken();
      return await tryGatewayLogin(token);
    } else {
      throw error;
    }
  }
};
