import React, { useContext } from "react";
import { useDraftState } from "state/template/expensive/useDraftState";
import Toolbar from "./Toolbar";
import ToolbarButtons from "./ToolbarButtons";
import { PopperReferenceContext } from "./VirtualReference";

export interface InlineToolbarProps {
  localId: string;
}

const InlineToolbar = ({ localId }: InlineToolbarProps) => {
  const popperReference = useContext(PopperReferenceContext);
  popperReference.update();
  const { draftState } = useDraftState({ stateId: localId });
  let visible = false;
  if (draftState) {
    const selection = draftState.getSelection();
    visible = selection.getHasFocus() && !selection.isCollapsed();
  }
  return (
    <Toolbar visible={visible} referenceElement={popperReference}>
      <ToolbarButtons localId={localId} />
    </Toolbar>
  );
};

export default InlineToolbar;
