import { BranchesState } from "state/template/BranchesState";
import { BranchFieldsState } from "state/template/BranchFieldsState";
import { DatasState } from "state/template/DatasState";
import { DraftStates } from "state/template/DraftStates";
import { ExampleDataState } from "state/template/ExampleDataState";
import { SynonymFieldsState } from "state/template/SynonymFieldsState";
import { SynonymsState } from "state/template/SynonymsState";
import { applyEntitySelections } from "state/templateStateHelpers/applyEntitySelections";
import { createInitialState } from "state/templateStateHelpers/createInitialState";
import { selectionToNewContent } from "state/templateStateHelpers/selectionToNewContent";
import {
  isBranchEntity,
  isDataEntity,
  isSynonymEntity
} from "templatio-evaluator";
import EntityInterface from "templatio-evaluator/lib/main/evaluator/models/EntityInterface";
import FieldInterface from "templatio-evaluator/lib/main/evaluator/models/FieldInterface";
import SynonymFieldInterface from "templatio-evaluator/lib/main/evaluator/models/SynoymFieldInterface";
import uuid from "uuid/v4";
import { useCommitEntityText } from "./editors/common/useCommitEntityText";

export const useToolbarActions = (draftId: string) => {
  const { addBranch, updateBranch } = BranchesState.useContainer();
  const { assocBranchField } = BranchFieldsState.useContainer();
  const { addSynonym, updateSynonym } = SynonymsState.useContainer();
  const { assocSynonymField } = SynonymFieldsState.useContainer();
  const { addData, updateData } = DatasState.useContainer();
  const { getDraftState, setDraftState } = DraftStates.useContainer();
  const { exampleData } = ExampleDataState.useContainer();
  const { commitEntityText } = useCommitEntityText();

  const addNewEntity = (parentId: string, entity: EntityInterface) => {
    if (isSynonymEntity(entity)) {
      return updateSynonym({ ...entity, parentId });
    }
    if (isBranchEntity(entity)) {
      return updateBranch({ ...entity, parentId });
    }
    if (isDataEntity(entity)) {
      return updateData({ ...entity, parentId });
    }
    return {} as EntityInterface;
  };

  const createEntity = (entity: EntityInterface, newFieldId: string) => {
    const draftState = getDraftState(draftId);
    if (draftState) {
      const { selectionText, oldEntities, editorState } = selectionToNewContent(
        draftState,
        entity
      );
      setDraftState(draftId, editorState);

      const newEntities = oldEntities.map(block =>
        block.map(({ data, ...selection }) => {
          return {
            data: addNewEntity(newFieldId, data),
            ...selection
          };
        })
      );
      const fieldEditorState = applyEntitySelections(
        createInitialState(selectionText),
        newEntities
      );
      setDraftState(newFieldId, fieldEditorState);
    }
  };

  const createSynonym = () => {
    const synonym = addSynonym({ parentId: draftId });
    const newField: SynonymFieldInterface = {
      parentId: synonym.entityId,
      localId: uuid()
    };
    createEntity(synonym, newField.localId);
    assocSynonymField(newField);
  };

  const createBranch = () => {
    const branch = addBranch({ parentId: draftId });
    const newField: FieldInterface = {
      parentId: branch.entityId,
      localId: uuid()
    };
    createEntity(branch, newField.localId);
    assocBranchField(newField);
  };

  const createData = (
    parentId: string,
    variable: { id: string; name: string }
  ) => {
    const exampleText = exampleData[variable.id];
    const draftState = getDraftState(parentId);
    if (draftState) {
      const dataEntity = addData({
        parentId,
        variableId: variable.id
      });
      const { editorState } = selectionToNewContent(
        draftState,
        dataEntity,
        exampleText ? `${exampleText}` : variable.name
      );
      commitEntityText({ entity: dataEntity, editorState });
    }
  };

  return { createSynonym, createBranch, createData };
};
