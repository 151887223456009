import { Card } from "antd";
import styled from "style/themed";

const TCard = styled(Card)`
  .ant-card-body {
    padding: ${({ theme }) => theme.margin.medium};
  }
`;

export default TCard;
