import gql from "graphql-tag";

export default gql`
  mutation QueryUpdateExampleData($projectId: uuid!, $rows: jsonb!) {
    updateExampleData: insert_data_examples(
      objects: [{ project_id: $projectId, rows: $rows }]
      on_conflict: {
        constraint: data_examples_project_id_key
        update_columns: [rows]
      }
    ) {
      affected_rows
    }
  }
`;
