import { Card } from "antd";
import ChangePasswordForm from "components/accountScreen/ChangePasswordForm";
import ScreenTitle from "components/common/texts/ScreenTitle";
import React from "react";
import AppScreen from "./AppScreen";
import Loading from "./Loading";

export default () => {
  return (
    <AppScreen>
      <ScreenTitle t="accountScreen.title" />
      <React.Suspense fallback={<Loading />}>
        <Card>
          <ChangePasswordForm />
        </Card>
      </React.Suspense>
    </AppScreen>
  );
};
