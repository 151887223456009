import { EditorState } from "draft-js";
import { assoc } from "ramda";
import { useCallback, useMemo } from "react";
import { DraftStates } from "../DraftStates";

export const useDraftState = ({ stateId }: { stateId: string }) => {
  const { getDraftState, setDraftStates } = DraftStates.useContainer();

  const state = getDraftState(stateId);
  const draftState = useMemo(() => state, [state]);
  const setDraftState = useCallback(
    (newState: EditorState) => setDraftStates(assoc(stateId, newState)),
    // eslint-disable-next-line
    [state, stateId]
  );

  return { draftState, setDraftState };
};
