import { SubscriptionResult } from "@apollo/react-common";
import {
  SubscriptionHookOptions,
  useSubscription as useApolloSubscription
} from "@apollo/react-hooks";
import { OperationVariables } from "apollo-client";
import { DocumentNode } from "apollo-link";
import { useEffect, useState } from "react";

const useSubscription = <TData = any, TVariables = OperationVariables>(
  subscription: DocumentNode,
  options?: SubscriptionHookOptions<TData, TVariables>
) => {
  const [subscriptionData, setSubscriptionData] = useState<
    SubscriptionResult<TData>
  >({ loading: true });
  const res = useApolloSubscription(subscription, {
    ...options,
    onSubscriptionData: d => setSubscriptionData(d.subscriptionData)
  });

  useEffect(() => {
    if (res.error) {
      setSubscriptionData({ loading: false, error: res.error });
    }
  }, [res.error]);

  return { ...subscriptionData, loading: !!subscriptionData.loading };
};

export default useSubscription;
