import { generatePath } from "react-router";

export interface WithToken {
  token: string;
}
export interface WithProjectSlug {
  projectSlug: string;
}
export interface WithTemplateSlug {
  templateSlug: string;
}

const createRoute = (path: string) => ({
  path,
  withParams: () => path
});

const createParamRoute = <T>(path: string) => ({
  path,
  withParams: (params: T) => generatePath(path, params as any)
});

export const Paths = {
  playground: createRoute("/playground"),
  // MAINTENANCE
  maintenance: createRoute("/maintenance"),
  // LOGIN AND REGISTER
  login: createRoute("/login"),
  register: createRoute("/register"),
  forgotPassword: createRoute("/forgot-password"),
  emailVerification: createParamRoute<WithToken>("/email/verify/:token"),
  // PROJECTS
  data: createRoute("/data"),
  projects: createRoute("/projects"),
  projectData: createParamRoute<WithProjectSlug>("/projects/:projectSlug/data"),
  projectDataTypes: createParamRoute<WithProjectSlug>(
    "/projects/:projectSlug/data/types"
  ),
  projectTemplates: createParamRoute<WithProjectSlug>(
    "/projects/:projectSlug/templates"
  ),
  projectTemplate: createParamRoute<WithProjectSlug & WithTemplateSlug>(
    "/projects/:projectSlug/templates/:templateSlug"
  ),
  projectMembers: createParamRoute<WithProjectSlug>(
    "/projects/:projectSlug/members"
  ),
  account: createRoute("/account")
};
