import React from "react";
import styled from "style/themed";

const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const NotFound = styled.h1``;

const PlaceholderScreen = () => (
  <Container>
    <NotFound>Not found</NotFound>
  </Container>
);

export default PlaceholderScreen;
