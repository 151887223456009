import { useQuery } from "@apollo/react-hooks";
import { Breadcrumb, Button } from "antd";
import { Column } from "components/common/Flex";
import TCard from "components/common/layout/TCard";
import { projectBreadcrumbItems } from "components/common/RouteBreadcrumbs";
import CreateNewTable from "components/projectDataScreen/CreateNewTable";
import ExampleDataTable from "components/projectDataScreen/ExampleDataTable";
import strings from "localisation/strings";
import { Paths } from "navigation/Paths";
import React from "react";
import { Link } from "react-router-dom";
import Loading from "screens/Loading";
import PlaceholderScreen from "screens/PlaceholderScreen";
import styled from "style/themed";
import QUERY_PROJECT_DATA from "./queries/QUERY_PROJECT_DATA";
import {
  QueryProjectData,
  QueryProjectDataVariables
} from "./queries/__generated__/QueryProjectData";

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
`;

const DataTypesButton = styled(Button)`
  margin-left: ${({ theme }) => theme.margin.large};
`;

const ProjectDataDetails = ({ projectId }: { projectId: string }) => {
  const { data, loading } = useQuery<
    QueryProjectData,
    QueryProjectDataVariables
  >(QUERY_PROJECT_DATA, {
    variables: { projectId }
  });

  if (loading) {
    return <Loading />;
  }

  if (data && data.project) {
    const { project } = data;
    return (
      <Column>
        <Breadcrumb>
          {projectBreadcrumbItems({
            projectSlug: project.slug,
            projectName: project.name
          })}
        </Breadcrumb>
        <TCard>
          <ButtonRow>
            <CreateNewTable
              projectId={projectId}
              projectDataVariables={project.dataVariables}
            />
            <DataTypesButton>
              <Link
                to={Paths.projectDataTypes.withParams({
                  projectSlug: project.slug
                })}
              >
                {strings("projectData.dataTypesButton")}
              </Link>
            </DataTypesButton>
          </ButtonRow>
          <ExampleDataTable
            variables={project.dataVariables || []}
            exampleData={project.exampleData}
            projectId={projectId}
          />
        </TCard>
      </Column>
    );
  }
  return <PlaceholderScreen />;
};

export default ProjectDataDetails;
