import { useMutation } from "@apollo/react-hooks";
import { Avatar, Breadcrumb, Button, List } from "antd";
import { Column, Row, SpreadRow } from "components/common/Flex";
import useSubscription from "components/common/hooks/useSubscription";
import TCard from "components/common/layout/TCard";
import { MarginedDivider } from "components/common/MarginedDivider";
import { projectBreadcrumbItems } from "components/common/RouteBreadcrumbs";
import { SimpleText } from "components/common/SimpleText";
import TAvatar from "components/common/TAvatar";
import strings from "localisation/strings";
import React from "react";
import Loading from "screens/Loading";
import PlaceholderScreen from "screens/PlaceholderScreen";
import AddMemberInput from "./AddMemberInput";
import MUTATION_DELETE_PROJECT_MEMBER_INVITE from "./queries/MUTATION_DELETE_PROJECT_MEMBER_INVITE";
import QUERY_PROJECT_MEMBERS from "./queries/QUERY_PROJECT_MEMBERS";
import {
  MutationDeleteProjectMemberInvite,
  MutationDeleteProjectMemberInviteVariables
} from "./queries/__generated__/MutationDeleteProjectMemberInvite";
import {
  QueryProjectMembers,
  QueryProjectMembersVariables
} from "./queries/__generated__/QueryProjectMembers";

interface ProjectMembersProps {
  projectId: string;
}

const ProjectMembers = ({ projectId }: ProjectMembersProps) => {
  const { data, loading } = useSubscription<
    QueryProjectMembers,
    QueryProjectMembersVariables
  >(QUERY_PROJECT_MEMBERS, {
    variables: { projectId }
  });
  const [deleteInvite, { loading: isDeleting }] = useMutation<
    MutationDeleteProjectMemberInvite,
    MutationDeleteProjectMemberInviteVariables
  >(MUTATION_DELETE_PROJECT_MEMBER_INVITE);

  if (loading) {
    return <Loading />;
  }

  const project = data && data.project;

  if (!project) {
    return <PlaceholderScreen />;
  }

  const { owner } = project;

  return (
    <Column>
      <Breadcrumb>
        {projectBreadcrumbItems({
          projectSlug: project.slug,
          projectName: project.name
        })}
        <Breadcrumb.Item>
          <SimpleText t="breadcrumbs.members" />
        </Breadcrumb.Item>
      </Breadcrumb>
      <TCard>
        <SpreadRow>
          <SimpleText as="h2" t="projectMembers.owner" />
          <AddMemberInput projectId={projectId} />
        </SpreadRow>
        <Row grow={0}>
          <List.Item>
            <TAvatar email={owner.primaryEmail} />
            <SimpleText marginHorizontal="medium">
              {owner.primaryEmail}
            </SimpleText>
          </List.Item>
        </Row>
        <MarginedDivider margin="large" />
        <SimpleText as="h2" t="projectMembers.members" />
        <List
          itemLayout="horizontal"
          dataSource={project.members}
          renderItem={({ user: { primaryEmail: email } }) => (
            <Row grow={0}>
              <List.Item>
                <TAvatar email={email} />
                <SimpleText marginHorizontal="medium">{email}</SimpleText>
              </List.Item>
            </Row>
          )}
        />
        {project.memberInvites.length > 0 && (
          <>
            <MarginedDivider margin="large" />
            <SimpleText as="h4" color="tertiary" t="common.invites" />
            <List<typeof project.memberInvites[0]>
              loading={loading}
              itemLayout="horizontal"
              dataSource={project.memberInvites}
              renderItem={({ inviteeEmail, id }) => (
                <List.Item
                  actions={[
                    <Button
                      key="delete"
                      icon="delete"
                      loading={isDeleting}
                      onClick={() =>
                        deleteInvite({ variables: { inviteId: id } })
                      }
                    />
                  ]}
                >
                  <List.Item.Meta
                    avatar={<Avatar icon="mail" />}
                    title={inviteeEmail}
                    description={strings("projectMembers.invitePending")}
                  />
                </List.Item>
              )}
            />
          </>
        )}
      </TCard>
    </Column>
  );
};

export default ProjectMembers;
